import {
  FETCH_ERROR, FETCH_START, FETCH_SUCCESS, GET_ALL_DOCTOR, GET_DOCTOR} from "../../constants/ActionTypes";
import axios from 'axios';
import {CisUI} from "../../util/CISUI";
import Config from '../../util/config';
import ApiUrl from "../../APIURL/Admin/ApiUrl";

const getAllDoctor = () => {
  return (dispatch) => {

    dispatch({type: FETCH_START});
    axios.get(ApiUrl.doctor_list,CisUI().HeaderRequest)
      .then(res => {
      if (res.data.status === 1) {
        // console.log(res.data.data);
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: GET_ALL_DOCTOR, payload: res.data.data});
      } else {
        dispatch({type: FETCH_ERROR, payload: res.data.msg});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_SUCCESS});
      console.log("Error****:", error.message);
    });
  }
};

const getDoctor = (id) => {
  return (dispatch) => {
    
    dispatch({type: FETCH_START});
     axios.get(ApiUrl.single_doctor_data+"/"+id,CisUI().HeaderRequest)
        .then(res => {
          if (res.data.status === 1) {
            // console.log(res.data.data);
            dispatch({type: FETCH_SUCCESS});
            dispatch({type: GET_DOCTOR, payload: {
                'general_info' : res.data.general_info,
                'consultant_info': res.data.consultant_info
            }
            });
          } else {
            dispatch({type: FETCH_ERROR, payload: res.data.msg});
          }
        }).catch(function (error) {
      dispatch({type: FETCH_SUCCESS});
      console.log("Error****:", error.message);
    });
  }
};

export {getAllDoctor, getDoctor}



