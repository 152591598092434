import React, {useEffect, useState} from "react";
import {Col, Input, Row, Select, Form, Button, Card, DatePicker, Divider, Checkbox, Popconfirm} from "antd";
import { Link, useHistory } from "react-router-dom";
import {DeleteOutlined, EyeInvisibleOutlined, EyeTwoTone} from '@ant-design/icons';
import { useDispatch, useSelector } from "react-redux";
import { getCountry, getDistrict } from "../../../appRedux/actions/Country";
import axios from "axios";
import {CisUI} from "../../../util/CISUI";
import ApiUrl from "../../../APIURL/Admin/ApiUrl";
import {getAllAgent} from "../../../appRedux/actions/Agent";
import {getAllPatient} from "../../../appRedux/actions/Patient";
import {getAllDoctor, getDoctor} from "../../../appRedux/actions/Doctor";
import CKEditor from "react-ckeditor-component";
import {FETCH_ERROR, FETCH_SUCCESS, GET_DOCTOR} from "../../../constants/ActionTypes";
import {getAllMedicine} from "../../../appRedux/actions/Medicine";
import {apiserver} from "../../../util/config";

const { Option } = Select;
const { TextArea } = Input;

const NewPrescription = (props) => {
	const history = useHistory();
	const dispatch = useDispatch();
	const [form] = Form.useForm();
	const [image, setImage] = useState("");
	const [medicine, setMedicine] = useState([]);
	const [instruction, setInstruction] = useState("");
	const [remarks, setRemarks] = useState("");
	// const [flag, setFlag] = useState(false);
	
	let medicines = useSelector(({ medicine }) => medicine.medicines);
	const appointment_id = props.match.params.id;
	const [details, setDetails] = useState([]);
	
	const getAppointmentDetails = (appointment_id) => {
		document.getElementById("loader").style.display = "block";
		axios.get(ApiUrl.appointment_details+"/"+appointment_id, CisUI().HeaderRequest)
			.then(res => {
				console.log(res.data)
				if(res.data.status === 1) {
					setDetails(res.data.data);
				}
				else {
					CisUI().Notification('error', res.data.msg)
				}
				document.getElementById("loader").style.display = "none";
			})
			.catch(error => {
				console.log(error.response)
			})
	}
	
	useEffect(() => {
		dispatch(getAllMedicine());
		getAppointmentDetails(appointment_id)
	}, [appointment_id])
	
	const handleInstructions = (evt) => {
		var newContent = evt.editor.getData();
		setInstruction(newContent)
	}
	
	const handleRemarks = (evt) => {
		var newContent = evt.editor.getData();
		setRemarks(newContent)
	}
	
	
	
	// const handleChange = (evt) => {
	// 	var newContent = evt.editor.getData();
	// 	setRemarks(newContent)
	// }
	
	const changeImage = (e) => {
		let file = e.target.files[0];
		let reader = new FileReader();
		// setSource(URL.createObjectURL(e.target.files[0]))
		reader.onload = (e) => {
			setImage({
				image: e.target.result,
			});
		};
		// console.log(file);
		reader.readAsDataURL(file);
	};
	
	let redirectto = '/admin/agent/agentlist'
	
	const onFinish = (values) => {
		let data = {
			...values,
			date: values['date'].format("YYYY-MM-DD"),
			// image: image
			details: remarks
		}
		
		console.log(data);
		document.getElementById("loader").style.display = "block";
		axios.post(ApiUrl.request_appointment, data, CisUI().HeaderRequest)
			.then(res => {
				console.log(res.data)
				if(res.data.status === 1) {
					let id = res.data.id;
					CisUI().Notification('success', res.data.msg)
					history.push('/admin/appointment/appointmentdetails/'+id)
				}
				else {
					CisUI().Notification('error', res.data.msg)
				}
				document.getElementById("loader").style.display = "none";
			})
			.catch(error => {
				console.log(error.response)
			})
	};
	
	const [state, setState] = useState({
		rows: []
	});
	
	const handleAddRows = () => {
		let value = form.getFieldsValue(['medicine_type', 'medicine_name', 'times', 'taking_time', 'days', 'remark']);
		console.log(value)
		
		if(value.medicine_type !== undefined && value.medicine_name !== undefined && value.times !== undefined && value.taking_time !== undefined && value.days !== undefined){
			let items = {
				medicine_type: CisUI().medicineTypeName(parseInt(value.medicine_type)),
				medicine_name: value.medicine_name,
				times: value.times,
				taking_time: value.taking_time,
				days: value.days,
				remark: value.remark
			}
			
			setState({
				rows:[...state.rows, items]
			})
			
			form.setFieldsValue({
				medicine_type: undefined,
				medicine_name: undefined,
				times: undefined,
				taking_time: undefined,
				days: undefined,
				remark: undefined
			})
		}
		else{
			CisUI().Notification('error', 'Required fields are missing')
		}
	}
	
	// console.log(state.rows)
	
	const handleDelete = (id) => {
		let new_arr = state.rows.filter((item,index) => index !== id);
		setState({
			rows: new_arr
		})
	}
	
	
	const onSearch = ((e) => {
		console.log(e.target.value);

		const searchData = []
		if(e.target.value != '') {
			medicines.map((item, index) => {
				if (item?.brand_name?.toLowerCase().includes(e?.target?.value.toLowerCase())) {
					searchData.push({
						id: item.id,
						brand_name: item.brand_name,
						generic_name: item.generic_name,
						strength: item.strength,
						medicine_type: item.medicine_type,
					})
				}
			})
		}
		setMedicine(searchData)
	})

	// const setValue = (id, name) => {
	// 	// console.log(name);
	// 	form.setFieldsValue({
	// 		'medicine_id': "hwllo"
	// 	})
	// }
	
	return (
		<Card title="New Prescription" extra={'Appointment ID: ' + details.unqid}>
			{CisUI().showLoading}
			<Form form={form} onFinish={onFinish} initialValues={{ remember: true }}>
				<Row
					className="ant-row"
					style={{ rowGap: "15px" }}
					// justify="space-between"
				>
					<Divider orientation="left" plain>Patient Details</Divider>
					
					
					<Col lg={8} md={8} sm={24} xs={24}>
						<p style={{ textAlign:"center" }}>Name: {details.patient_name}</p>
					</Col>
					<Col lg={8} md={8} sm={24} xs={24}>
						<p style={{ textAlign:"center" }}>Age: {details.age}</p>
					</Col>
					<Col lg={8} md={8} sm={24} xs={24}>
						<p style={{ textAlign:"center" }}>Weight: {details.weight}</p>
					</Col>
					
					<Divider orientation="left" plain>
						Prescription
					</Divider>
					
					<Col lg={8} md={8} sm={12} xs={24}>
						<Form.Item
							label="Problems"
							rules={[
								{ required: true, message: "Patient Weight is required" },
							]}
							name="problems"
							className="form-lblock"
						>
							<Input maxLength={10} placeholder="Ex: Problem1, Problem2" />
						</Form.Item>
					</Col>
					
					<Col lg={8} md={8} sm={12} xs={24}>
						<Form.Item
							label="Pathology Tests"
							name="diagnostic_test"
							className="form-lblock"
						>
							<Select
								showSearch
								placeholder="Select An Option"
								style={{ width: "100%" }}
								filterOption={(input, option) =>
									option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
								}
							>
							</Select>
						</Form.Item>
					</Col>
					
					{/*<Col lg={8} md={8} sm={12} xs={24}>*/}
					{/*	<Form.Item*/}
					{/*		label="Highlighted Instruction"*/}
					{/*		rules={[*/}
					{/*			{ required: true, message: "Patient Weight is required" },*/}
					{/*		]}*/}
					{/*		name="highlighted_instructions"*/}
					{/*		className="form-lblock"*/}
					{/*	>*/}
					{/*		<Input maxLength={10} placeholder="Ex: Instruction1, Instruction2" />*/}
					{/*	</Form.Item>*/}
					{/*</Col>*/}

					<Col lg={24} md={24} sm={24} xs={24}>
						<Card>
							<Row>
								<Col lg={6} md={6} sm={12} xs={24}>
									<Form.Item
										label="Medicine Type"
										name="medicine_type"
										className="form-lblock"
										rules={[
											{ required: true, message: "Medicine Type is required" },
										]}
									>
										<Select
											showSearch
											placeholder="Select An Option"
											style={{ width: "100%" }}
											filterOption={(input, option) =>
												option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
											}
										>
											<Option value="1">Capsule</Option>
											<Option value="2">Tablet</Option>
											<Option value="3">Syrup</Option>
											<Option value="4">Injection</Option>
											<Option value="5">Saline</Option>
											<Option value="6">Spray</Option>
											<Option value="7">Nasal Spray</Option>
											<Option value="8">Inhelear</Option>
										</Select>
									</Form.Item>
								</Col>
								<Col lg={6} md={6} sm={12} xs={24}>
									<Form.Item
										label="Medicine Name"
										name="medicine_name"
										className="form-lblock"
										rules={[
											{ required: true, message: "Medicine Name is required" },
										]}
									>
										{/*<Input placeholder="Medicine" onChange={onSearch}/>*/}
										<Input placeholder="Medicine" onChange={onSearch}/>
										{/*<div>*/}
										{/*	{*/}
										{/*		medicine.slice(0, 10).map((item, index) =>*/}
										{/*			<li className="list-group-item" key={index} onClick={() => setValue(item.id, item.brand_name)}>{item.brand_name}</li>*/}
										{/*		)*/}
										{/*	}*/}
										{/*</div>*/}
									</Form.Item>
								</Col>
								<Col lg={6} md={6} sm={12} xs={24}>
									<Form.Item
										label="Times"
										name="times"
										className="form-lblock"
										rules={[
											{ required: true, message: "Time is required" },
										]}
									>
										<Input placeholder="Ex: Day+Noon+Night"/>
									</Form.Item>
								</Col>
								<Col lg={6} md={6} sm={12} xs={24}>
									<Form.Item
										label="Taking Times"
										name="taking_time"
										className="form-lblock"
										rules={[
											{ required: true, message: "Taking Time is required" },
										]}
									>
										<Input placeholder="After Meal Or Before Meal"/>
									</Form.Item>
								</Col>
								<Col lg={6} md={6} sm={12} xs={24}>
									<Form.Item
										label="Days"
										name="days"
										className="form-lblock"
										rules={[
											{ required: true, message: "Days is required" },
										]}
									>
										<Input placeholder="Days"/>
									</Form.Item>
								</Col>
								<Col lg={6} md={6} sm={12} xs={24}>
									<Form.Item
										label="Remark"
										name="remark"
										className="form-lblock"
									>
										<TextArea rows={1} placeholder="Remarks"/>
									</Form.Item>
								</Col>
								<Col lg={2} md={2} sm={6} xs={6}>
									<Button type="secondary" onClick={handleAddRows} style={{marginTop:"36px"}}>
										Add
									</Button>
								</Col>
							</Row>
							<Row>
								<Col lg={24} md={24} sm={24} xs={24}>
									
									<table className="stable table table-bordered table-responsive-md">
										<thead>
										<tr>
											<th>Medicine Type</th>
											<th>Medicine</th>
											<th>Times</th>
											<th>Taking Time</th>
											<th>Days</th>
											<th>Remarks</th>
											<th>Action</th>
										</tr>
										</thead>
										<tbody>
										{
											state.rows.map((item,index) =>
												<tr key={index}>
													<td>{item.medicine_type}</td>
													<td>{item.medicine_name}</td>
													<td>{item.times}</td>
													<td>{item.taking_time}</td>
													<td>{item.days}</td>
													<td>{item.remark}</td>
													<td>
														{/*<DeleteOutlined onClick={handleDelete}/>*/}
														
														<td style={{ textAlign:"right"}}>
															<Popconfirm
																title="Are you sure to delete this item?"
																onConfirm={() => handleDelete(index)}
																// onCancel={cancel}
																okText="Yes"
																cancelText="No"
															>
																<p style={{ cursor: "pointer" }} className="btn btn-text-adjust btn-circle-md"><i className="icon icon-trash"/></p>
															</Popconfirm>
														
														</td>
														
													</td>
												</tr>
											)
										}
										</tbody>
									</table>
								</Col>
							</Row>
						</Card>
					</Col>
					
					<Col lg={12} md={12} sm={24} xs={24}>
						<Form.Item
						label="Instructions"
						name="instructions"
						className="form-lblock"
						>
							<CKEditor
								activeClass="p10"
								content={remarks}
								events={{
									"change": handleInstructions
								}}
							/>
						</Form.Item>
					</Col>
					
					<Col lg={12} md={12} sm={24} xs={24}>
						<Form.Item
							label="Remarks"
							name="remarks"
							className="form-lblock"
						>
							<CKEditor
								activeClass="p10"
								content={remarks}
								events={{
									"change": handleRemarks
								}}
							/>
						</Form.Item>
					</Col>
					
					<Col lg={6} md={6} sm={12} xs={24}>
						<Form.Item
							label="Next Follow Up"
							name="next_followup"
							className="form-lblock"
						>
							<Input placeholder="Next Follow Up"/>
						</Form.Item>
					</Col>
					
					
					
					{/*<Col lg={12} md={12} sm={24} xs={24}>*/}
					{/*	<Form.Item*/}
					{/*		name="highlighted_instructions"*/}
					{/*		label="Highlighted Instruction"*/}
					{/*		className="form-lblock"*/}
					{/*	>*/}
					{/*		<CKEditor*/}
					{/*			activeClass="p10"*/}
					{/*			content={remarks}*/}
					{/*			events={{*/}
					{/*				"change": handleChange*/}
					{/*			}}*/}
					{/*		/>*/}
					{/*	</Form.Item>*/}
					{/*</Col>*/}
					
					{/*<Col lg={6} md={6} sm={24} xs={24}>*/}
					{/*	<Form.Item*/}
					{/*		name="remarks"*/}
					{/*		label="Remarks"*/}
					{/*		className="form-lblock"*/}
					{/*	>*/}
					{/*		<CKEditor*/}
					{/*			activeClass="p10"*/}
					{/*			content={remarks}*/}
					{/*			events={{*/}
					{/*				"change": handleChange*/}
					{/*			}}*/}
					{/*		/>*/}
					{/*	</Form.Item>*/}
					{/*</Col>*/}
					
					{/*<Col lg={8} md={8} sm={12} xs={24}>*/}
					{/*	<Form.Item*/}
					{/*		name="prev_prescription"*/}
					{/*		label="Have Any Previous Prescription?"*/}
					{/*		className="form-lblock"*/}
					{/*	>*/}
					{/*		<input type="file"/>*/}
					{/*	</Form.Item>*/}
					{/*</Col>*/}
					
					<Col lg={24} xs={24} style={{ textAlign: "center" }}>
						<Button type="secondary" htmlType="back"  onClick={() => history.push(redirectto)}>
							Back
						</Button>
						<Button type="primary" htmlType="submit">
							Save
						</Button>
					</Col>
				</Row>
			</Form>
		</Card>
	);
};

export default NewPrescription;