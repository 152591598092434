import React from "react";
import { Breadcrumb, Col, Input, Layout, Row, Form, notification, Card } from "antd";
import { Button } from "antd";
import { CisUI } from "../../../util/CISUI";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";

import {useDispatch} from "react-redux";
import {UpdatePassword} from "../../../appRedux/actions";

const ChangePassword = () => {
    const userID = CisUI().getLocalUserID();
    const dispatch = useDispatch();
    const onFinish = (values) => {
        dispatch(UpdatePassword(values, userID));

    };

    return (
        <Card title="Update Password">
            {CisUI().showLoading}
            <Form onFinish={onFinish}>
                <Row className="ant-row"
                     style={{ rowGap: "15px" }}
                >
                    <Col lg={8} md={8} sm={12} xs={24}>
                        <Form.Item
                            label="Old Password"
                            rules={[
                                { required: true, message: "Password is required" },
                            ]}
                            name="old_password"
                            className="form-lblock"
                        >
                            <Input.Password placeholder="old password" iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} />
                        </Form.Item>
                    </Col>

                    <Col lg={8} md={8} sm={12} xs={24}>
                        <Form.Item
                            name="password"
                            label="Password"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your password!',
                                },
                            ]}
                            className="form-lblock"
                        >
                            <Input.Password placeholder="new password" iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} />
                        </Form.Item>
                    </Col>

                    <Col lg={8} md={8} sm={12} xs={24}>
                        <Form.Item
                            name="confirm"
                            label="Confirm Password"
                            dependencies={['password']}
                            className="form-lblock"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please confirm your password!',
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                    },
                                }),
                            ]}
                        >
                            <Input.Password placeholder="confirm password" iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} />
                        </Form.Item>
                    </Col>

                    <Col lg={24} xs={24} style={{ textAlign: "center" }}>
                        {/*<Button type="primary" htmlType="submit">*/}
                        {/*	Save*/}
                        {/*</Button>*/}
                        <Button type="secondary" htmlType="reset">
                            Reset
                        </Button>
                        <Button type="primary" htmlType="submit">
                            Change Password
                        </Button>
                    </Col>
                </Row>
            </Form>
        </Card>
    );
};

export default ChangePassword;
