import React, { useState, } from "react";
import { Card, Collapse, Form, } from "antd";
import { Link, useHistory, } from "react-router-dom";
import { CisUI } from "../../../util/CISUI";
import ApiUrl from "../../../APIURL/Doctor/ApiUrl";
import SmartDataTable from 'react-smart-data-table';
import IntlMessages from "../../../util/IntlMessages";
import ActionMenu from "../../../util/ActionMenu";
const { Panel } = Collapse;

const AccountList = () => {
    const [state1, setState1] = useState({
        reqURL: ApiUrl.bank_account_list
    });

    const history = useHistory();
    const [form] = Form.useForm();

    const headers1 = {
        _id: {
            text: 'SL No',
            invisible: true,
            filterable: false,
            transform: (value) => `Row #${value + 1}`,
        },
        id: {
            text: <IntlMessages id="Sl NO"/>,
            sortable: true,
            filterable: true,
            transform: (value, id, row) => (<p style={{textAlign: "center"}}>{id+1}</p>)
        },
        account_type: {
            text: <IntlMessages id="Type"/>,
            sortable: true,
            filterable: true,
            invisible:true
        },
        bank_name: {
            text: <IntlMessages id="Bank Name"/>,
            sortable: true,
            filterable: true,
        },
        account_name: {
            text: <IntlMessages id="Account Name"/>,
            sortable: true,
            filterable: true,
        },
        account_no: {
            text: <IntlMessages id="Account No."/>,
            sortable: true,
            filterable: true,
        },
        branch_name: {
            text: <IntlMessages id="Branch Name"/>,
            sortable: true,
            filterable: true,
        },
        status: {
            text: <IntlMessages id="Status"/>,
            sortable: true,
            filterable: true,
            transform: (value, id, row) => (CisUI().getAccountStatusLabel(value)
            )
        },
        full_name:{
            invisible:true
        },
        routing_no:{
            invisible:true
        },
        actions: {
            text: <IntlMessages id="Actions"/>,
            sortable: false,
            filterable: false,
            transform: (value, idx, row) => (
                <>
                    {ActionMenu('/doctor/account/editaccount/',ApiUrl.delete_account,row.id)}
                </>
            ),
        },
    };
    const orderedHeaders1 = ["id", "account_type", "bank_name", "account_name", "account_no", "branch_name","status", "actions"];

    const [state2, setState2] = useState({
        reqURL: ApiUrl.mb_account_list
    });

    const headers2 = {
        _id: {
            text: 'SL No',
            invisible: true,
            filterable: false,
            transform: (value) => `Row #${value + 1}`,
        },
        id: {
            text: <IntlMessages id="Sl NO"/>,
            sortable: true,
            filterable: true,
            transform: (value, id, row) => (<p style={{textAlign: "center"}}>{id+1}</p>)
        },
        account_type: {
            text: <IntlMessages id="Type"/>,
            sortable: true,
            filterable: true,
            invisible:true
        },

        mb_operator: {
            text: <IntlMessages id="Operator"/>,
            sortable: true,
            filterable: true,
        },
        mb_account_name: {
            text: <IntlMessages id="Account Name"/>,
            sortable: true,
            filterable: true,
        },
        mobile_no: {
            text: <IntlMessages id="Number"/>,
            sortable: true,
            filterable: true,
        },
        status: {
            text: <IntlMessages id="Status"/>,
            sortable: true,
            filterable: true,
            transform: (value, id, row) => (CisUI().getAccountStatusLabel(value)
            )
        },
        full_name:{
            invisible:true
        },
        actions: {
            text: <IntlMessages id="Actions"/>,
            sortable: false,
            filterable: false,
            transform: (value, idx, row) => (
                <>
                    {ActionMenu('/doctor/account/editaccount/',ApiUrl.delete_account,row.id)}
                </>
            ),
        },
    };
    const orderedHeaders2 = ["id", "account_type", "mb_operator","mb_account_name","mobile_no","status", "actions"];

    return (
        <>

            <Card className="tickets-card" title="Accounts List">
                <Card className="tickets-card" title="Bank Account List">
                <SmartDataTable
                    name='data_table'
                    data={state1.reqURL}
                    dataRequestOptions={CisUI().HeaderRequest}
                    dataKey="data"
                    headers={headers1}
                    orderedHeaders={orderedHeaders1}
                    hideUnordered={CisUI().hideUnordered}
                    className={CisUI().sematicUI.table}
                    // filterValue={state.filterValue}
                    perPage={CisUI().perPage}
                    sortable
                    withLinks
                    withHeader
                    loader={CisUI().loader}

                    emptyTable={CisUI().emptyTable}
                />
                </Card>
                <Card className="tickets-card" title="Mobile Account List">
                    <SmartDataTable
                        name='data_table'
                        data={state2.reqURL}
                        dataRequestOptions={CisUI().HeaderRequest}
                        dataKey="data"
                        headers={headers2}
                        orderedHeaders={orderedHeaders2}
                        hideUnordered={CisUI().hideUnordered}
                        className={CisUI().sematicUI.table}
                        // filterValue={state.filterValue}
                        perPage={CisUI().perPage}
                        sortable
                        withLinks
                        withHeader
                        loader={CisUI().loader}

                        emptyTable={CisUI().emptyTable}
                    />
                </Card>
            </Card>
        </>
    );
};

export default AccountList;
