import { ArrowDownOutlined, DownloadOutlined, LoadingOutlined } from "@ant-design/icons";
import { Button, Card, Col, Input, Row } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Loading from "../../../components/Loading";
import { apiserver } from "../../../util/config";

const { Search } = Input;


const MedicineList = () => {
    const [medicines, setMedicines] = useState([]);
    const [allmedicines, setAllMedicines] = useState([]);
    const [number, setNumber] = useState(20);
    const [loading, setLoading] = useState(false);

    const getMedicines = () => {
        setLoading(true)
        axios.get(apiserver + "allmedicines")
            .then(function (response) {
                // handle success
                console.log(response);
                setMedicines(response.data.data)
                setAllMedicines(response.data.data)
                setLoading(false)
            })
            .catch(function (error) {
                // handle error
                console.log(error);
                setLoading(false)
            });
    };
    useEffect(() => {
        getMedicines()
    }, [])
    const loadMore = (() => {
        setNumber(number + 20)
    })

    const onSearch = ((e) => {
        console.log(e.target.value);

        const searchData = []

        allmedicines.map((item, index) => {
            if (item?.brand_name?.toLowerCase().includes(e?.target?.value.toLowerCase())) {
                searchData.push({
                    id: item.id,
                    company_name: item.company_name,
                    brand_name: item.brand_name,
                    generic_name: item.generic_name,
                    strength: item.strength,
                    medicine_type: item.medicine_type,
                    uses_for: item.uses_for
                })
            }
        })
        setMedicines(searchData)
    })


    return (
        loading ? <Loading /> :
            <>
                <Card>
                    <Search placeholder="Search medicine..." size="large" shape="round" onChange={onSearch} />
                    <Row>
                        {medicines.slice(0, number).map((product, index) => (
                            <Col xl={6} lg={12} md={12} sm={12} xs={24} key={index} className={{ textAlign: 'center' }}>

                                <Card className="medicine_card">
                                    <img src={product.medicine_type === "Tablet" ? "/assets/images/tablet.png" : "/assets/images/capsule.png"} alt={product.generic_name} />
                                    <br />
                                    {product.brand_name}
                                    <br />
                                    {product.generic_name}
                                    <br />
                                    {product.strength}
                                    <br />
                                    {product.company_name}

                                </Card>
                            </Col>
                        ))}


                    </Row>
                    <div style={{ textAlign: "center" }}>
                        <Button shape="round" size="large" onClick={loadMore} icon={<ArrowDownOutlined />}>Load More</Button>
                    </div>
                </Card>

            </>
    );
};

export default MedicineList;
