import React from "react";
import {
    Menu,
    Popconfirm,
    message,
    Dropdown,
    Popover,
    Tooltip,
    Button,
} from "antd";
import { Link } from "react-router-dom";
import axios from "axios";
import { CisUI } from "./CISUI";
import ApiUrl from "../APIURL/Admin/ApiUrl";
import IntlMessages from "./IntlMessages";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

const ActionMenu = (edit_endpoint, delete_endpoint, id) => {
    function confirm(e) {
        // console.log(e);
        axios
            .delete(delete_endpoint + `/` + id, CisUI().HeaderRequest)
            .then((res) => {
                if (res.data.status == 1) {
                    CisUI().Notification("success", res.data.msg);
                    // history.push(redirectto);
                    // setState({
                    // 	reqURL: ApiUrl.patient_list
                    // })
                    // message.success("Click on Yes");
                    window.location.reload();
                } else {
                    CisUI().Notification("error", res.data.msg);
                }
            });
    }

    function cancel(e) {
        // console.log(e);
        // message.error("Click on No");
    }

    const menu = (
        <Menu>
            <Menu.Item key={`edit_` + id}>
                <Link to={edit_endpoint + `${id}`}>
                    {/*<Button*/}
                    {/*	size="small"*/}
                    {/*	type="primary"*/}
                    {/*	className="list-table-btn"*/}
                    {/*	block*/}
                    {/*>*/}
                    {/* <i className="icon icon-edit" /> Edit */}

                    {/*</Button>*/}

                    <Tooltip title="Edit">
                        <Button shape="circle" icon={<EditOutlined />} />
                    </Tooltip>
                </Link>
            </Menu.Item>
            {/*<Menu.Item key={`delete_` + id} >*/}
            {/*		<span*/}
            {/*			onClick={() => {*/}
            {/*				showConfirm(id);*/}
            {/*			}}*/}
            {/*		>*/}
            {/*			<i className="icon icon-trash"/> Delete*/}
            {/*		</span>*/}
            {/*</Menu.Item>*/}
            <Popconfirm
                title="Are you sure to delete this task?"
                onConfirm={confirm}
                onCancel={cancel}
                okText="Yes"
                cancelText="No"
            >
                <i className="icon icon-trash" /> Delete
            </Popconfirm>
        </Menu>
    );

    const content = (
        <div className="action_menu">
            <p className="edit_btn">
                <Link to={edit_endpoint + `${id}`}>
                    {/*<button className="btn btn-text btn-circle">*/}
                    {/*	<i className="icon icon-edit" />*/}
                    {/*</button>*/}
                    <i className="icon icon-edit" />
                    <IntlMessages id="Edit" />
                    {/* <Tooltip title="Edit"> */}
                    {/*<Button shape="circle" size="small" icon={<EditOutlined />}/>*/}
                    {/* </Tooltip> */}
                </Link>
            </p>
            <p className="delete_btn">
                {/*<Link to="#">*/}
                <Popconfirm
                    title="Are you sure you want to delete this?"
                    onConfirm={confirm}
                    onCancel={cancel}
                    okText="Yes"
                    cancelText="No"
                >
                    {/*<button className="btn btn-danger btn-circle">*/}
                    <span className="delete_btn_wrap"><i className="icon icon-trash" />
                    <IntlMessages id="Delete" /></span>
                    {/*</button>*/}
                    {/*<Button shape="circle" size="small" icon={<DeleteOutlined />} title="Delete" />*/}
                </Popconfirm>
                {/*</Link>*/}
            </p>
        </div>
    );

    return (
        // <Dropdown overlay={menu} placement="bottomCenter">
        // 	<i className="icon icon-ellipse-v"></i>
        // </Dropdown>

        <Popover content={content}>
            <i className="icon icon-ellipse-v" />
        </Popover>
    );
};

export default ActionMenu;
