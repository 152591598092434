import { ArrowDownOutlined, DownloadOutlined, LoadingOutlined } from "@ant-design/icons";
import {Avatar, Button, Card, Col, Collapse, Form, Input, Rate, Row} from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Loading from "../../../components/Loading";
import { apiserver } from "../../../util/config";
import ApiUrl from "../../../APIURL/Patient/ApiUrl";
import {useHistory} from "react-router-dom";
import AdvancedSearch from "./AdvancedSearch";
import {CisUI} from "../../../util/CISUI";
const {Panel} = Collapse;

const { Search } = Input;


const DoctorList = () => {
    const [doctors, setDoctors] = useState([]);
    const [number, setNumber] = useState(8);
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const [form] = Form.useForm();
    const getDoctors = () => {
        setLoading(true)
        axios.get(ApiUrl.doctor_list, CisUI().HeaderRequest)
            .then(function (response) {
                // handle success
                console.log("doctor",response.data);
                setDoctors(response.data.data)
                setLoading(false)
            }).catch(function (error) {
                // handle error
                console.log(error);
                setLoading(false)
            });
    };
    useEffect(() => {
        getDoctors()
    }, [])
    const loadMore = (() => {
        setNumber(number + 8)
    })

    // const onSearch = ((e) => {
    //     console.log(e.target.value);
    //
    //     const searchData = []
    //
    //     doctors.map((item, index) => {
    //         if (item?.brand_name?.toLowerCase().includes(e?.target?.value.toLowerCase())) {
    //             searchData.push({
    //                 id: item.id,
    //                 company_name: item.company_name,
    //                 brand_name: item.brand_name,
    //                 generic_name: item.generic_name,
    //                 strength: item.strength,
    //                 medicine_type: item.medicine_type,
    //                 uses_for: item.uses_for
    //             })
    //         }
    //     })
    //     setDoctors(searchData)
    // })
    const onFinish = values => {
        console.log('Received values of form: ', values);
        const qs = Object.keys(values).map(key => `${key}=${values[key]}`).join('&');
        const qsup = qs.replaceAll("undefined","");
        //reqURL = reqURL+"/?"+qsup;
        history.push("?"+qsup);
        const newURL = ApiUrl.doctor_list+"/?"+qsup;
        axios.get(newURL,CisUI().HeaderRequest)
            .then(function (response) {
                // handle success
                console.log(response);
                setDoctors(response.data.data)
                setLoading(false)
            })
            .catch(function (error) {
                // handle error
                console.log(error);
                setLoading(false)
            });
    };

    const searchView  = (
        <Card className="advance-search-card">
            <Collapse expandIconPosition="right" defaultActiveKey={['1']}>
                <Panel header="Advanced Search" key="1">
                    <Form
                        form={form}
                        name="advanced_search"
                        className="ant-advanced-search-form"
                        onFinish={onFinish}
                    >
                        {AdvancedSearch}
                    </Form>
                </Panel>
            </Collapse>
        </Card>
    );


    return (
        loading ? <Loading /> :
            <>
                {searchView}
                <Card>
                    {/*<Search placeholder="Search Doctor..." size="large" shape="round" onChange={onSearch} />*/}
                    <Row>
                        {doctors!=null? (doctors.slice(0, number).map((doctor, index) => (

                            <Col xl={6} lg={12} md={12} sm={12} xs={24} key={index} className={{ textAlign: 'center' }}>

                                <Card className="medicine_card height-95" style={{textAlign:"center"}}>
                                    {/*<img src={doctor.image} alt={doctor.full_name} width={120} className={""} />*/}
                                    <Avatar
                                        size={100}
                                        src={doctor.image}
                                    />
                                    <br/>
                                    <span className={"text-bold text-size-10"}>{doctor.full_name}</span>
                                    <br/>
                                    <span className={"text-bold"}>Degree: </span>{doctor.degree}
                                    <br/>
                                    <span className={"text-bold"}>Department : </span>{doctor.department_name}
                                    <br/>
                                    <span className={"text-bold"}>Specialist: </span>{doctor.specialist}
                                    <br/>
                                    <span className={"text-bold"}>Fees: </span>{doctor.fees}
                                    <br/>
                                    <Rate disabled defaultValue={doctor.rating} />
                                    <br/>
                                    <Button href={'/patient/appointment/new'+"?id="+doctor.id} >Appointment</Button>
                                </Card>

                            </Col>
                        ))): 'No Data Found'}


                    </Row>
                    <div style={{ textAlign: "center" }}>
                        {/*{console.log(doctors.length,number)}*/}
                        {doctors!=null &&
                            doctors.length>number?<Button shape="round" size="large" onClick={loadMore} icon={<ArrowDownOutlined />}>Load More</Button>:''
                        }

                    </div>
                </Card>

            </>
    );
};

export default DoctorList;
