import React from "react";
import { Route, Switch } from "react-router-dom";
import Account from "./account";
import Appointment from "./appointment";
import Dashboard from "../doctor/dashboard";
import Medicine from "./medicine";
import Prescription from "./prescription";
import Settings from "../doctor/settings";
import Withdraw from "./withdraw";
import Report from "./report";
const App = ({ match }) => (

    <Switch>
        <Route path={`${match.url}/dashboard`} component={Dashboard} />
        <Route path={`${match.url}/account`} component={Account} />
        <Route path={`${match.url}/appointment`} component={Appointment} />
        <Route path={`${match.url}/report`} component={Report} />
        <Route path={`${match.url}/withdraw`} component={Withdraw} />
        <Route path={`${match.url}/medicinelist`} component={Medicine} />
        <Route path={`${match.url}/prescription`} component={Prescription} />
        <Route path={`${match.url}/settings`} component={Settings} />
    </Switch>


);

export default App;
