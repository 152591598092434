import {GET_ALL_DEPARTMENT} from "../../constants/ActionTypes";

const INIT_STATE = {
	departments: [],
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
		
		case GET_ALL_DEPARTMENT: {
			return {...state, departments: action.payload};
		}
		
		default:
			return state;
	}
}
