import React from "react";
import { Route, Switch } from "react-router-dom";
import { useSelector } from "react-redux";
import Admin from "./admin";
import Doctor from "./doctor";
import Patient from "./patient";


const App = ({ match }) => {
  const { authUser } = useSelector(({ auth }) => auth);
  return (
    <div className="gx-main-content-wrapper">
      <Switch>
        {authUser.area === 1 && <Route path={`${match.url}admin`} component={Admin} />}
        {authUser.area === 5 && <Route path={`${match.url}doctor`} component={Doctor} />}
        {authUser.area === 7 && <Route path={`${match.url}patient`} component={Patient} />}
      </Switch>
    </div>

  )
};

export default App;
