const Config = require("../../util/config");


module.exports = {
    appointment_list: Config.apiserver + 'appointment/doctorappointmentlist',
    appointment_details: Config.apiserver + 'appointment/doctorappointmentdetails',

    //For Account
    bank_account_list: Config.apiserver + 'doctor/bankaccounts',
    mb_account_list: Config.apiserver + 'doctor/mobileaccounts',
    add_account: Config.apiserver + 'doctor/addaccount',
    show_account: Config.apiserver + 'doctor/showaccount',
    update_account: Config.apiserver + 'doctor/updateaccount',
    delete_account: Config.apiserver + 'doctor/deleteaccount',
    acc_type_by_accounts: Config.apiserver + 'account/acctypebyaccounts',
    
    // For profile settings
    get_doctor_profile: Config.apiserver + 'doctor/doctorinfoshowweb',
    update_general_info: Config.apiserver+ 'doctor/updatedoctorinfo',
    update_consultant_info: Config.apiserver+ 'doctor/updateconsultantinfo',

	// For Dashboard
	doctor_online: Config.apiserver + 'doctor/online',
	doctor_offline: Config.apiserver + 'doctor/offline',
	dashboard: Config.apiserver + 'doctor/dashboard'
}
