import React from "react";
import { Route, Switch } from "react-router-dom";
import NewPrescription from "../../../containers/DoctorPortal/Prescription/NewPrescription";

const Prescription = ({ match }) => (

    <Switch>
        <Route path={`${match.url}/createprescription/:id`} component={NewPrescription}/>

    </Switch>
);

export default Prescription;
