import React from "react";
import { Route, Switch } from "react-router-dom";
import Dashboard from "./dashboard";
import Doctors from "./doctors";
import Settings from "./settings";
import Payment from "./payment";
import Prescription from "./prescription";
import Appointment from "./appointment";
import Card from "./card";

const Patient = ({ match }) => (

    <Switch>
        <Route path={`${match.url}/dashboard`} component={Dashboard} />
        <Route path={`${match.url}/doctors`} component={Doctors} />
        <Route path={`${match.url}/settings`} component={Settings} />
        <Route path={`${match.url}/payment-history`} component={Payment} />
        <Route path={`${match.url}/download-prescription`} component={Prescription} />
        <Route path={`${match.url}/appointment`} component={Appointment} />
        <Route path={`${match.url}/card`} component={Card} />
    </Switch>


);

export default Patient;