import {GET_ALL_MEDICINE} from "../../constants/ActionTypes";

const INIT_STATE = {
	medicines: [],
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
		
		case GET_ALL_MEDICINE: {
			return {...state, medicines: action.payload};
		}
		
		default:
			return state;
	}
}
