import React from "react";
import { Route, Switch } from "react-router-dom";
import IncomeReport from "../../../containers/DoctorPortal/Report/IncomeHistory";

const Report = ({ match }) => (

    <Switch>
        <Route path={`${match.url}/incomereport`} component={IncomeReport}/>
    </Switch>
);

export default Report;
