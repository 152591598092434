import React from "react";
import { Route, Switch } from "react-router-dom";
import AddDoctor from "../../../containers/AdminPortal/Doctor/AddDoctor";
import DoctorList from "../../../containers/AdminPortal/Doctor/DoctorList";
import EditDoctor from "../../../containers/AdminPortal/Doctor/EditDoctor";
// import EditPatient from "../../../containers/AdminPortal/Patient/EditPatient";

const Doctor = ({ match }) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route path={`${match.url}/adddoctor`} component={AddDoctor}/>
      <Route path={`${match.url}/doctorlist`} component={DoctorList}/>
      <Route path={`${match.url}/editdoctor/:id`} component={EditDoctor}/>
      {/*<Route path={`${match.url}/editdoctor/:id`} component={EditPatient}/>*/}
      {/* <Route path={`${match.url}/customerdelete/:id`} component={DeletePatient}/> */}
    </Switch>
  </div>
);

export default Doctor;
