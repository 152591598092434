const Config = require("../../util/config");

module.exports = {
    payment_history: Config.apiserver + "",
    appointment_list: Config.apiserver + "appointment/patientappointmentlist",
    hospital_list: Config.apiserver + "hospitals",

    // For Card
    my_card: Config.apiserver + "mycard",
    doctor_list: Config.apiserver + 'doctor/doctorlistwithconsultant',
    // For Appointment
    // appointment_list: Config.apiserver + 'appointment/appointmenthistory',
    request_appointment: Config.apiserver + 'appointment/requestappointment',
    appointment_details: Config.apiserver + 'appointment/patientappointmentdetails',
    single_doctor_data: Config.apiserver + 'doctor/doctorinfoshowweb',
    // for profile
    show_patient_profile: Config.apiserver + 'patient/patientinfoshow/',
    update_patient_profile: Config.apiserver + 'patient/updatepatientinfo/',
    
    // for dashboard
    patient_dashboard: Config.apiserver + 'patient/dashboard'
};
