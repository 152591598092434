import React from "react";
import { Route, Switch } from "react-router-dom";
import WithdrawHistory from "../../../containers/DoctorPortal/Withdraw/WithdrawHistory";
import WithdrawRequest from "../../../containers/DoctorPortal/Withdraw/WithdrawRequest";

const Withdraw = ({ match }) => (

    <Switch>
        <Route path={`${match.url}/withdrawhistory`} component={WithdrawHistory}/>
        <Route path={`${match.url}/withdrawrequest`} component={WithdrawRequest}/>
    </Switch>
);

export default Withdraw;
