import React, {useEffect, useState} from "react";
import {Col, Input, Row, Select, Form, Button, Card, DatePicker, Divider, Checkbox} from "antd";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import {CisUI} from "../../../util/CISUI";
import ApiUrl from "../../../APIURL/Admin/ApiUrl";
import {getAllAgent} from "../../../appRedux/actions/Agent";
import {getAllPatient} from "../../../appRedux/actions/Patient";
import {getAllDoctor} from "../../../appRedux/actions/Doctor";
import CKEditor from "react-ckeditor-component";

const { Option } = Select;

const NewAppointment = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const [image, setImage] = useState("");

	let doctors = useSelector(({ doctor }) => doctor.doctors);
	let patients = useSelector(({ patient }) => patient.patients);
	let agents = useSelector(({ agent }) => agent.agents);
	

	useEffect(() => {
		dispatch(getAllAgent());
		dispatch(getAllPatient());
		dispatch(getAllDoctor());
	}, []);
	
	const [generalInfo, setGeneralInfo] = useState({});
	const [consultantInfo, setConsultantInfo] = useState({});
	
	const handleDoctorChange = (id) => {
		axios.get(ApiUrl.single_doctor_data+"/"+id,CisUI().HeaderRequest)
			.then(res => {
				if (res.data.status === 1) {
					setGeneralInfo(res.data.general_info)
					setConsultantInfo(res.data.consultant_info)
				} else {
					console.log("error")
				}
			})
			.catch(error => {
				console.log(error);
			});
	}
	
	useEffect(() => {
		handleDoctorChange()
	}, []);
	
	
	let available_day = Object.keys(consultantInfo).length != 0 ? JSON.parse(consultantInfo.available_days) : "";
	
	
	const [remarks, setRemarks] = useState("");
	
	const handleChange = (evt) => {
		var newContent = evt.editor.getData();
		setRemarks(newContent)
	}
	
	const changeImage = (e) => {
		let file = e.target.files[0];
		let reader = new FileReader();
		// setSource(URL.createObjectURL(e.target.files[0]))
		reader.onload = (e) => {
			setImage({
				image: e.target.result,
			});
		};
		// console.log(file);
		reader.readAsDataURL(file);
	};
	
	let redirectto = '/admin/agent/agentlist'
	
	const onFinish = (values) => {
		let data = {
			...values,
			date: values['date'].format("YYYY-MM-DD"),
			// image: image
			details: remarks
		}
		
		console.log(data);
		document.getElementById("loader").style.display = "block";
		axios.post(ApiUrl.request_appointment, data, CisUI().HeaderRequest)
			.then(res => {
				console.log(res.data)
				if(res.data.status === 1) {
					let id = res.data.id;
					CisUI().Notification('success', res.data.msg)
					history.push('/admin/appointment/appointmentdetails/'+id)
				}
				else {
					CisUI().Notification('error', res.data.msg)
				}
				document.getElementById("loader").style.display = "none";
			})
			.catch(error => {
				console.log(error.response)
			})
	};
	
	return (
		<Card title="New Appointment" extra={
			<Link to={redirectto} className="list_icon"><i className="icon icon-lising-dbrd" /></Link>
		}>
			{CisUI().showLoading}
			<Form onFinish={onFinish}>
				<Row
					className="ant-row"
					style={{ rowGap: "15px" }}
					// justify="space-between"
				>
					<Divider orientation="left" plain>Doctor</Divider>
					
					<Col lg={8} md={8} sm={12} xs={24}>
						<Form.Item
							label="Doctor"
							name="doctor_id"
							className="form-lblock"
							rules={[
								{ required: true, message: "Doctor is required" },
							]}
						>
							<Select
								showSearch
								placeholder="Select A Doctor"
								style={{ width: "100%" }}
								filterOption={(input, option) =>
									option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
								}
								onChange={handleDoctorChange}
							>
								{
									doctors.map((doctor, index) => (
										<Option key={++index} value={doctor.id}>
											{doctor.full_name}
										</Option>
									))
								}
							</Select>
						</Form.Item>
					</Col>
					
					{
						Object.keys(generalInfo).length != 0 && (
							<Col lg={10} md={10} sm={12} xs={24} style={{ float:"right"}}>
								<Card>
									<Row>
										<Col lg={12} md={12} sm={12} xs={12}>
											<img src={generalInfo.image != null ? generalInfo.image : "https://via.placeholder.com/150"} alt=""
											     style={{ width:"150px", height:"120px"}}/>
										{/*</Col>*/}
										{/*<Col lg={8} md={8} sm={12} xs={12} className="doctor_details">*/}
											<div className="doctor_details">
												<p>{generalInfo.full_name}</p>
												<p>{generalInfo.degree}</p>
												<p>{generalInfo.specialist}</p>
											</div>
										</Col>
										<Col lg={12} md={12} sm={12} xs={12} className="consultant_details">
											<p>Available Days: <span>{available_day.toString()}</span></p>
											<p>Time: <span>{consultantInfo.start_time} - {consultantInfo.end_time}</span></p>
											<p>Regular Fee: <span>{consultantInfo.regular_fee}</span></p>
											<p>Follow Up Fee: <span>{consultantInfo.follow_up_fee}</span></p>
											<p>Report Fee: <span>{consultantInfo.report_fee}</span></p>
											<p>Average Duration: <span>{consultantInfo.avg_duration} min</span></p>
										</Col>
									</Row>
								</Card>
								
							</Col>
						)
					}
					
					<Divider orientation="left" plain>
						Patient
					</Divider>
					
					<Col lg={8} md={8} sm={12} xs={24}>
						<Form.Item
							label="Agent"
							name="agent_id"
							className="form-lblock"
						>
							<Select
								showSearch
								placeholder="Select An Agent"
								style={{ width: "100%" }}
								filterOption={(input, option) =>
									option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
								}
							>
								{
									agents.map((agent, index) => (
										<Option key={++index} value={agent.id}>
											{agent.full_name}
										</Option>
									))
								}
							</Select>
						</Form.Item>
					</Col>
					
					<Col lg={8} md={8} sm={12} xs={24}>
						<Form.Item
							label="Patient"
							name="patient_id"
							className="form-lblock"
							rules={[
								{ required: true, message: "Patient is required" },
							]}
						>
							<Select
								showSearch
								// optionFilterProp="children"
								placeholder="Select A Patient"
								style={{ width: "100%" }}
								filterOption={(input, option) =>
									option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
								}
								// name="country"
							>
								{
									patients.map((patient, index) => (
										<Option key={++index} value={patient.id}>
											{patient.full_name}
										</Option>
									))
								}
							</Select>
						</Form.Item>
					</Col>
					
					<Col lg={8} md={8} sm={12} xs={24}>
						<Form.Item
							label="Patient Weight"
							rules={[
								{ required: true, message: "Patient Weight is required" },
							]}
							name="weight"
							className="form-lblock"
						>
							<Input placeholder="Patient Weight" />
						</Form.Item>
					</Col>
					
					<Col lg={8} md={8} sm={12} xs={24}>
						<Form.Item
							label="Appointment Type"
							rules={[
								{
									required: true,
									message: "Appointment Type is required",
								},
							]}
							name="appointment_type"
							className="form-lblock"
						>
							<Select
								placeholder="Select An Option"
								style={{ width: "100%" }}
								name="status"
							>
								<Option value="1">New</Option>
								<Option value="2">Follow Up</Option>
								<Option value="3">Showing Report</Option>
							</Select>
						</Form.Item>
					</Col>
					
					<Col lg={8} md={8} sm={12} xs={24}>
						<Form.Item
							label="Date"
							rules={[
								{ required: true, message: "Appointment Date is required" },
							]}
							name="date"
							className="form-lblock"
						>
							<DatePicker className="gx-w-100"/>
						</Form.Item>
					</Col>
					
					
					<Col lg={8} md={8} sm={12} xs={24}>
						<Form.Item
							name="problems"
							label="Have Any?"
							valuePropName="checked"
							className="form-lblock"
						>
							<Checkbox.Group>
								<Checkbox type="checkbox" id="facilities" value="Diabetics">Diabetics</Checkbox>
								<Checkbox type="checkbox" id="facilities" value="Hypertension">Hypertension</Checkbox>
								<Checkbox type="checkbox" id="facilities" value="Asthma">Asthma</Checkbox>
							</Checkbox.Group>
						</Form.Item>
					</Col>
					
					<Col lg={24} md={24} sm={24} xs={24}>
						<Form.Item
							name="remarks"
							label="Describe Your Problem"
							className="form-lblock"
						>
							<CKEditor
								activeClass="p10"
								content={remarks}
								events={{
									"change": handleChange
								}}
							/>
						</Form.Item>
					</Col>
					
					<Col lg={8} md={8} sm={12} xs={24}>
						<Form.Item
							name="prev_prescription"
							label="Have Any Previous Prescription?"
							className="form-lblock"
						>
							<input type="file"/>
						</Form.Item>
					</Col>
					
					<Col lg={24} xs={24} style={{ textAlign: "center" }}>
						<Button type="secondary" htmlType="back"  onClick={() => history.push(redirectto)}>
							Back
						</Button>
						<Button type="primary" htmlType="submit">
							Save
						</Button>
					</Col>
				</Row>
			</Form>
		</Card>
	);
};

export default NewAppointment;