import React from "react";
import Config from "../../util/config";

const Loading = () => {
  return (
    <>
      {/* <img
        src={Config.loader}
        alt="Loading"
        style={{ width: "10%", margin: "auto", display: "block",marginTop: "12%" }}
        id="loading"
      /> */}
      <div class="loader loading">
        <img src="/assets/images/loader.gif" alt="loader" />
      </div>
    </>
  );
};

export default Loading;
