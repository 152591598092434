import React, {useEffect, useState,} from "react";
import {Button, Card, Col, Collapse, Form, Row,Modal} from "antd";
import {Link, useHistory,} from "react-router-dom";
import { CisUI } from "../../../util/CISUI";
import ApiUrl from "../../../APIURL/Doctor/ApiUrl";
import axios from "axios";
const {Panel} = Collapse;


let source2= "";
const AppointmentDetails = (props) => {
   const [state, setState] = useState({
        loading: false,
        visible: false,
    });

  const  showModal = () => {
        setState({
            visible: true,
        });
    };

  const handleCancel = () => {
      setState({ visible: false });
  };

    const history = useHistory();
    const appointment_id = props.match.params.id;
    const [details, setDetails] = useState([]);
    const [image, setImage] = useState("");
    const changeImage = (e) => {
        let file = e.target.files[0];
        let reader = new FileReader();
        source2= (URL.createObjectURL(e.target.files[0]))
        reader.onload = (e)=> {
            setImage({
                image : e.target.result
            });
        };
        reader.readAsDataURL(file);
    }

    const getAppointmentDetails = (appointment_id) => {
        document.getElementById("loader").style.display = "block";
        axios.get(ApiUrl.appointment_details+"/"+appointment_id, CisUI().HeaderRequest)
            .then(res => {
                console.log(res.data)
                if(res.data.status === 1) {
                    setDetails(res.data.data);
                }
                else {
                    CisUI().Notification('error', res.data.msg)
                }
                document.getElementById("loader").style.display = "none";
            })
            .catch(error => {
                console.log(error.response)
            })
    }

    useEffect(() => {
        getAppointmentDetails(appointment_id)
    }, [appointment_id]);

    const redirectToPay = (id) => {
        window.open('https://sakib75sandboxapi.sakib75.health/aptmntdetails/'+id, '_blank');
    }

    return (
        <Col lg={14} md={24} sm={24} xs={24} style={{ display:"block", margin:"0 auto"}}>
            <Card className="tickets-card">
                {CisUI().showLoading}
                <div className="appointment_details" style={{ textAlign:"center" }}>
                    <div className="message">
                        {/*<img src="https://sakib75sandboxapi.sakib75.health/public/upload/verified.svg" alt="" style={{ width:"5%" }}/>*/}
                        <p style={{ padding:"5px 0", fontSize:"xx-large" }}>Patient Details</p>
                    </div>

                    <div className="doctor_details" style={{width: "100%", margin:"0 auto", textAlign:"center"}}>
                        <Card>
                            {/*<Row>*/}
                                {/*<Col lg={7} md={8} sm={8} xs={24} style={{ paddingRight: "0px", alignItems:"center"}}>*/}
                                {/*    /!*<img src={details.image != null ? details.image : "https://via.placeholder.com/150"} alt=""*!/*/}
                                {/*    /!*     style={{ width:"80px", height:"80px", borderRadius:"50%" }}/>*!/*/}
                                {/*</Col>*/}
                                {/*<Col lg={16} md={16} sm={16} xs={24} style={{ paddingLeft: "2px"}}>*/}
                                    <img src={details.image!= null ? details.image : "https://via.placeholder.com/150"} alt=""
                                         style={{ width:"80px", height:"80px", borderRadius:"50%" }}/>
                                    <p>Patient Name: <span style={{ color:"#3a6472" }}>{details.patient_name}</span></p>
                                    <p>Gender: <span style={{ color:"#3a6472" }}>{details.gender}</span></p>
                                    <p>Age: <span style={{ color:"#3a6472" }}>{details.age}</span></p>
                                    <p>Weight: <span style={{ color:"#3a6472" }}>{details.weight}</span></p>
                                    <p>Patient Phone: <span style={{ color:"#3a6472" }}>{details.patient_phone}</span></p>
                            <p>Appointment Details: <span style={{ color:"#3a6472" }}>{details.appointment_details}</span></p>
                                {/*</Col>*/}
                            {/*</Row>*/}
                        </Card>
                    </div>

                </div>

                <div className="info" style={{ width: "50%", margin: "0 auto"}}>
                    <p>Appointment ID: <span style={{ color:"#3a6472" }}>{details.unqid}</span></p>
                    <p>Appointment Type: <span style={{ color:"#3a6472" }}>{details.type_name}</span></p>
                    <p>Appointment Date: <span style={{ color:"#3a6472" }}>{details.date}</span></p>
                    <p>Appointment Time: <span style={{ color:"#3a6472" }}>{details.time}</span></p>
                    {details?.call_type ==3 ?
                        <p>For Call: <img src={"/assets/images/phone.png"} style={{ width:"25px", height:"25px", borderRadius:"50%" }}/> <span style={{ color:"#3a6472" }}>{details.call_id}</span></p>
                        : <p>For Call: <img src={"/assets/images/whatsapp.png"} style={{ width:"25px", height:"25px", borderRadius:"50%" }}/> <span style={{ color:"#3a6472" }}>{details.call_id}</span></p>
                    }
                    {/*<p>Patient Name: <span style={{ color:"#3a6472" }}>{details.patient_name}</span></p>*/}
                    {/*<p>Fee: <span style={{ color:"#3a6472" }}>{details.fees}</span></p>*/}
                    <p>Status: <span style={{ color:"#3a6472" }}>
						{CisUI().getAppointmentStatusText(details.status)}
                        {/*{details.status === 1 && 'Pending' }*/}
                        {/*{details.status === 0 && 'Closed' }*/}
                        {/*{details.status === 2 && 'Running' }*/}
                        {/*{details.status === 3 && 'Confirmed' }*/}
                        {/*{details.status === 4 && 'Cancel' }*/}
					</span></p>
                </div>
                <div style={{ textAlign: "center", margin:"30px 0 0 0" }}>
                    <Button type="primary" onClick={() => history.goBack()} style={{ marginBottom: "0"}}>
                        Back
                    </Button>
                    {/*{details.status === 1 && (*/}
                    {/*    <Button type="primary" onClick={() => redirectToPay(details.id)} style={{ marginBottom: "0"}}>*/}
                    {/*        Pay*/}
                    {/*    </Button>*/}
                    {/*)}*/}
                    {
                        details.status !== 1 && (
                            <>
                                {/*<Button type="primary" style={{ marginBottom: "0"}}>*/}
                                {/*    Call*/}
                                {/*</Button>*/}

                                {/*<Button type="primary" onClick={() => history.push('/doctor/prescription/createprescription/'+details.id)} style={{ marginBottom: "0"}}>*/}
                                {/*    Create Prescription*/}
                                {/*</Button>*/}
                                <Button type="primary" style={{ marginBottom: "0"}} onClick={showModal}>
                                    Upload Prescription
                                </Button>
                                <Modal
                                    visible={state.visible}
                                    title="Upload Prescription"
                                    onCancel={handleCancel}
                                    footer={[
                                        <Button key="back" onClick={handleCancel}>
                                            Return
                                        </Button>,
                                        <Button key="submit" type="primary" >
                                            Submit
                                        </Button>,
                                    ]}
                                >
                                    <input name="image" type="file"  onChange={changeImage}/>
                                    {source2!=""&&
                                        <img src={source2} alt="Image" style={{ width: "120px", height: "100px", marginTop: "10px"}}/>
                                    }

                                </Modal>
                            </>
                        )
                    }
                </div>

            </Card>
        </Col>
    );
};

export default AppointmentDetails;
