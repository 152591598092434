import React, { useState, useEffect } from "react";
import {Breadcrumb, Col, Input, Layout, Row, Select, Form, Card, DatePicker} from "antd";
import { Content } from "antd/lib/layout/layout";
import { Link, useHistory } from "react-router-dom";
import { Button } from "antd";
import Config from "../../../util/config";
import Loading from "../../../components/Loading";
import {useDispatch, useSelector} from "react-redux";
import {getUserProfileData, updateProfile} from "../../../appRedux/actions";
import {getCountry, getDistrict} from "../../../appRedux/actions/Country";
import {CisUI} from "../../../util/CISUI";
import moment from "moment";
import * as XLXS from "xlsx";


const { Option } = Select;

const ProfileSettings = () => {
	//   const serviceID = props.match.params.id;
	const history = useHistory();
	const [image, setImage] = useState("");

	const user = JSON.parse(localStorage.getItem('userData'));
	const userID = user['id'];

	const dispatch = useDispatch();
	let userData = useSelector((state) => state.auth.profileData);
	let countries = useSelector(({country}) => country.countries);
	let districts = useSelector(({district}) => district.districts);

	console.log(userData)

	useEffect(() => {
		dispatch(getUserProfileData(userID));
		dispatch(getCountry());
		dispatch(getDistrict());
	}, [userID]);

	const changeImage = (e) => {
		let file = e.target.files[0];
		let reader = new FileReader();
		reader.onload = (e)=> {
			setImage({
				image : e.target.result
			});
		};
		reader.readAsDataURL(file);
	}
	const [data, setData] = useState([]);
	const onChangeExcel = (e) => {
		let file = e.target.files[0];
		let reader = new FileReader();
		reader.readAsArrayBuffer(file);
		
		reader.onload = (e) => {
			const bufferArray = e.target.result;
			const wb = XLXS.read(bufferArray, {type:'buffer'});
			const wsname = wb.SheetNames[0];
			const ws = wb.Sheets[wsname]
			const data = XLXS.utils.sheet_to_json(ws)
			
			// console.log(data)
			
			// resolve(data)
			
			setData(data)
		}
		// console.log(file);
	}

	console.log(data);
	const onFinish = (values) => {
		// setLoading({loading:true});
		const data = {
			...values,
			image: image['image']
		}
		// console.log(data);
		// console.log(data);
		dispatch(updateProfile(data,userID))
	};
	
	return (
		<Card title="Update Profile">
			{CisUI().showLoading}
			{
				userData != '' && (
					<Form
						onFinish={onFinish}
						initialValues={{
							full_name: userData.full_name,
							email: userData.email,
							phone: userData.phone,
							nationality: userData.nationality,
							// nid: userData.nid,
							dob: moment(userData.dob),
							gender: userData.gender,
							district: parseInt(userData.district),
							country: parseInt(userData.country),
							address: userData.address,
						}}
					>
						<Row>
							<Col lg={8} md={8} sm={12} xs={24}>
								<Form.Item
									label="Name"
									rules={[
										{ required: true, message: "Name is required" },
									]}
									name="full_name"
									className="form-lblock"
								>
									<Input placeholder="Name" />
								</Form.Item>
							</Col>
							
							<Col lg={8} md={8} sm={12} xs={24}>
								<Form.Item
									label="Email"
									rules={[
										{ required: true, message: "Email is required" },
									]}
									name="email"
									className="form-lblock"
								>
									<Input placeholder="Username" />
								</Form.Item>
							</Col>
							
							<Col lg={8} md={8} sm={12} xs={24}>
								<Form.Item
									label="Phone"
									rules={[
										{ required: true, message: "Phone is required" },
									]}
									name="phone"
									className="form-lblock"
									
								>
									<Input placeholder="Phone" disabled={true} />
								</Form.Item>
							</Col>
							
							<Col lg={8} md={8} sm={12} xs={24}>
								<Form.Item
									label="Nationality"
									rules={[
										{ required: true, message: "Nationality is required" },
									]}
									name="nationality"
									className="form-lblock"
								>
									<Input placeholder="Nationality" />
								</Form.Item>
							</Col>
							
							{/* <Col lg={8} md={8} sm={12} xs={24}>
								<Form.Item
									label="NID"
									rules={[
										{ required: true, message: "NID is required" },
									]}
									name="nid"
									className="form-lblock"
								>
									<Input placeholder="NID" />
								</Form.Item>
							</Col> */}
							
							<Col lg={8} md={8} sm={12} xs={24}>
								<Form.Item
									label="Date Of Birth"
									rules={[
										{ required: true, message: "Date Of Birth is required" },
									]}
									name="dob"
									className="form-lblock"
								>
									<DatePicker className="gx-w-100"/>
								</Form.Item>
							</Col>
							
							<Col lg={8} md={8} sm={12} xs={24}>
								<Form.Item
									label="Gender"
									rules={[
										{
											required: true,
											message: "Gender is required",
										},
									]}
									name="gender"
									className="form-lblock"
								>
									<Select
										placeholder="Select An Option"
										style={{ width: "100%" }}
										name="status"
									>
										<Option value="Male">Male</Option>
										<Option value="Female">Female</Option>
										<Option value="Others">Others</Option>
									</Select>
								</Form.Item>
							</Col>
							
							<Col lg={8} md={8} sm={12} xs={24}>
								<Form.Item
									label="Address"
									rules={[
										{
											required: true,
											message: "Address is required",
										},
									]}
									name="address"
									className="form-lblock"
								>
									<Input placeholder="Address" />
								</Form.Item>
							</Col>
							
							<Col lg={8} md={8} sm={12} xs={24}>
								<Form.Item
									label="District"
									rules={[
										{ required: true, message: "District is required" },
									]}
									className="form-lblock"
									name="district"
								>
									<Select
										placeholder="Select Your Country"
										style={{ width: "100%" }}
									>
										{districts.map((district, index) => (
											<Option key={++index} value={district.id}>
												{district.name}
											</Option>
										))}
									</Select>
								</Form.Item>
							</Col>
							
							<Col lg={8} md={8} sm={12} xs={24}>
								<Form.Item
									label="Country"
									rules={[
										{ required: true, message: "Country is required" },
									]}
									className="form-lblock"
									name="country"
								>
									<Select
										placeholder="Select Your Country"
										style={{ width: "100%" }}
									>
										{countries.map((country, index) => (
											<Option key={++index} value={country.id}>
												{country.name}
											</Option>
										))}
									</Select>
								</Form.Item>
							</Col>
							
							<Col lg={8} md={8} sm={12} xs={24}>
								
								<Form.Item
									label="Image"
									className="form-lblock"
									name="image"
								>
									<input name="image" type="file"  onChange={changeImage}/>
									{ userData.image != null &&
										<img src={Config.profile_img + userData.image} alt="Image" style={{ width: "120px", height: "100px", marginTop: "10px"}}/>
									}
								</Form.Item>
							</Col>
							<Col lg={8} md={8} sm={12} xs={24}>
								
								<input name="excel" type="file"  onChange={onChangeExcel}/>
							</Col>
							<Col lg={24} xs={24} style={{ textAlign: "center" }}>
								<Button type="secondary" htmlType="reset">
									Reset
								</Button>
								<Button type="primary" htmlType="submit">
									Save
								</Button>
							</Col>
						</Row>
					</Form>
				)
			}
		</Card>
	);
};

export default ProfileSettings;
