import React from "react";
import { Route, Switch } from "react-router-dom";
import PaymentHistory from "../../../containers/PatientPortal/Payment/PaymentHistory";


const Payment = ({ match }) => (

    <Switch>
        <Route
            path={`${match.url}/`}
            component={PaymentHistory}
        />

    </Switch>
);

export default Payment;