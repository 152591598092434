import React from "react";
import {Form, Card, Col, Row, Button, Select, TimePicker, InputNumber} from 'antd';
import 'react-smart-data-table/dist/react-smart-data-table.css'
import {Link, useHistory} from "react-router-dom";
import moment from "moment";
import IntlMessages from "../../../../util/IntlMessages";
import Config from "../../../../util/config";
import axios from "axios";
import ApiUrl from "../../../../APIURL/Admin/ApiUrl";
import {CisUI} from "../../../../util/CISUI";

const { Option } = Select;

const AddConsultantInfo = (props) => {
	const [form] = Form.useForm();
	let redirectto = '/admin/doctor/doctorlist';
	
	const history = useHistory();
	
	const doctorID = props.dID;
	console.log(doctorID)
	
	let userID = 0;
	let userData = [];
	// console.log("Userid : "+props.match.params.id);
	let isEdit = 0;
	if(userID > 0) {
		isEdit = 1;
	}
	
	
	const onFinish = (values) => {
		// console.log(values);
		let data = {
			...values,
			'available_days': values['available_days'].toString(),
			'start_time': values['start_time'].format('HH:mm a'),
			'end_time': values['end_time'].format('HH:mm a'),
		}
		console.log(data, doctorID);
		// if(doctorID > 0) {
		// 	document.getElementById("loader1").style.display = "block";
		// 	axios.post(ApiUrl.add_consultant_info + "/" + doctorID, data, CisUI().HeaderRequest)
		// 		.then(res => {
		// 			// console.log(res)
		// 			if (res.data.status === 1) {
		// 				CisUI().Notification('success', res.data.msg);
		// 				history.push(redirectto);
		// 			} else {
		// 				CisUI().Notification('error', res.data.msg);
		// 			}
		// 			document.getElementById("loader1").style.display = "none";
		// 		})
		// 		.catch(errors => {
		// 			console.log(errors);
		// 		});
		// }
		// else {
		// 	CisUI().Notification('error', "Please insert doctor general info first.");
		// }
	};
	
	return (
		<Card title={<IntlMessages id="Add Consultant Info"/>}>
			
			<div id="loader1" className="loading_view loading loader" style={{display: 'none'}}>
				<div className="loading_center2">
					<div className="loader-table" style={{textAlign: 'center'}}>
						<img style={{background : 'transparent'}} src={Config.loader} />
						{/*<h4 style={{textAlign: 'center', color: 'white', marginTop: '-80px'}}>Please wait...</h4>*/}
						<h4 style={{textAlign: 'center', color: 'white', marginTop: '10px'}}>Please wait...</h4>
					</div>
				</div>
			</div>
			
			<Form onFinish={onFinish}>
				<Row
					className="ant-row"
					style={{ rowGap: "15px" }}
					// justify="space-between"
				>
					<Col lg={8} md={8} sm={12} xs={24}>
						<Form.Item
							label="Available Days"
							rules={[
								{
									required: true,
									message: "Available Days is required"
								},
							]}
							name="available_days"
							className="form-lblock"
						>
							<Select
								mode="multiple"
								// size={size}
								placeholder="Please select"
								// defaultValue={['a10', 'c12']}
								// onChange={handleChange}
								style={{ width: '100%' }}
							>
								<Option value="Saturday">Saturday</Option>
								<Option value="Sunday">Sunday</Option>
								<Option value="Monday">Monday</Option>
								<Option value="Tuesday">Tuesday</Option>
								<Option value="Wednesday">Wednesday</Option>
								<Option value="Thursday">Thursday</Option>
								<Option value="Friday">Friday</Option>
							</Select>
						</Form.Item>
					</Col>
					
					<Col lg={8} md={8} sm={12} xs={24}>
						<Form.Item
							label="Start Time"
							rules={[
								{ required: true, message: "Phone is required" },
							]}
							name="start_time"
							className="form-lblock"
							initialValue={moment()}
						>
							<TimePicker className="gx-w-100" placeholder="Start Time" use12Hours />
						</Form.Item>
					</Col>
					
					<Col lg={8} md={8} sm={12} xs={24}>
						<Form.Item
							label="End Time"
							rules={[
								{ required: true, message: "Phone is required" },
							]}
							name="end_time"
							className="form-lblock"
							// initialValue={moment()}
						>
							<TimePicker className="gx-w-100" placeholder="End Time" use12Hours />
						</Form.Item>
					</Col>
					
					<Col lg={8} md={8} sm={12} xs={24}>
						<Form.Item
							label="Regular Fees"
							rules={[
								{ required: true, message: "Regular fees is required" },
							]}
							name="regular_fee"
							className="form-lblock"
						>
							<InputNumber className="gx-w-100" placeholder="Regular Fees" />
						</Form.Item>
					</Col>
					
					<Col lg={8} md={8} sm={12} xs={24}>
						<Form.Item
							label="Follow Up Fee"
							rules={[
								{ required: true, message: "Follow Up Fee is required" },
							]}
							name="follow_up_fee"
							className="form-lblock"
						>
							<InputNumber className="gx-w-100" placeholder="Follow Up Fees" />
						</Form.Item>
					</Col>
					
					<Col lg={8} md={8} sm={12} xs={24}>
						<Form.Item
							label="Report Fee Time"
							rules={[
								{ required: true, message: "Report Fee is required" },
							]}
							name="report_fee"
							className="form-lblock"
							// initialValue={moment()}
						>
							<InputNumber className="gx-w-100" placeholder="Report Fees" />
						</Form.Item>
					</Col>
					
					<Col lg={8} md={8} sm={12} xs={24}>
						<Form.Item
							label="Average Duration"
							rules={[
								{ required: true, message: "Average Duration is required" },
							]}
							name="avg_duration"
							className="form-lblock"
						>
							<InputNumber className="gx-w-100" placeholder="Average Duration" />
						</Form.Item>
					</Col>
					
					<Col lg={24} xs={24} style={{ textAlign: "center" }}>
						<Button type="secondary" htmlType="back"  onClick={() => history.push(redirectto)}>
							Back
						</Button>
						<Button type="primary" htmlType="submit">
							Save
						</Button>
					</Col>
				</Row>
			</Form>
		</Card>
	);
};

export default AddConsultantInfo;