import React from "react";
import {Route, Switch} from "react-router-dom";
import Dashboard from "./dashboard";
import Patient from "./patient";
import Doctor from "./doctor";
import MedicineList from '../../containers/DoctorPortal/Medicine/MedicineList';
import Agent from "./agent";
import Appointment from "./appointment";
import Report from "./report";
import Prescription from  "./prescription";
import Settings from "./settings";
import Card from "./cardList";


const App = ({ match }) => (
    <Switch>
        <Route path={`${match.url}/dashboard`} component={Dashboard} />
        <Route path={`${match.url}/patient`} component={Patient} />
        <Route path={`${match.url}/doctor`} component={Doctor} />
        <Route path={`${match.url}/agent`} component={Agent} />
        <Route path={`${match.url}/appointment`} component={Appointment} />
        <Route path={`${match.url}/medicinelist`} component={MedicineList} />
        <Route path={`${match.url}/prescription`} component={Prescription} />
        <Route path={`${match.url}/report`} component={Report} />
        <Route path={`${match.url}/settings`} component={Settings} />
        <Route path={`${match.url}/card`} component={Card} />
    </Switch>
);

export default App;
