import React from "react";
import { Route, Switch } from "react-router-dom";
import NewPrescription from "../../../containers/AdminPortal/Prescription/NewPrescription";

const Prescription = ({ match }) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route path={`${match.url}/createprescription/:id`} component={NewPrescription}/>
    </Switch>
  </div>
);

export default Prescription;
