import React, { useEffect, useState } from "react";
import { Button, Form, Input, Modal, Row } from "antd";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import InfoView from "components/InfoView";
import { PhoneOutlined } from "@ant-design/icons";
import config from "../util/config";
import axios from "axios";
import { CisUI } from "../util/CISUI";
import OTPInput, { ResendOTP } from "otp-input-react";


const SignUpOtp = (props) => {
    const [OTP, setOTP] = useState("");
    const [input, setInput] = useState("")
    const [type, setType] = useState("")
    const [isModalVisible, setIsModalVisible] = useState(false);
    
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const queryType = urlParams.get('type');
    // console.log(urlParams.get('type'))
    
    useEffect(() => {
        if(queryType !== undefined || queryType === 'Patient'){
            setType('Patient')
        }
    }, []);

    const history = useHistory();
    const authUser = useSelector(({ auth }) => auth.authUser);

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };


    const onFinish = values => {
        console.log("finish", values)

        const data = {
            ...values,
            type: type.toLowerCase()
        }
        console.log(data);
        setInput(data)
    
        document.getElementById("loader").style.display = "block";
        axios.post(`${config.apiserver}signupotp`, data).then(function (response) {
            // handle success
            console.log(response);

            // setModal(true)

            if (response?.data?.status === 1) {
                setIsModalVisible(true);
                CisUI().Notification("success", response.data.msg)
                // setModal(true)
                // setInput(values)
            }
            if (response?.data?.status === 3) {
                CisUI().Notification("warning", response.data.msg)
                // NotificationManager.warning(response.data.msg);
                // history.push(`/session/signup?phone=${input?.phone}&type=${input?.type}`)
	            history.push(`/signup?phone=${data?.phone}&type=${response.data.type}&otp=${response.data.otp}`)

            }

            if (response?.data?.status === 4) {
                CisUI().Notification("warning", response.data.msg)
                // NotificationManager.warning(response.data.msg);
                // history.push(`/session/signin`)
            }

            if (response?.data?.status === 0) {
                CisUI().Notification("warning", response.data.msg)
                // setErrors(response.data.all_message)
            }
            document.getElementById("loader").style.display = "none";
        }).catch(function (error) {
            console.log(error);
            // CisUI().Notification("error", response.data.msg)
        })
    };

    useEffect(() => {
        if (authUser !== null) {
            history.push('/');
        }
    }, [authUser]);

    // const handleOk = () => {
    //     setIsModalVisible(false);
    // };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const renderResendBtn = (buttonProps) => {
        return (
            <button {...buttonProps} className="resend_btn">
                {buttonProps.remainingTime !== 0 ? `Please wait for ${buttonProps.remainingTime} sec` : "Resend"}
            </button>
        );
    };




    const onResendClick = () => {
        console.log("hello");

        console.log(input);
    
        document.getElementById("loader").style.display = "block";
        axios.post(`${config.apiserver}resendotp`, input).then(function (response) {
            // handle success
            console.log(response);
            if (response.data.status === 1) {
                CisUI().Notification("success", response.data.msg)
            }
            // setModal(true)
            document.getElementById("loader").style.display = "none";
        }).catch(function (error) {
            // handle error
            console.log(error);
        })
    }



    const handleOtpSubmit = () => {
        // console.log(input);
        const data = {
            ...input,
            "otp": OTP
        }
        console.log(data)
        // history.push(`/session/signup?phone=${input?.phone}&type=${input?.type}`)
        // history.push(`/signup?phone=${input?.phone}&type=${input?.type}`)
        document.getElementById("loader").style.display = "block";
        axios.post(`${config.apiserver}verifyotp`, data).then(function (response) {
            // handle success
            console.log(response);

            if (response.data.status === 0) {
                CisUI().Notification("warning", response.data.msg)
            }

            if (response.data.status === 1) {
                CisUI().Notification("success", response.data.msg)
                history.push(`/signup?phone=${input?.phone}&type=${input?.type}`)
            }
            document.getElementById("loader").style.display = "none";

        }).catch(function (error) {
            // handle error
            console.log(error);
        })
    }

    const buttonHandle = (e) => {
        console.log(e.target.innerText);
        setType(e.target.innerText)
    }

    return (
        <div className="gx-app-login-wrap login-wrap">
            {CisUI().showLoading}
            <div className="gx-app-login-container">
                <div className="gx-app-login-main-content">
                    <div className="gx-app-login-content">
                        <img alt="example" src="/assets/images/logo.png" className="login-logo" />
                        <Form
                            initialValues={{ remember: true }}
                            name="basic"
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            className="gx-signin-form gx-form-row0"
                            layout="vertical">


                            <Form.Item
                                rules={[
                                    { required: type === "" ? true : false, message: "Type is required!" },
                                ]}
                                name="type"

                                label="I'm a "

                            >
                                <Row justify="space-between mb-5" className="type-area">
                                    <Button className={`${type === "Patient" ? "ant-btn ant-btn-primary" : "ant-btn"}`} onClick={buttonHandle}>Patient</Button>
                                    <Button className={`${type === "Doctor" ? "ant-btn ant-btn-primary" : "ant-btn"}`} onClick={buttonHandle}>Doctor</Button>
                                    <Button className={`${type === "Agent" ? "ant-btn ant-btn-primary" : "ant-btn"}`} onClick={buttonHandle}>Agent</Button>
                                </Row>
                            </Form.Item>


                            <Form.Item
                                label="Your Mobile Number"
                                rules={[{ required: true, message: 'Phone is required!' }, { min: 11, message: 'Phone number must be 11 digits.' }, { max: 11, message: 'Phone number must be 11 digits.' }]} name="phone">
                                <Input placeholder="01829 * * * * * *" size="large" prefix={<PhoneOutlined />} />
                            </Form.Item>
                            <Form.Item className="signin-btn">
                                <Button type="primary" className="gx-mb-0" htmlType="submit">
                                    Get Otp
                                </Button>
                                <Link to="/signin">Back to Login</Link>
                            </Form.Item>
                        </Form>
                    </div>
                    <InfoView />

                </div>
            </div>
            <Modal visible={isModalVisible} onOk={handleOtpSubmit} onCancel={handleCancel} okText="Submit">
                <OTPInput value={OTP} onChange={setOTP} autoFocus OTPLength={4} otpType="number" disabled={false} className="otp_input justify-content-center" />
                <ResendOTP renderButton={renderResendBtn} className="resend_btn ant-btn ant-btn-link" onResendClick={onResendClick} />
            </Modal>
        </div>
    );
};

export default SignUpOtp;



