import {GET_ALL_DOCTOR, GET_DOCTOR} from "../../constants/ActionTypes";

const INIT_STATE = {
	doctors: [],
	doctor: [],
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
		
		case GET_ALL_DOCTOR: {
			return {...state, doctors: action.payload};
		}
		case GET_DOCTOR: {
			return {...state, doctor: action.payload}
		}
		default:
			return state;
	}
}
