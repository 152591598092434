import React from "react";
import { Route, Switch } from "react-router-dom";
import AddAgent from "../../../containers/AdminPortal/Agent/AddAgent";
import AgentList from "../../../containers/AdminPortal/Agent/AgentList";
import EditAgent from "../../../containers/AdminPortal/Agent/EditAgent";

const Agent = ({ match }) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route path={`${match.url}/addagent`} component={AddAgent}/>
      <Route path={`${match.url}/agentlist`} component={AgentList}/>
      <Route path={`${match.url}/editagent/:id`} component={EditAgent}/>
    </Switch>
  </div>
);

export default Agent;
