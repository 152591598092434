import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Collapse, Divider, Form, Input, Row, Select } from "antd";
import IntlMessages from "../../../util/IntlMessages";
import axios from "axios";
import ApiUrl from "../../../APIURL/Doctor/ApiUrl";
import {CisUI} from "../../../util/CISUI";

const { Option } = Select;
const { Panel } = Collapse;
const { TextArea } = Input;
const WithdrawRequest = () => {
    const [accountList, setAccountList] = useState([]);
    const [accDetails, setAccDetails] = useState([])
    const doctor_id = CisUI().getLocalUserByKey('doctor_id');
    const handleSelect = (e) => {
        setAccountList([])
        setAccDetails([])
        document.getElementById("loader").style.display = "block";
        axios.get(ApiUrl.acc_type_by_accounts+"?type="+e+"&doctor_id="+doctor_id, CisUI().HeaderRequest)
            .then(res => {
                if(res.data.status === 1) {
                    setAccountList(res.data.data)
                }
                else {
                    CisUI().Notification('warning', res.data.msg)
                }
                document.getElementById("loader").style.display = "none";
            })
            .catch(error => console.log(error))
    }

    const handleAccountDetails = (e) => {
        document.getElementById("loader").style.display = "block";
        axios.get(ApiUrl.show_account + `/${e}`, CisUI().HeaderRequest)
            .then(res => {
                if(res.data.status === 1){
                    setAccDetails(res.data.data)
                }
                else{
                    CisUI().Notification('warning', res.data.msg)
                }
                document.getElementById("loader").style.display = "none";
            })
            .catch(error => console.log(error))
    }


    return (
        <>
            <Card className="tickets-card" title={<IntlMessages id="With Draw Request"/>}>
                {/* <Collapse expandIconPosition="right">
                    <Panel header="Advanced Search" key="1"> */}
                {CisUI().showLoading}
                        <Form
                            // form={form}
                            name="advanced_search"
                            className="ant-advanced-search-form"
                        // onFinish={onFinish}
                        >
                            <Row
                                className="ant-row"
                                style={{ rowGap: "15px", display: "flex", alignItems: "flex-end" }}
                            // justify="space-between"
                            >
                                <Col lg={6} md={6} sm={12} xs={24}>
                                    <Form.Item
                                        // label="Amount (Available Balance: $119.50) "
                                        label="Amount"
                                        name="doctor_id"
                                        className="form-lblock"
                                    >

                                        <Input placeholder="Amount" />
                                    </Form.Item>
                                </Col>

                                <Col lg={6} md={6} sm={12} xs={24}>
                                    <Form.Item
                                        label="Account Type"
                                        name="account_type"
                                        className="form-lblock"
                                    >
                                        <Select
                                            onSelect={handleSelect}
                                            placeholder="Select An Option"
                                            style={{ width: "100%" }}
                                        >
                                            <Option value="2">Mobile Banking</Option>
                                            <Option value="1">Bank Account</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>

                                {
                                    accountList != '' && (
                                        <Col lg={6} md={6} sm={12} xs={24}>
                                            <Form.Item
                                                label="Account"
                                                name="account"
                                                className="form-lblock"
                                            >
                                                <Select
                                                    onSelect={handleAccountDetails}
                                                    placeholder="Select An Option"
                                                    style={{ width: "100%" }}
                                                >
                                                    {
                                                        accountList.map((account, index) =>
                                                            account.account_type === 1 ? (
                                                                <Option value={account.id} key={++index}>{account.account_name+" ("+account.bank_name+")"}</Option>
                                                            ) : (
                                                                <Option value={account.id} key={++index}>{account.mb_account_name+" ("+account.mb_operator+")"}</Option>
                                                            )
                                                        )
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    )
                                }


                                {
                                    accDetails != '' && accDetails.account_type === 2 && (
                                        <Col lg={24}>
                                            <Divider orientation="left" plain>
                                                Mobile Banking Information
                                            </Divider>
                                            <Row gutter={[0, 20]}>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    Account Holder Name: <span style={{fontSize:"16px", color:"#0d6efd"}}>{accDetails.mb_account_name}</span>
                                                </Col>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    Service Name: <span style={{fontSize:"16px", color:"#0d6efd"}}>{accDetails.mb_operator}</span>
                                                </Col>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    Mobile No: <span style={{fontSize:"16px", color:"#0d6efd"}}>{accDetails.mobile_no}</span>
                                                </Col>
                                            </Row>
                                        </Col>
                                    )}
                                {
                                    accDetails != '' && accDetails.account_type === 1 && (
                                        <Col lg={24}>
                                            <Divider orientation="left" plain>
                                                Bank Information:
                                            </Divider>
                                            <Row gutter={[0, 20]}>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    Bank Name: <span style={{fontSize:"16px", color:"#0d6efd"}}>{accDetails.bank_name}</span>
                                                </Col>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    Branch Name : <span style={{fontSize:"16px", color:"#0d6efd"}}>{accDetails.branch_name}</span>
                                                </Col>

                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    Account No: <span style={{fontSize:"16px", color:"#0d6efd"}}>{accDetails.account_no}</span>
                                                </Col>

                                            </Row>
                                        </Col>

                                    )}

                                {/*{pMethod=="1"&&(*/}
                                {/*    <Col lg={24}>*/}
                                {/*        <Divider orientation="left" plain>*/}
                                {/*            Bank Information:*/}
                                {/*        </Divider>*/}
                                {/*        <Row>*/}
                                {/*            <Col lg={8} md={8} sm={12} xs={24}>*/}
                                {/*                Branch Name:*/}
                                {/*            </Col>*/}

                                {/*            <Col lg={8} md={8} sm={12} xs={24}>*/}
                                {/*                Account No:*/}
                                {/*            </Col>*/}

                                {/*            <Col lg={8} md={8} sm={12} xs={24}>*/}
                                {/*                Account Name:*/}
                                {/*            </Col>*/}

                                {/*        </Row>*/}
                                {/*    </Col>*/}

                                {/*)}*/}

                                <Col lg={24}>
                                    <Divider orientation="left" plain>
                                        Others Information
                                    </Divider>
                                    <Row>
                                        <Col lg={12} md={6} sm={12} xs={24}>
                                            <Form.Item
                                                label="Note"
                                                name="note"
                                                className="form-lblock"
                                            >
                                                <TextArea rows={4} />
                                            </Form.Item>
                                        </Col>

                                    </Row>

                                </Col>


                                <Col lg={24} md={6} sm={12} xs={24}>
                                    <Button type="primary" htmlType="submit" style={{ marginBottom: "0" }}>
                                        Submit
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    {/* </Panel>
                </Collapse> */}
            </Card>


        </>
    )
}

export default WithdrawRequest
