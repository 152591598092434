import React from "react";
import {Route, Switch} from "react-router-dom";
import CardList from "../../../containers/AdminPortal/CardList/CardList";
import CardListDownload from "../../../containers/AdminPortal/CardList/CardListDownload";

const Appointment = ({match}) => (
	<div className="gx-main-content-wrapper">
		<Switch>
			<Route path={`${match.url}/card-list`} component={CardList}/>
            <Route path={`${match.url}/card-list-download`} component={CardListDownload}/>
		</Switch>
	</div>
);

export default Appointment;
