import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Popover } from "antd";
import { userSignOut } from "appRedux/actions/Auth";
import Config from "../../util/config";
import { getUserProfileData } from "../../appRedux/actions";
import { Link } from "react-router-dom";

const UserInfo = () => {
	const dispatch = useDispatch();

	const user = JSON.parse(localStorage.getItem("userData"));
	const userID = user["id"];
	const userArea = user["area"];

	let userData = useSelector((state) => state.auth.profileData);

	useEffect(() => {
		dispatch(getUserProfileData(userID));
	}, []);

	const userMenuOptions = (
		<ul className="gx-user-popover">
			<li><Link to={userArea === 1 ? `/admin/settings/profile_settings` : userArea === 5 ? `/doctor/settings/profile_settings` : userArea === 7 ? `/patient/settings/profile_settings` : ''} style={{ textDecoration: "none", color: "#545454" }}>My Account</Link></li>
			{/* <li>Connections</li> */}
			<li onClick={() => dispatch(userSignOut())}>Logout</li>
		</ul>
	);

	return (
		<div className="gx-flex-row gx-align-items-center gx-avatar-row">
			<Popover
				overlayClassName="gx-popover-horizantal"
				placement="bottomRight"
				content={userMenuOptions}
				trigger="click"
			>
				{userData.image != null ?
					<Avatar
						src={Config.profile_img + userData.image}
						className="gx-pointer"
						alt=""
						style={{ width: "40px", height: "40px" }}
					/>
					: <Avatar
						src={Config.profile_img + userData.image}
						className="gx-pointer"
						alt=""
						style={{ width: "40px", height: "40px" }}
					/>
				}
				<span
					className="gx-avatar-name"
					style={{ color: "#000", fontSize: "14px", marginLeft: "10px" }}
				>
					{userData.full_name}
					<i className="icon icon-chevron-down gx-fs-xxs gx-ml-2" />
				</span>
			</Popover>
		</div>
	);
};

export default UserInfo;
