import React from "react";
import { useSelector } from "react-redux";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import AdminMenu from "../Sidebar/json_menu/admin_menu.json";
import PatientMenu from "../Sidebar/json_menu/patient_menu.json";
import DoctorMenu from "../Sidebar/json_menu/doctor_menu.json";
import AgentMenu from "../Sidebar/json_menu/agent_menu.json";

import {
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
} from "../../constants/ThemeSetting";

const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

const HorizontalNav = () => {
  const navStyle = useSelector(({ settings }) => settings.navStyle);
  const pathname = useSelector(({ common }) => common.pathname);

  const { authUser } = useSelector(({ auth }) => auth);

  const getNavStyleSubMenuClass = (navStyle) => {
    switch (navStyle) {
      case NAV_STYLE_DEFAULT_HORIZONTAL:
        return "gx-menu-horizontal gx-submenu-popup-curve";
      case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
        return "gx-menu-horizontal gx-submenu-popup-curve gx-inside-submenu-popup-curve";
      case NAV_STYLE_BELOW_HEADER:
        return "gx-menu-horizontal gx-submenu-popup-curve gx-below-submenu-popup-curve";
      case NAV_STYLE_ABOVE_HEADER:
        return "gx-menu-horizontal gx-submenu-popup-curve gx-above-submenu-popup-curve";
      default:
        return "gx-menu-horizontal";
    }
  };

  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split("/")[1];

  // console.log(AdminMenu)
  const userData = JSON.parse(localStorage.getItem("userData"));
  const userID = parseInt(userData["area"]);
  // console.log(userID)

  const patientMenu = PatientMenu.menu.map((menu, index) => {
    if (menu.submenu?.length > 0) {
      return (<SubMenu
        key={menu.slug + menu.id}
        title={menu.name}
        className={getNavStyleSubMenuClass(navStyle)}
      >
        {menu.submenu &&
          menu.submenu.map((submenu, i) => (
            <Menu.Item key={submenu.slug + submenu.id}>
              <Link to={submenu.url}>{submenu.name}</Link>
            </Menu.Item>
          ))}
      </SubMenu>)
    }
    else {
      return (<Menu.Item key={menu.slug + menu.id} className="h-menu-design">
        <Link to={menu.url}>{menu.name}</Link>
      </Menu.Item>)
    }
  });


  const adminMenu = AdminMenu.menu.map((menu, index) => {
    if (menu.submenu?.length > 0) {
      return (<SubMenu
        key={menu.slug + menu.id}
        title={menu.name}
        className={getNavStyleSubMenuClass(navStyle)}
      >
        {menu.submenu &&
          menu.submenu.map((submenu, i) => (
            <Menu.Item key={submenu.slug + submenu.id}>
              <Link to={submenu.url}>{submenu.name}</Link>
            </Menu.Item>
          ))}
      </SubMenu>)
    }
    else {
      return (<Menu.Item key={menu.slug + menu.id} className="h-menu-design">
        <Link to={menu.url}>{menu.name}</Link>
      </Menu.Item>)
    }
  });

  const doctorMenu = DoctorMenu.menu.map((menu, index) => {
    if (menu.submenu?.length > 0) {
      return (<SubMenu
        key={menu.slug + menu.id}
        title={menu.name}
        className={getNavStyleSubMenuClass(navStyle)}
      >
        {menu.submenu &&
          menu.submenu.map((submenu, i) => (
            <Menu.Item key={submenu.slug + submenu.id}>
              <Link to={submenu.url}>{submenu.name}</Link>
            </Menu.Item>
          ))}
      </SubMenu>)
    }
    else {
      return (<Menu.Item key={menu.slug + menu.id} className="h-menu-design">
        <Link to={menu.url}>{menu.name}</Link>
      </Menu.Item>)
    }
  });

  return (
    <Menu
      defaultOpenKeys={[defaultOpenKeys]}
      selectedKeys={[selectedKeys]}
      mode="horizontal"
      className="main-top-menu"
    >
      {authUser.area === 1 && adminMenu}
      {authUser.area === 5 && doctorMenu}
      {authUser.area === 7 && patientMenu}
    </Menu>
  );
};

HorizontalNav.propTypes = {};

export default HorizontalNav;


// {
//   "id": 2,
//     "name": "Health Card",
//     "slug": "health-card",
//     "url": "/patient/card/mycard",
//     "icon": "/assets/images/icons/card.svg",
//     "submenu": []
// },