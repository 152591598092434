import React from "react";
import { Route, Switch } from "react-router-dom";
import AppointmentList from "../../../containers/DoctorPortal/Appointment/AppointmentList";
import ClosedAppointmentList from "../../../containers/DoctorPortal/Appointment/ClosedAppointmentList";
import PendingAppointmentList from "../../../containers/DoctorPortal/Appointment/PendingAppointmentList";
import AppointmentDetails from "../../../containers/DoctorPortal/Appointment/AppointmentDetails";

const Appointment = ({ match }) => (

    <Switch>
        <Route
            path={`${match.url}/appointmentlists`}
            component={AppointmentList}
        />
        <Route
            path={`${match.url}/closedappointmentlists`}
            component={ClosedAppointmentList}
        />
        <Route
            path={`${match.url}/pendingappointmentlists`}
            component={PendingAppointmentList}
        />
        <Route
            path={`${match.url}/appointmentdetails/:id`}
            component={AppointmentDetails}
        />
    </Switch>
);

export default Appointment;
