import React from "react";
import { Route, Switch } from "react-router-dom";

// import NewCard from "../../../containers/PatientPortal/Card/NewCard";
// import CardList from "../../../containers/PatientPortal/Card/CardList";
// import ClosedCard from "../../../containers/PatientPortal/Card/ClosedCard";
import MyCard from "../../../containers/PatientPortal/Card/MyCard";

const Card = ({ match }) => (
	<div className="gx-main-content-wrapper">
	<Switch>
		<Route path={`${match.url}/mycard`} component={MyCard}/>
	
	</Switch>
	</div>
);

export default Card;