import React, {useState} from "react";
import {Card, Tabs} from 'antd';
import 'react-smart-data-table/dist/react-smart-data-table.css'
import IntlMessages from "../../../util/IntlMessages";
import GeneralInfo from "./GeneralInfo/AddGeneralInfo";
import ConsultantInfo from "./ConsultantInfo/AddConsultantInfo";
import {Link} from "react-router-dom";


const { TabPane } = Tabs;

const AddDoctor = (props) => {
	
	const [doctorID, setDoctorID] = useState(0);
	
	const handleCallback = (childData) =>{
		// console.log(childData);
		setDoctorID(childData)
	}
	
	let redirectto = '/admin/doctor/doctorlist'
	
	return (
		<Card title={<IntlMessages id="Add Doctor"/>} extra={
			<Link to={redirectto} className="list_icon"><i className="icon icon-lising-dbrd" /></Link>
		}>
			
			<Tabs defaultActiveKey={1} style={{ marginBottom: 32 }}>
				<TabPane tab="General Info." key="1">
					<GeneralInfo dID={handleCallback} />
				</TabPane>
				<TabPane tab="Consultant Info." key="2">
					<ConsultantInfo dID={doctorID} />
				</TabPane>
			</Tabs>
		
		</Card>
	);
};

export default AddDoctor;