const Config = require("../../util/config");
const {apiserver} = require("../../util/config");


module.exports = {
	// For patient
	add_patient: Config.apiserver + 'patient/addpatientinfo',
	patient_list: Config.apiserver + 'patient/patientlists',
	single_patient_data: Config.apiserver + 'patient/patientinfoshow',
	update_patient: Config.apiserver + 'patient/updatepatientinfo',
	delete_patient: Config.apiserver + 'patient/deletepatientinfo',

	// For doctor
	add_general_info: Config.apiserver + 'doctor/addgeneralinfo',
	add_consultant_info: Config.apiserver + 'doctor/addconsultantinfo',
	doctor_list: Config.apiserver + 'doctor/doctorlist',
	single_doctor_data: Config.apiserver + 'doctor/doctorinfoshowweb',
	update_general_info: Config.apiserver+ 'doctor/updatedoctorinfo',
	update_consultant_info: Config.apiserver+ 'doctor/updateconsultantinfo',
	delete_doctor: Config.apiserver+ 'doctor/deletedoctor',

	//For Agent
	add_agent: Config.apiserver + 'agent/addagentinfo',
	agent_list: Config.apiserver + 'agent/agentlists',
	single_agent_data: Config.apiserver + 'agent/agentinfoshow',
	update_agent: Config.apiserver + 'agent/updateagentinfo',
	delete_agent: Config.apiserver + 'agent/deleteagentinfo',

	// For Appointment
	appointment_list: Config.apiserver + 'appointment/appointmenthistory',
	request_appointment: Config.apiserver + 'appointment/requestappointment',
	appointment_details: Config.apiserver + 'appointment/appointmentdetails',

	//For Transaction Report
	transaction_report: Config.apiserver + 'report/transactionreport',
	daily_transaction_report: Config.apiserver + 'report/dailytransactionreport',
	monthly_transaction_report: Config.apiserver + 'report/monthlytransactionreport',

	// For Profile
	my_account: Config.apiserver + 'myaccount',
	update_account: Config.apiserver + 'updatemyaccount',
	update_password: Config.apiserver + 'updatepassword',

    //card
    card_list: Config.apiserver + 'cardlists',

}
