import React from "react";
import { Route, Switch } from "react-router-dom";
import IncomeReport from "../../../containers/DoctorPortal/Report/IncomeHistory";
import AccountInfo from "../../../containers/DoctorPortal/Account/AccountInfo";
import EditAccountInfo from "../../../containers/DoctorPortal/Account/EditAccountInfo";
import AccountList from "../../../containers/DoctorPortal/Account/AccountList";

const Account = ({ match }) => (

    <Switch>
        <Route path={`${match.url}/accountinfo`} component={AccountInfo}/>
        <Route path={`${match.url}/editaccount/:id`} component={EditAccountInfo}/>
        <Route path={`${match.url}/accountlist`} component={AccountList}/>

    </Switch>
);

export default Account;
