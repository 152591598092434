import React from "react";
import {Table} from "antd";
import Widget from "components/Widget/index";
import moment from "moment";

const AppointmentList = (props) => {
  
  const get_data = props.appointmentData;
  
  const columns = [
    {
      title: 'Doctor Name',
      dataIndex: 'image',
      render: (text, record) => {
        console.log(record)
        return <div className="gx-flex-row gx-align-items-center">
          <img className="gx-rounded-circle gx-size-30 gx-mr-2" src={record.doctor_image} alt=""/>
          <p className="gx-mb-0">{record.name}</p>
        </div>
      },
    },
    {
      title: 'Appointment ID',
      dataIndex: 'unqid',
      render: (text, record) => {
        return <span className="gx-text-grey">{record.unqid}</span>
      },
    },
    {
      title: 'Date',
      dataIndex: 'date',
      render: (text, record) => {
        return <span className="gx-text-grey">{moment(record.date).format('DD-MM-YYYY')}</span>
      },
    },
    {
      title: 'Prescription',
      dataIndex: 'status',
      render: (text, record) => {
        return <span className="gx-text-primary gx-pointer">
        <i className="icon icon-forward gx-fs-sm gx-mr-2"/>
          <a href={record.status} target="_blank">Download</a>
        </span>
      },
    },
  
  ];
  
  const data = [];
  
  get_data?.data?.map((dt, index) =>
    data.push({
      key: ++index,
      name: dt.doctor_name,
      doctor_image: dt.doctor_image,
      unqid: dt.unqid,
      date: dt.appointment_date,
      status: dt.prescription_image
  
    })
  )
  
  return (
    <Widget
      title={
        <h2 className="h4 gx-text-capitalize gx-mb-0">{get_data.title}</h2>
      } extra={
      <p className="gx-text-primary gx-mb-0 gx-pointer gx-d-none gx-d-sm-block">
          <a href="appointment/new"><i className="icon icon-add-circle gx-fs-lg gx-d-inline-flex gx-vertical-align-middle"/> Add New Appointment</a></p>
    }>
      <div className="gx-table-responsive">
        <Table className="gx-table-no-bordered" columns={columns} dataSource={data} pagination={false}
               size="small"/>
      </div>
      <p className="gx-text-primary gx-mb-0 gx-pointer gx-d-block gx-d-sm-none gx-mb-0 gx-mt-3">
        <a href="appointment/new"><i className="icon icon-add-circle gx-fs-lg gx-d-inline-flex gx-vertical-align-middle"/> Add New Appointment</a></p>
    </Widget>
  );
};

export default AppointmentList;
