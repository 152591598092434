import {GET_ALL_COUNTRY, GET_ALL_DISTRICT} from "../../constants/ActionTypes";

const INIT_STATE = {
  countries: [],
  districts: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case GET_ALL_COUNTRY: {
      return {...state, countries: action.payload};
    }
    
    case GET_ALL_DISTRICT: {
      return {
        ...state,
        districts: action.payload
      };
    }

    default:
      return state;
  }
}
