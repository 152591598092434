import React, { useEffect } from "react";
import { Button, Form, Input, Col, Row, DatePicker, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { EyeInvisibleOutlined, EyeTwoTone, UserOutlined } from "@ant-design/icons";
import Config from "../util/config";
import axios from "axios";
import { CisUI } from "../util/CISUI";
import InfoView from "../components/InfoView";

const ResetPassword = (props) => {
	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);

	const token = urlParams.get('token')

	console.log(token);

	const history = useHistory();
	const dispatch = useDispatch();
	const authUser = useSelector(({ auth }) => auth.authUser);


	const onFinish = values => {
		let data = {
			...values,
			'token': token,
		}
		console.log(data)
		document.getElementById("loader").style.display = "block";
		axios.post(Config.apiserver + 'passwordreset', data)
			.then(res => {
				// handle success
				console.log(res);

				if (res.data.status === 1) {
					CisUI().Notification("success", res.data.msg)
					history.push(`/signin`)
				}
				else {
					CisUI().Notification('error', res.data.msg)
				}
				document.getElementById("loader").style.display = "none";
			}).catch(function (error) {
				// handle error
				console.log(error);
			})
	};

	useEffect(() => {
		if (authUser !== null) {
			history.push('/');
		}
	}, [authUser]);

	return (
		<>
			<div className="gx-app-login-wrap login-wrap">
				<div className="gx-app-login-container">
					<div className="gx-app-login-main-content">
						{CisUI().showLoading}
						<div className="gx-app-login-content">
							<img alt="example" src="/assets/images/logo.png" className="login-logo" />
							<Form
								initialValues={{ remember: true }}
								name="basic"
								onFinish={onFinish}
								className="gx-signin-form gx-form-row0"
								layout="vertical">

								{/*<Col lg={8} md={8} sm={12} xs={24}>*/}
								<Form.Item
									name="password"
									label="Password"
									rules={[
										{
											required: true,
											message: 'Please input your password!',
										},
									]}
								>
									<Input.Password placeholder="new password" iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} size="large" />
								</Form.Item>
								{/*</Col>*/}

								{/*<Col lg={8} md={8} sm={12} xs={24}>*/}
								<Form.Item
									name="confirm_password"
									label="Confirm Password"
									dependencies={['password']}
									rules={[
										{
											required: true,
											message: 'Please confirm your password!',
										},
										({ getFieldValue }) => ({
											validator(_, value) {
												if (!value || getFieldValue('password') === value) {
													return Promise.resolve();
												}
												return Promise.reject(new Error('The two passwords that you entered do not match!'));
											},
										}),
									]}
								>
									<Input.Password placeholder="confirm password" iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} size="large" />
								</Form.Item>
								{/*</Col>*/}

								<Form.Item className="signin-btn">

									<Button type="primary" className="gx-mb-0" htmlType="submit">
										Submit
									</Button>
								</Form.Item>

							</Form>
							<p style={{ textAlign: "center" }}>Back to Login <Link to="/signin">Sign in</Link> </p>
						</div>
						<InfoView />

					</div>
				</div>
			</div>

		</>
	);
};

export default ResetPassword;



