import React from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import UserProfile from "./UserProfile";
import AppsNavigation from "./AppsNavigation";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";
import { useSelector } from "react-redux";
import AdminMenu from "./json_menu/admin_menu.json";
import DoctorMenu from "./json_menu/doctor_menu.json";
import PatientMenu from "./json_menu/patient_menu.json";
const { SubMenu } = Menu;


const SidebarContent = ({ sidebarCollapsed, setSidebarCollapsed }) => {
  const { navStyle, themeType } = useSelector(({ settings }) => settings);
  const pathname = useSelector(({ common }) => common.pathname);
  const { authUser } = useSelector(({ auth }) => auth);

  const getNoHeaderClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
      return "gx-no-header-notifications";
    }
    return "";
  };

  const getNavStyleSubMenuClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      return "gx-no-header-submenu-popup";
    }
    return "";
  };

  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split('/')[1];


  const userData = JSON.parse(localStorage.getItem("userData"));
  const userID = parseInt(userData["area"]);

  const adminmenu = AdminMenu.menu.map((menu, index) => {
    if (menu.submenu?.length > 0) {
      return (<SubMenu
        key={menu.slug + menu.id}
        title={<span><i className="icon sidebar-icon"><img src={menu.icon} alt={menu.name} /></i><span>{menu.name}</span> </span>}
        popupClassName={getNavStyleSubMenuClass(navStyle)}
      >
        {menu.submenu &&
          menu.submenu.map((submenu, i) => (
            <Menu.Item key={submenu.slug + submenu.id}>
              <i className="icon icon-crypto" />
              <Link to={submenu.url}>{submenu.name}</Link>
            </Menu.Item>
          ))}
      </SubMenu>)
    }
    else {
      return (<Menu.Item key={menu.slug + menu.id}>
        <Link to={menu.url}>
          <i className="icon sidebar-icon"><img src={menu.icon} alt={menu.name} /></i>
          <span>{menu.name}</span>
        </Link>
      </Menu.Item>)
    }
  });

  const doctormenu = DoctorMenu.menu.map((menu, index) => {
    if (menu.submenu?.length > 0) {
      return (<SubMenu
        key={menu.slug + menu.id}
        title={<span><i className="icon sidebar-icon"><img src={menu.icon} alt={menu.name} /></i><span>{menu.name}</span> </span>}
        popupClassName={getNavStyleSubMenuClass(navStyle)}
      >
        {menu.submenu &&
          menu.submenu.map((submenu, i) => (
            <Menu.Item key={submenu.slug + submenu.id} className="sidebar-submenu">
              <i className="icon icon-crypto" />
                {/*<h1>Hello</h1>*/}
              <Link to={submenu.url}>{submenu.name}</Link>
            </Menu.Item>
          ))}
      </SubMenu>)
    }
    else {
      return (<Menu.Item key={menu.slug + menu.id}>
        <Link to={menu.url} className={"hellother"}>
          <i className="icon sidebar-icon"><img src={menu.icon} alt={menu.name} /></i>
          <span>{menu.name}</span>
        </Link>
      </Menu.Item>)
    }
  });

  const patientmenu = PatientMenu.menu.map((menu, index) => {
    if (menu.submenu?.length > 0) {
      return (<SubMenu
        key={menu.slug + menu.id}
        title={<span><i className="icon sidebar-icon"><img src={menu.icon} alt={menu.name} /></i><span>{menu.name}</span> </span>}
        popupClassName={getNavStyleSubMenuClass(navStyle)}
      >
        {menu.submenu &&
          menu.submenu.map((submenu, i) => (
            <Menu.Item key={submenu.slug + submenu.id}>
              <i className="icon icon-crypto" />
              <Link to={submenu.url}>{submenu.name}</Link>
            </Menu.Item>
          ))}
      </SubMenu>)
    }
    else {
      return (<Menu.Item key={menu.slug + menu.id}>
        <Link to={menu.url}>
          <i className="icon sidebar-icon"><img src={menu.icon} alt={menu.name} /></i>
          <span>{menu.name}</span>
        </Link>
      </Menu.Item>)
    }
  });


  return (
    <>
      <SidebarLogo sidebarCollapsed={sidebarCollapsed} setSidebarCollapsed={setSidebarCollapsed} />
      <div className="gx-sidebar-content">
        {/*<div className={`gx-sidebar-notifications ${getNoHeaderClass(navStyle)}`}>*/}
        {/*  <UserProfile />*/}
        {/*  /!* <AppsNavigation/> *!/*/}
        {/*</div>*/}
        <CustomScrollbars className="gx-layout-sider-scrollbar">
          <Menu
            defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={[selectedKeys]}
            theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
            mode="inline">

            {authUser.area === 1 && adminmenu}
            {authUser.area === 5 && doctormenu}
            {authUser.area === 7 && patientmenu}

            {/* <Menu.Item key="sample">
              <Link to="/sample"><i className="icon icon-widgets" />
                <span>Dashboard</span>
              </Link>
            </Menu.Item> */}

            {/* <SubMenu key="dashboard" popupClassName={getNavStyleSubMenuClass(navStyle)}
              title={<span> <i className="icon icon-dasbhoard" />
                <span><IntlMessages id="sidebar.dashboard" /></span></span>}>
              <Menu.Item key="main/dashboard/crypto">
                <Link to="/main/dashboard/crypto">
                  <i className="icon icon-crypto" />
                  <span><IntlMessages id="sidebar.dashboard.crypto" /></span>
                </Link>
              </Menu.Item>
            </SubMenu> */}
            {/*    <Menu.Item key="main/dashboard/crm">*/}
            {/*      <Link to="/main/dashboard/crm">*/}
            {/*        <i className="icon icon-crm"/>*/}
            {/*        <span><IntlMessages id="sidebar.dashboard.crm"/></span>*/}
            {/*      </Link>*/}
            {/*    </Menu.Item>*/}
            {/*    <Menu.Item key="main/dashboard/listing">*/}
            {/*      <Link to="/main/dashboard/listing">*/}
            {/*        <i className="icon icon-listing-dbrd"/>*/}
            {/*        <span><IntlMessages id="sidebar.dashboard.listing"/></span>*/}
            {/*      </Link>*/}
            {/*    </Menu.Item>*/}
            {/*  </SubMenu>*/}

          </Menu>
        </CustomScrollbars>
      </div>
    </>
  );
};

export default React.memo(SidebarContent);

