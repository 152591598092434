import React, { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch, useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { ConfigProvider } from 'antd';
import { IntlProvider } from "react-intl";

import AppLocale from "../../lngProvider";
import MainApp from "./MainApp";
import SignIn from "../SignIn";
import SignUp from "../SignUp";
// import SignUpOtp from "../signupotp";
import SignInRemote from "../SignInRemote";
import { setInitUrl } from "../../appRedux/actions/Auth";

import {
  LAYOUT_TYPE_BOXED,
  LAYOUT_TYPE_FRAMED,
  LAYOUT_TYPE_FULL,
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL, THEME_TYPE_DARK
} from "../../constants/ThemeSetting";
import { getUser, onLayoutTypeChange, onNavStyleChange, setThemeType } from "../../appRedux/actions";
import CircularProgress from "../../components/CircularProgress";
import SignUpOtp from "../SignUpOtp";
import ForgotPassword from "../ForgotPassword";
import ResetPassword from "../ResetPassword";

const RestrictedRoute = ({ component: Component, location, authUser, ...rest }) =>
  <Route
    {...rest}
    render={props =>
      authUser
        ? <Component {...props} />
        : <Redirect
          to={{
            pathname: '/signin',
            state: { from: location }
          }}
        />}
  />;

const setLayoutType = (layoutType) => {
  if (layoutType === LAYOUT_TYPE_FULL) {
    document.body.classList.remove('boxed-layout');
    document.body.classList.remove('framed-layout');
    document.body.classList.add('full-layout');
  } else if (layoutType === LAYOUT_TYPE_BOXED) {
    document.body.classList.remove('full-layout');
    document.body.classList.remove('framed-layout');
    document.body.classList.add('boxed-layout');
  } else if (layoutType === LAYOUT_TYPE_FRAMED) {
    document.body.classList.remove('boxed-layout');
    document.body.classList.remove('full-layout');
    document.body.classList.add('framed-layout');
  }
};

const setNavStyle = (navStyle) => {
  if (navStyle === NAV_STYLE_DEFAULT_HORIZONTAL ||
    navStyle === NAV_STYLE_DARK_HORIZONTAL ||
    navStyle === NAV_STYLE_INSIDE_HEADER_HORIZONTAL ||
    navStyle === NAV_STYLE_ABOVE_HEADER ||
    navStyle === NAV_STYLE_BELOW_HEADER) {
    document.body.classList.add('full-scroll');
    document.body.classList.add('horizontal-layout');
  } else {
    document.body.classList.remove('full-scroll');
    document.body.classList.remove('horizontal-layout');
  }
};

const App = () => {
  // const locale = useSelector(({settings}) => settings.locale);
  // const navStyle = useSelector(({settings}) => settings.navStyle);
  // const layoutType = useSelector(({settings}) => settings.layoutType);
  // const themeType = useSelector(({settings}) => settings.themeType);
  // const isDirectionRTL = useSelector(({settings}) => settings.isDirectionRTL);

  const dispatch = useDispatch();
  const { locale, navStyle, layoutType } = useSelector(({ settings }) => settings);
  const { token, initURL, loadingAuthUser, authUser } = useSelector(({ auth }) => auth);

  console.log(navStyle)

  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();

  // const {authUser, initURL} = useSelector(({auth}) => auth);

  // const location = useLocation();
  // const history = useHistory();
  // const match = useRouteMatch();

  useEffect(() => {
    dispatch(getUser(token));
  }, [dispatch]);

  useEffect(() => {
    if (initURL === '') {
      dispatch(setInitUrl(location.pathname));
    }
    const params = new URLSearchParams(location.search);

    if (params.has("theme")) {
      dispatch(setThemeType(params.get('theme')));
    }
    if (params.has("nav-style")) {
      dispatch(onNavStyleChange(params.get('nav-style')));
    }
    if (params.has("layout-type")) {
      dispatch(onLayoutTypeChange(params.get('layout-type')));
    }
    setLayoutType(layoutType);
    setNavStyle(navStyle);
  }, [dispatch, initURL, layoutType, location.pathname, location.search, navStyle]);

  useEffect(() => {
    if (location.pathname === '/') {
      const counter = localStorage.getItem("otpstatus");
      if (counter === 1) {
        history.push('/otp');
      }
      else {
        if (authUser === null) {
          history.push('/signin');
        }
        else if (initURL === '' || initURL === '/' || initURL === '/signin') {
          // history.push('/main/dashboard/crm');
          // console.log(authUser)
          if (authUser.area === 1) {
            history.push('/admin/dashboard');
          } else if (authUser.area === 7) {
            history.push('/patient/dashboard');
          } else if (authUser.area === 5) {
            history.push('/doctor/dashboard');
          }
        }
        else {
          // console.log(initURL);
          // history.push(initURL);

          if (authUser.area === 1) {
            history.push('/admin/dashboard');
          } else if (authUser.area === 7) {
            history.push('/patient/dashboard');
          } else if (authUser.area === 5) {
            history.push('/doctor/dashboard');
          }
        }
      }
    }
  }, [authUser, initURL, location, history]);


  // useEffect(() => {
  //   if (isDirectionRTL) {
  //     document.documentElement.classList.add('rtl');
  //     document.documentElement.setAttribute('data-direction', 'rtl')
  //   } else {
  //     document.documentElement.classList.remove('rtl');
  //     document.documentElement.setAttribute('data-direction', 'ltr')
  //   }
  // }, [isDirectionRTL]);

  // useEffect(() => {
  //   if (locale)
  //     document.documentElement.lang = locale.locale;
  // }, [locale]);

  // useEffect(() => {
  //   if (themeType === THEME_TYPE_DARK) {
  //     document.body.classList.add('dark-theme');
  //   } else if (document.body.classList.contains('dark-theme')) {
  //     document.body.classList.remove('dark-theme');
  //   }
  // }, [themeType]);

  // useEffect(() => {
  //   if (initURL === '') {
  //     dispatch(setInitUrl(location.pathname));
  //   }
  // });

  // useEffect(() => {
  //   if (location.pathname === '/') {
  //     if (authUser === null) {
  //       history.push('/signin');
  //     } else if (initURL === '' || initURL === '/' || initURL === '/signin') {
  //       history.push('/sample');
  //     } else {
  //       history.push(initURL);
  //     }
  //   }
  // }, [authUser, initURL, location, history]);

  // useEffect(() => {
  //   setLayoutType(layoutType);
  //   setNavStyle(navStyle);
  // }, [layoutType, navStyle]);

  const currentAppLocale = AppLocale[locale.locale];

  return loadingAuthUser ? <CircularProgress /> : (
    // <ConfigProvider locale={currentAppLocale.antd} direction={isDirectionRTL ? 'rtl' : 'ltr'}>
    <ConfigProvider locale={currentAppLocale.antd}>
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}>
        <Switch>
          <Route exact path='/signin' component={SignIn} />
          <Route exact path='/forgotpassword' component={ForgotPassword} />
          <Route exact path='/resetpassword' component={ResetPassword} />
          <Route exact path='/signup' component={SignUp} />
          <Route exact path='/signupotp' component={SignUpOtp} />
          <Route exact path='/signinremote' component={SignInRemote} />
          <RestrictedRoute path={`${match.url}`} authUser={authUser} location={location} component={MainApp} />
        </Switch>
      </IntlProvider>
    </ConfigProvider>
  )
};

export default memo(App);
