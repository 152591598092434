import React, {useState,} from "react";
import {Card, Collapse, Form, Tooltip,} from "antd";
import {Link, useHistory,} from "react-router-dom";
import {CisUI} from "../../../util/CISUI";
import ApiUrl from "../../../APIURL/Admin/ApiUrl";
import SmartDataTable from 'react-smart-data-table';
import IntlMessages from "../../../util/IntlMessages";
import ActionMenu from "../../../util/ActionMenu";
import AdvancedSearch from "./AdvancedSearch";
import moment from "moment";
import Config from "../../../util/config";
import {FETCH_ERROR, FETCH_START, FETCH_SUCCESS, GET_ALL_DOCTOR} from "../../../constants/ActionTypes";
import axios from "axios";

const {Panel} = Collapse;

const CardList = () => {
	const [state, setState] = useState({
		reqURL: ApiUrl.card_list
	});
	const history = useHistory();
	const [form] = Form.useForm();

	const headers = {
		_id: {
			text: 'SL No',
			invisible: true,
			filterable: false,
			transform: (value) => `Row #${value + 1}`,
		},
		id: {
			text: <IntlMessages id="Sl NO"/>,
			sortable: true,
			filterable: true,
			transform: (value, id, row) => (<p style={{textAlign: "center"}}>{id + 1}</p>)

		},
		patient_id: {
			text: <IntlMessages id="Patient ID"/>,
			sortable: true,
			filterable: true,
			invisible: true
		},
		card_no: {
			text: <IntlMessages id="Card No"/>,
			sortable: true,
			filterable: true,
		},
		cvv: {
			text: <IntlMessages id="Cvv"/>,
			sortable: true,
			filterable: true,
		},
		card_name: {
			text: <IntlMessages id="Card Name"/>,
			sortable: true,
			filterable: true,
		},
		phone: {
			text: <IntlMessages id="Phone"/>,
			sortable: true,
			filterable: true,
		},
		validate_from: {
			text: <IntlMessages id="Validate From"/>,
			sortable: true,
			filterable: true,
			transform: (value, id, row) => moment(row.validate_from).format('DD-MM-YYYY')
		},
		expired_date: {
			text: <IntlMessages id="Expired Date"/>,
			sortable: true,
			filterable: true,
			transform: (value, id, row) => moment(row.expired_date).format('DD-MM-YYYY')
		},
		address: {
			text: <IntlMessages id="Address"/>,
			sortable: true,
			filterable: true,
			invisible: true
		}
	};

	const orderedHeaders = [];

	const onFinish = values => {
		// console.log('Received values of form: ', values);
		// let data = {
		// 	...values,
		// 	date: values['date'].format('YYYY-MM-DD')
		// }

		let data = '';
		if (values['date'] === null || values['date'] === undefined) {
			data = {
				...values,
			}
		} else {
			data = {
				...values,
				expired_date: values['expired_date'].format('YYYY-MM-DD')
			}
		}

		const qs = Object.keys(data).map(key => `${key}=${data[key]}`).join('&');
		const qsup = qs.replaceAll("undefined", "");
		//reqURL = reqURL+"/?"+qsup;
		history.push("?" + qsup);
		const newURL = ApiUrl.card_list + "/?" + qsup;
		setState({reqURL: newURL});
	};

	const searchView = (
		<Card className="advance-search-card">
			<Collapse expandIconPosition="right" defaultActiveKey={['1']}>
				<Panel header="Advanced Search" key="1">
					<Form
						form={form}
						name="advanced_search"
						className="ant-advanced-search-form"
						onFinish={onFinish}
					>
						{AdvancedSearch}
					</Form>
				</Panel>
			</Collapse>
		</Card>
	);


	const downloadExcel = (

		<a href={Config.apiserver + 'cardlists?excel=1&token=' + localStorage.getItem('token')}
		   target={"_blank"}><Tooltip placement="bottom" color={"#3a6472"} title="Export to Excel"><img
			className={"excel-icon"} src={"/assets/images/icons/excel.png"}/></Tooltip></a>

	);
	return (
		<>
			{searchView}
			<Card className="tickets-card" title="Card List" extra={downloadExcel}>
				{/*<Link to={redirectto} className="add_icon"><i className="icon icon-add" /></Link>*/}
				<SmartDataTable
					name='data_table'
					data={state.reqURL}
					dataRequestOptions={CisUI().HeaderRequest}
					dataKey="data"
					headers={headers}
					orderedHeaders={orderedHeaders}
					hideUnordered={CisUI().hideUnordered}
					className={CisUI().sematicUI.table}
					// filterValue={state.filterValue}
					perPage={CisUI().perPage}
					sortable
					withLinks
					withHeader
					loader={CisUI().loader}
					// parseBool={{
					// 	yesWord: 'Yes',
					// 	noWord: 'No',
					// }}
					// parseImg={{
					// 	style: {
					// 		border: '1px solid #ddd',
					// 		borderRadius: '50px',
					// 		padding: '3px',
					// 		width: '30px',
					// 		height: '30px'
					// 	},
					// }}
					emptyTable={CisUI().emptyTable}
				/>
			</Card>
		</>
	);
};

export default CardList;
