import React from "react";
import { Card, } from "antd";
// import { Link, } from "react-router-dom";
import { CisUI } from "../../../util/CISUI";
import SmartDataTable from 'react-smart-data-table';
import IntlMessages from "../../../util/IntlMessages";
import ActionMenu from "../../../util/ActionMenu";
import ApiUrl from "../../../APIURL/Doctor/ApiUrl";


const PaymentHistory = () => {
    // const [state, setState] = useState({
    // 	reqURL: ApiUrl.patient_list
    // });

    const headers = {
        id: {
            text: <IntlMessages id="Sl NO" />,
            sortable: true,
            filterable: true,
            transform: (value, id, row) => (<p style={{ textAlign: "center" }}>{id + 1}</p>)

        },
        image: {
            invisible: true,
        },
        patient_name: {
            text: <IntlMessages id="Full Name" />,
            sortable: true,
            filterable: true,
            transform: (value, idx, row) =>
                <>
                    <img src={row.image != null ? row.image : "https://via.placeholder.com/150"} alt="" style={{ width: "40px", height: "40px", borderRadius: "50%", marginRight: "8px" }} />
                    {row.patient_name}
                </>
        },

        patient_phone: {
            text: <IntlMessages id="Phone" />,
            sortable: true,
            filterable: true,
        },

        unqid: {
            text: <IntlMessages id="Appointment ID" />,
            sortable: true,
            filterable: true,
        },

        patient_dob: {
            text: <IntlMessages id="Birth of Date" />,
            sortable: true,
            filterable: true,
        },

        status: {
            text: <IntlMessages id="Status" />,
            sortable: true,
            filterable: true,
            transform: (value, idx, row) => (
                CisUI().getStatusLabel(value)
            )
        },
        actions: {
            text: <IntlMessages id="Actions" />,
            sortable: false,
            filterable: false,
            invisible: true,
            transform: (value, idx, row) => (
                <>
                    {ActionMenu('/admin/patient/editpatient/', ApiUrl.delete_patient, row.id)}
                </>
            ),
        },
    };

    const orderedHeaders = ["id", "image", "patient_name", "patient_phone", "unqid", "patient_dob", "type_name", "date", "status"];

    // let redirectto = '/admin/patient/addpatient';

    return (
        <Card className="tickets-card" title="Upload Prescription">
            <SmartDataTable
                name='data_table'
                data={ApiUrl.appointment_list}
                dataRequestOptions={CisUI().HeaderRequest}
                dataKey="data"
                headers={headers}
                orderedHeaders={orderedHeaders}
                hideUnordered={CisUI().hideUnordered}
                className={CisUI().sematicUI.table}
                perPage={CisUI().perPage}
                sortable
                withLinks
                withHeader
                loader={CisUI().loader}
                emptyTable={CisUI().emptyTable}
            />
        </Card>
    );
};

export default PaymentHistory;





