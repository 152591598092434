import React, {useEffect, useState} from "react";
import {Button, Form, Input, Modal} from "antd";
import {useDispatch, useSelector} from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { UserOutlined } from "@ant-design/icons";
import Config from "../util/config";
import axios from "axios";
import { CisUI } from "../util/CISUI";
import InfoView from "../components/InfoView";
import OTPInput, {ResendOTP} from "otp-input-react";
import config from "../util/config";

const ForgotPassword = (props) => {
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [input, setInput] = useState("");
	const [OTP, setOTP] = useState("");
    const history = useHistory();
    const dispatch = useDispatch();
	const authUser = useSelector(({ auth }) => auth.authUser);
	const [phoneNumber, setPhoneNumber] = useState('');

    const onFinish = values => {
		setPhoneNumber(values['phone'])
        document.getElementById("loader").style.display = "block";
        axios.post(Config.apiserver + 'forgotpassword', values)
	        .then(res => {
            // handle success
            console.log(res);
            if (res.data.status === 1) {
                CisUI().Notification("success", res.data.msg)
                setIsModalVisible(true)
            }
            else {
            	CisUI().Notification('error', res.data.msg)
            }
            document.getElementById("loader").style.display = "none";
        }).catch(function (error) {
            // handle error
            console.log(error);
        })
    };

    useEffect(() => {
        if (authUser !== null) {
            history.push('/');
        }
    }, [authUser]);

	const handleCancel = () => {
		setIsModalVisible(false);
	};

	const renderResendBtn = (buttonProps) => {
		return (
			<button {...buttonProps} className="resend_btn">
				{buttonProps.remainingTime !== 0 ? `Please wait for ${buttonProps.remainingTime} sec` : "Resend"}
			</button>
		);
	};




	const onResendClick = () => {
		console.log("hello");

		// console.log(input);
		let data = {
			"phone": phoneNumber
		}

		document.getElementById("loader").style.display = "block";
		axios.post(`${config.apiserver}forgotpassword`, data).then(function (response) {
			// handle success
			console.log(response);
			if (response.data.status === 1) {
				CisUI().Notification("success", response.data.msg)
			}
			else{
				CisUI().Notification('error', response.data.msg)
			}
			// setModal(true)
			document.getElementById("loader").style.display = "none";
		}).catch(function (error) {
			// handle error
			console.log(error);
		})
	}



	const handleOtpSubmit = () => {
		// console.log(input);
		const data = {
			...input,
			"otp": OTP,
			"phone": phoneNumber
		}
		console.log(data)

		document.getElementById("loader").style.display = "block";
		axios.post(`${config.apiserver}verifyforgotpassotp`, data)
			.then(res => {
			// handle success
			console.log(res);

			if (res.data.status === 1) {
				CisUI().Notification("success", res.data.msg)
				history.push(`/resetpassword?token=${res.data.token}`)
			}
			else {
				CisUI().Notification("warning", res.data.msg)
			}
			document.getElementById("loader").style.display = "none";

		}).catch(function (error) {
			// handle error
			console.log(error);
		})
	}
    
    return (
        <>
	        <div className="gx-app-login-wrap login-wrap">
		        <div className="gx-app-login-container">
			        <div className="gx-app-login-main-content">
				        {CisUI().showLoading}
				        <div className="gx-app-login-content">
					        <img alt="example" src="/assets/images/logo.png" className="login-logo" />
					        <p style={{ textAlign:"center", color:"#3a6472", fontWeight:"bold"}}>Enter Your Phone Number For Verification</p>
					        <Form
						        initialValues={{ remember: true }}
						        name="basic"
						        onFinish={onFinish}
						        className="gx-signin-form gx-form-row0"
						        layout="vertical">

						        <Form.Item
							        label="Your phone number"
							        rules={[{ required: true, message: 'Phone is required!' }, { min: 11, message: 'Phone number must be 11 digits.' }, { max: 11, message: 'Phone number must be 11 digits.' }]}
							        name="phone"
						        >
							        <Input placeholder="Your phone number" prefix={<UserOutlined />} size="large" />
						        </Form.Item>

						        <Form.Item className="signin-btn">

							        <Button type="primary" className="gx-mb-0" htmlType="submit">
								        Submit
							        </Button>
						        </Form.Item>

					        </Form>
					        <p style={{ textAlign: "center" }}>Don't have an account? <Link to="/signupotp">Sign Up</Link> </p>
				        </div>
				        <InfoView />

				        <Modal visible={isModalVisible} onOk={handleOtpSubmit} onCancel={handleCancel} okText="Submit">
					        <OTPInput value={OTP} onChange={setOTP} autoFocus OTPLength={4} otpType="number" disabled={false} className="otp_input justify-content-center" />
					        <ResendOTP renderButton={renderResendBtn} className="resend_btn ant-btn ant-btn-link" onResendClick={onResendClick} />
				        </Modal>

			        </div>
		        </div>
	        </div>

        </>
    );
};

export default ForgotPassword;



