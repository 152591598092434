import React from "react";
import { Route, Switch } from "react-router-dom";
import MedicineList from '../../../containers/DoctorPortal/Medicine/MedicineList';


const Medicine = ({ match }) => (

    <Switch>
        <Route
            path={`${match.url}/`}
            component={MedicineList}
        />
    </Switch>
);

export default Medicine;