import React from "react";
import {notification, Tag} from "antd";
// import 'react-smart-data-table/dist/react-smart-data-table.css'
import moment from "moment";
import Config from "./config";


const CisUI = () => {
	const sematicUI = {
		change: 'ui labeled secondary icon button',
		changeIcon: 'exchange icon',
		checkbox: 'ui toggle checkbox',
		deleteIcon: 'trash red icon',
		input: 'ui input',
		iconInput: 'ui icon input',
		labeledInput: 'ui right labeled input',
		loader: 'ui active inverted dimmer"',
		message: 'ui message',
		refresh: 'ui labeled primary icon button',
		refreshIcon: 'sync alternate icon',
		rowsIcon: 'numbered list icon',
		searchIcon: 'search icon',
		segment: 'ui segment',
		select: 'ui dropdown',
		table: 'ui compact selectable table',
	}

	const emptyTable = (
		<div className={sematicUI.message}>
			There is no data available to display.
		</div>
	)

	const getStatusLabel = (status) => {
		let statusLB = {status};
		if (status === 1) {
			statusLB = <Tag color="#34495e" className="list-tag-name success">Active</Tag>
		} else if (status === 0) {
			statusLB = <Tag color="#750707" className="list-tag-name danger">Inactive</Tag>
		} else {
			statusLB = <Tag color="#750707" className="list-tag-name">{status}</Tag>
		}
		return statusLB;
	};

	const getAppointmentStatusText = (status) => {
		let text = '';

		if (status === 1) {
			text = 'Pending';
		} else if (status === 0) {
			text = 'Closed';
		}
		else if (status === 2) {
			text = 'Running';
		}
		else if (status === 3) {
			text = 'Confirmed';
		}
		else {
			text = 'Cancel';
		}
		return text;
	}

	const getAppointmentMsg = (status) => {
		let msg = '';

		if (status === 1) {
			msg = 'Appointment request is successfully done. To confirm please pay.';
		} else if (status === 0) {
			msg = 'Appointment is closed.';
		}
		else if (status === 2) {
			msg = 'Appointment is Running';
		}
		else if (status === 3) {
			msg = 'Appointment is Confirmed.';
		}
		else {
			msg = 'Appointment request is Cancelled.';
		}
		return msg;
	}

    const getAppointmentTypeLabel = (status) => {
        let statusLB = "";
        if (status === "New") {
            statusLB = <p style={{color:"#088100"}}>New</p>
        }
        else if (status === "Follow Up") {
            statusLB = <p style={{color:"#033005"}}>Follow Up</p>
        }
        else if (status === "Report") {
            statusLB = <p style={{color:"#34495e"}}  >Showing Report</p>
        }
        return statusLB;
    };

	const getAppointmentStatusLabel = (status) => {
		let statusLB = "";
		if (status === 1) {
			statusLB = <Tag color="#34495e" className="list-tag-name success">Unpaid</Tag>
		} else if (status === 0) {
			statusLB = <Tag color="#34495e" className="list-tag-name success">Closed</Tag>
		}
		else if (status === 2) {
			statusLB = <Tag color="#750707" className="list-tag-name danger">Running</Tag>
		}
		else if (status === 3) {
			statusLB = <Tag color="#088100" className="list-tag-name danger">Confirmed</Tag>
		}
		else {
			statusLB = <Tag color="#750707" className="list-tag-name">Cancel</Tag>
		}
		return statusLB;
	};
    const getAccountStatusLabel = (status) => {
        let statusLB = "";
        if (status === 1) {
            statusLB = <Tag color="#088100" className="list-tag-name success">Active</Tag>
        } else if (status === 0) {
            statusLB = <Tag color="#750707" className="list-tag-name success">InActive</Tag>
        }

        return statusLB;
    };
	const getOnlineLabel = (status) => {
		let statusLB = "";
		if (status === 1) {
			statusLB = <Tag color="#3a7241" className="list-tag-name">Online</Tag>
		} else {
			statusLB = <Tag color="#750707" className="list-tag-name">Offline</Tag>
		}
		return statusLB;
	};

	const getEditStatusLabel = (status) => {
		let statusLB = {status};
		if (status === 1) {
			statusLB = "Active";
		} else {
			statusLB = "Inactive"
		}
		return statusLB;
	};

	const getOrderStatus = (status) => {
		let statusLB = {status};
		if (status === 'Active') {
			statusLB = <Tag color="#34495e">{status}</Tag>
		} else if (status === 1) {
			statusLB = <Tag color="#34495e">Approved</Tag>
		} else if (status === 0) {
			statusLB = <Tag color="#750707">Pending</Tag>
		} else if (status === 3) {
			statusLB = <Tag color="#750707">Cancelled</Tag>
		} else {
			statusLB = <Tag color="#750707">{status}</Tag>
		}
		return statusLB;

	};

	const getTicketStatus = (status) => {
		let statusLB = {status};
		if (status === 0) {
			statusLB = <Tag color="#34495e">Open</Tag>
		} else if (status === 1) {
			statusLB = <Tag color="#52c41a">Closed</Tag>
		} else if (status === 2) {
			statusLB = <Tag color="#750707">Customer-Reply</Tag>
		} else if (status === 3) {
			statusLB = <Tag color="#750707">Answered</Tag>
		} else {
			statusLB = <Tag color="#750707">{status}</Tag>
		}
		return statusLB;
	};

	const getInvoiceStatus = (status) => {
		let statusLB = {status};
		if (status === 'Paid') {
			statusLB = <Tag color="#34495e">{status}</Tag>
		} else if (status === 'Unpaid') {
			statusLB = <Tag color="#750707">Unpaid</Tag>
		} else if (status === 1) {
			statusLB = <Tag color="#34495e">Paid</Tag>
		} else if (status === 0) {
			statusLB = <Tag color="#750707">Unpaid</Tag>
		} else {
			statusLB = <Tag color="#750707">{status}</Tag>
		}
		return statusLB;
	};


	const formItemLayout = {
		labelCol: {
			span: 24,
		},
		wrapperCol: {
			span: 24,
		},
	};

	const hideUnordered = false;
	const perPage = 50;

	const headers = {
		'Content-Type': 'application/json',
		'Authorization': 'superadmin:123456'
	}

	const HeaderRequest = () => {
		const token = localStorage.getItem('token');
		return {headers: {Authorization: token}};
	}

	const getLocalUserData = () => {
		const userData = localStorage.getItem('userData');
		return userData;
	}
	const getLocalUserID = () => {
		const userData = JSON.parse(localStorage.getItem('userData'));
		return userData['id'];
	}
	const getLocalUserByKey = (key) => {
		const userData = JSON.parse(localStorage.getItem('userData'));
		return userData[key];
	}
	const CurrencyFormat = (x) => {
		let number = parseFloat(x).toFixed(2);
		return "৳ " + number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	}

	const FullName = (fname, lname) => {
		return fname + " " + lname;
	}

	const DateFormat = (data) => {
		// return moment(data).format("MM/DD/YYYY");
		if (data !== null) {
			return moment(data).format("MM-DD-YYYY");
		}
	}

	const DateFormatWithTime = (data) => {
		// return moment(data).format("MM/DD/YYYY hh:mm:ss");
		if (data !== null) {
			return moment(data).format("MM-DD-YYYY hh:mm:ss");
		}
	}

	// const TimeFormat = (data) => {
	//   // return moment(data).format("MM/DD/YYYY hh:mm:ss");
	//   if(data !== null) {
	//     return moment(data);
	//   }
	// }

	const numberFormat = (value) =>
		new Intl.NumberFormat("en-US", {
			style: 'currency',
			currency: 'BDT'
		}).format(value);

	const getCurrencyFormated = (amount) => {
		return <div style={{textAlign: 'right', marginRight: "30px"}}>{numberFormat(amount || 0)}</div>
	}

	const getCurrencyFormated1 = (amount) => {
		if (amount >= 0) {
			return numberFormat(amount || 0);
		} else {
			const amt = numberFormat(amount || 0);
			return "(" + amt.replace("-", "") + ")";
		}
	}

	const getNumber = (amount) => {
		return new Intl.NumberFormat('en-us').format(amount);
	}

	const getQueryStrings = (values) => {
		const qs = Object.keys(values).map(key => {
				if (values[key] === undefined || values[key] === "" || values[key] === null) {
					return `${key}=${""}`
				} else {
					console.log(key + ":" + values[key]);
					if (key === 'date') {
						return `${key}=${CisUI().ParseDateFormat(values[key])}`
					} else {
						return `${key}=${values[key]}`
					}
				}
			}
		).join('&');

		return qs.replaceAll("undefined", "");
	}

	const getBillSatus = (statuscode) => {
		let sts = <Tag color="#34495e">Pending</Tag>
		if (statuscode === 0) {
			sts = <Tag color="#34495e">Pending</Tag>
		} else if (statuscode === 1) {
			sts = <Tag color="#34495e">Approved</Tag>
		} else if (statuscode === 3) {
			sts = <Tag color="#34495e">Reject</Tag>
		}
		return sts;
	}

	const Notification = (type, message) => {
		switch (type) {

			case 'success':
				return notification.success({
					type: type,
					message: 'Success',
					description: message
				});

			case 'error' :
				return notification.error({
					type: type,
					message: 'Error',
					description: message
				});

			case 'warning' :
				return notification.warning({
					type: type,
					message: 'Warning',
					description: message
				});

			case 'info' :
				return notification.info({
					type: type,
					message: 'Info',
					description: message
				});

			default:
				return false
		}
	}

	const loader = (
		<div className="loader-table loading loader">
			<img src={Config.loader} />
		</div>
	)

	const showLoading = (
		<div id="loader" className="loading_view loading loader" style={{display: 'none'}}>
			<div className="loading_center2">
				<div className="loader-table" style={{textAlign: 'center'}}>
					<img style={{background : 'transparent'}} src={Config.loader} />
					{/*<h4 style={{textAlign: 'center', color: 'white', marginTop: '-80px'}}>Please wait...</h4>*/}
					<h4 style={{textAlign: 'center', color: 'white', marginTop: '10px'}}>Please wait...</h4>
				</div>
			</div>
		</div>
	);

	const medicineTypeName = (type) => {
		let name = "";
		switch (type) {
			case 1:
				name = "Capsule";
				break
			case 2:
				name = "Tablet";
				break
			case 3:
				name = "Syrup";
				break
			case 4:
				name = "Injection";
				break
			case 5:
				name = "Saline";
				break
			case 6:
				name = "Spray";
				break
			case 7:
				name = "Nasal Spray";
				break
			case 8:
				name = "Inhelear";
				break
		}

		return name;
	}

	return {
		sematicUI: sematicUI,
		emptyTable: emptyTable,
		hideUnordered: hideUnordered,
		perPage: perPage,
		formItemLayout: formItemLayout,
		headers: headers,
		getStatusLabel: getStatusLabel,
		getBillSatus: getBillSatus,
		getOrderStatus: getOrderStatus,
		HeaderRequest: HeaderRequest(),
		CurrencyFormat: CurrencyFormat,
		FullName: FullName,
		DateFormat: DateFormat,
		DateFormatWithTime: DateFormatWithTime,
		getCurrency: getCurrencyFormated,
		getCurrencyFormated1: getCurrencyFormated1,
		getNumber: getNumber,
		getQueryStrings: getQueryStrings,
		getInvoiceStatus: getInvoiceStatus,
		getTicketStatus: getTicketStatus,
		getLocalUserData: getLocalUserData,
		Notification,
		getEditStatusLabel,
		loader,
		showLoading: showLoading,
		getOnlineLabel: getOnlineLabel,
		getAppointmentStatusLabel,
        getAppointmentTypeLabel,
		getAppointmentStatusText,
		getAppointmentMsg,
		getLocalUserID,
		getLocalUserByKey,
		medicineTypeName,
        getAccountStatusLabel,
	}
}

export {CisUI}
