import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import Settings from "./Settings";
import Auth from "./Auth";
import Notes from "./Notes";
import Common from "./Common";
import Country from "./Country";
import Employee from "./Employee";
import Service from "./Service";
import Customer from "./Customer";
import Department from "./Department";
import Agent from "./Agent";
import Patient from "./Patient";
import Doctor from "./Doctor";
import Medicine from "./Medicine";


const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  settings: Settings,
  auth: Auth,
  notes: Notes,
  common: Common,
  country: Country,
  district: Country,
  department: Department,
  customer: Customer,
  employee: Employee,
  service: Service,
  agent: Agent,
  doctor: Doctor,
  medicine: Medicine,
  patient: Patient
});

export default createRootReducer
