import React, {useEffect, useState} from 'react';
import {Button, Card, Col, Collapse, Divider, Form, Input, Row, Select} from "antd";
import IntlMessages from "../../../util/IntlMessages";
import axios from "axios";
import ApiUrl from '../../../APIURL/Doctor/ApiUrl';
import {CisUI} from "../../../util/CISUI";
import {Link, useHistory} from "react-router-dom";

const {Option} = Select;
const {Panel} = Collapse;
const {TextArea} = Input;

const EditAccountInfo = (props) => {
	const id = props.match.params.id;
	const history = useHistory();
	const [pMethod, setPMethod] = useState("")
	const [editData, setEditData] = useState([]);
	const handleSelect = (e) => {
		console.log(e);
		setPMethod(e)
	}
	let redirectto = '/doctor/account/accountlist'
	const getAccountData = (id) => {
		axios.get(ApiUrl.show_account+'/'+id, CisUI().HeaderRequest)
			.then(res => {
				// console.log(res.data.data)
				setEditData(res.data.data)
				setPMethod(res.data.data.account_type)
			})
	}
	
	useEffect(() => {
		getAccountData(id)
	}, []);
	
	const onFinish = (values) => {
		let data = {
			...values,
			doctor_id: CisUI().getLocalUserByKey('doctor_id')
		};
		// console.log(data);
		document.getElementById("loader").style.display = "block";
		axios.post(ApiUrl.update_account+'/'+id, data, CisUI().HeaderRequest)
			.then(res => {
				if(res.data.status === 1) {
					CisUI().Notification('success', res.data.msg)
					history.push(redirectto)
				}
				else {
					CisUI().Notification('error', res.data.msg)
				}
				document.getElementById("loader").style.display = "none";
			})
			.catch(error => {
				console.log(error.response.data)
			})
	};
	
	return (
		<>
			<Card className="tickets-card" title={<IntlMessages id="Edit Account Info"/>} extra={
				<Link to={redirectto} className="list_icon"><i className="icon icon-lising-dbrd" /></Link>
			}>
				
				{CisUI().showLoading}
				{
					editData != '' && (
						<Form
							// form={form}
							name="advanced_search"
							className="ant-advanced-search-form"
							onFinish={onFinish}
							initialValues={{
								account_type: editData.account_type.toString(),
								bank_name: editData.bank_name,
								account_name: editData.account_name,
								account_no: editData.account_no,
								branch_name: editData.branch_name,
								routing_no: editData.routing_no,
								mb_operator: editData.mb_operator,
								mb_account_name: editData.mb_account_name,
								mobile_no: editData.mobile_no
							}}
						>
							<Row
								className="ant-row"
								style={{rowGap: "15px", display: "flex", alignItems: "flex-end"}}
								// justify="space-between"
							>
								
								<Col lg={6} md={6} sm={12} xs={24}>
									<Form.Item
										label="Account Type"
										name="account_type"
										className="form-lblock"
										rules={[
											{ required: true, message: "Account Type is required" },
										]}
									>
										<Select
											onSelect={handleSelect}
											placeholder="Select An Option"
											style={{width: "100%"}}
										>
											<Option value="1">Bank Account</Option>
											<Option value="2">Mobile Banking</Option>
										</Select>
									</Form.Item>
								</Col>
								{
									pMethod == "2" && (
										<Col lg={24}>
											<Divider orientation="left" plain>
												Mobile Banking Information
											</Divider>
											<Row>
												<Col lg={8} md={8} sm={12} xs={24}>
													<Form.Item
														label="Service Name"
														name="mb_operator"
														className="form-lblock"
														rules={[
															{ required: true, message: "Service is required" },
														]}
													>
														<Select
															showSearch
															
															placeholder="Select An Option"
															style={{width: "100%"}}
														
														>
															<Option value="bkash">Bkash</Option>
															<Option value="nagad">Nagad</Option>
															<Option value="Rocket">Rocket</Option>
															<Option value="m_cash">M-cash</Option>
															<Option value="upay">Upay</Option>
															<Option value="my_cash">My Cash</Option>
														</Select>
													</Form.Item>
												</Col>
												
												
												<Col lg={8} md={8} sm={12} xs={24}>
													<Form.Item
														label="Mobile No."
														name="mobile_no"
														className="form-lblock"
														rules={[
															{ required: true, message: "Mobile No is required" },
														]}
													>
														<Input placeholder="Mobile No."/>
													</Form.Item>
												</Col>
												
												<Col lg={8} md={8} sm={12} xs={24}>
													<Form.Item
														label="Account Holder Name"
														name="mb_account_name"
														className="form-lblock"
														rules={[
															{ required: true, message: "Account Holder Name is required" },
														]}
													>
														<Input placeholder="Account Holder Name"/>
													</Form.Item>
												</Col>
											
											
											</Row>
										</Col>
									)}
								
								{
									pMethod == "1" && (
										<Col lg={24}>
											<Divider orientation="left" plain>
												Bank Information
											</Divider>
											<Row>
												<Col lg={8} md={8} sm={12} xs={24}>
													<Form.Item
														label="Bank Name"
														name="bank_name"
														className="form-lblock"
														rules={[
															{ required: true, message: "Bank Name is required" },
														]}
													>
														<Input placeholder="Bank Name"/>
													</Form.Item>
												</Col>
												
												<Col lg={8} md={8} sm={12} xs={24}>
													<Form.Item
														label="Branch Name"
														name="branch_name"
														className="form-lblock"
														rules={[
															{ required: true, message: "Branch Name is required" },
														]}
													>
														<Input placeholder="Branch Name"/>
													</Form.Item>
												</Col>
												
												<Col lg={8} md={8} sm={12} xs={24}>
													<Form.Item
														label="Account No."
														name="account_no"
														className="form-lblock"
														rules={[
															{ required: true, message: "Account No is required" },
														]}
													>
														<Input placeholder="Account No."/>
													</Form.Item>
												</Col>
												
												<Col lg={8} md={8} sm={12} xs={24}>
													<Form.Item
														label="Account Name"
														name="account_name"
														className="form-lblock"
														rules={[
															{ required: true, message: "Account Name is required" },
														]}
													>
														<Input placeholder="Account Name"/>
													</Form.Item>
												</Col>
												
												<Col lg={8} md={8} sm={12} xs={24}>
													<Form.Item
														label="Routing Number (Optional)"
														name="routing_no"
														className="form-lblock"
													>
														<Input placeholder="Routing Number"/>
													</Form.Item>
												</Col>
											</Row>
										</Col>
									
									)}
								{
									pMethod != '' &&
									<Col lg={24} xs={24} style={{textAlign: "center"}}>
										<Button type="secondary" htmlType="back">
											Back
										</Button>
										<Button type="primary" htmlType="submit">
											Save
										</Button>
									</Col>
								}
							</Row>
						</Form>
					)
				}
			</Card>
		
		
		</>
	)
}

export default EditAccountInfo;
