import React, {useEffect, useState} from "react";
import {Form, Card, Col, Row, Button, Checkbox, Select, Divider, Input, DatePicker, message, Upload} from 'antd';
import CKEditor from "react-ckeditor-component";
import 'react-smart-data-table/dist/react-smart-data-table.css'
import {Link, useHistory} from "react-router-dom";
import {EyeInvisibleOutlined, EyeTwoTone} from "@ant-design/icons";
import {useDispatch, useSelector} from "react-redux";
import {getCountry, getDistrict} from "../../../../appRedux/actions/Country";
import {getDepartment} from "../../../../appRedux/actions/Department";
import IntlMessages from "../../../../util/IntlMessages";
import { UploadOutlined } from '@ant-design/icons';
import ApiUrl, {add_doctor} from "../../../../APIURL/Admin/ApiUrl";
import axios from "axios";
import {CisUI} from "../../../../util/CISUI";
import FileUploads from "../../../../util/FileUploads";

const { Option } = Select;

const AddGeneralInfo = (props) => {
	const [form] = Form.useForm();
	let redirectto = '/admin/doctor/doctorlist';
	
	const history = useHistory();
	const dispatch = useDispatch();
	const [image, setImage] = useState('');
	const [source, setSource] = useState('');
	
	let countries = useSelector(({ country }) => country.countries);
	let districts = useSelector(({ district }) => district.districts);
	let departments = useSelector(({ department }) => department.departments);
	
	useEffect(() => {
		dispatch(getCountry());
		dispatch(getDistrict());
		dispatch(getDepartment());
	}, []);
	
	const changeImage = (e) => {
		let file = e.target.files[0];
		
		let reader = new FileReader();
		
		if(e.target.files.length !== 0) {
			setSource(URL.createObjectURL(file));
		}
		else {
			setSource('')
		}
		
		reader.onload = (e) => {
			setImage({
				image: e.target.result,
			});
		};
		reader.readAsDataURL(file);
	};

	
	const [intro, setIntro] = useState("");
	
	const handleBioChange = (evt) => {
		var newContent = evt.editor.getData();
		setIntro(newContent)
	}
	
	
	const onFinish = (values) => {
		// console.log(values);
		let data = {
			...values,
			'image': image['image'],
			'intro' : intro,
			'dob': values['dob'].format('YYYY-MM-DD')
		}
		console.log(data);
		document.getElementById("loader").style.display = "block";
		axios.post(ApiUrl.add_general_info, data, CisUI().HeaderRequest)
			.then(res => {
				console.log(res.data)
				if(res.data.status === 1) {
					CisUI().Notification('success', res.data.msg);
					props.dID(res.data.doctor_id)
					document.getElementById("loader").style.display = "none";
				}
				else {
					CisUI().Notification('error', res.data.msg);
				}
				document.getElementById("loader").style.display = "none";
			})
			.catch(errors => {
				console.log(errors);
			});
	};
	
	return (
		<Card title={<IntlMessages id="Add General Info"/>}>
			{CisUI().showLoading}
			<Form onFinish={onFinish}>
				<Row
					className="ant-row"
					style={{ rowGap: "15px" }}
					// justify="space-between"
				>
					<Col lg={8} md={8} sm={12} xs={24}>
						<Form.Item
							label="Department"
							name="department_id"
							className="form-lblock"
							rules={[
								{ required: true, message: "Department is required" },
							]}
						>
							<Select
								showSearch
								// optionFilterProp="children"
								placeholder="Select Your Department"
								style={{ width: "100%" }}
								filterOption={(input, option) =>
									option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
								}
								// name="country"
							>
								{
									departments.map((department, index) => (
										<Option key={++index} value={department.id}>
											{department.name}
										</Option>
									))
								}
							</Select>
						</Form.Item>
					</Col>
					
					<Col lg={8} md={8} sm={12} xs={24}>
						<Form.Item
							label="Full Name"
							rules={[
								{ required: true, message: "Full Name is required" },
							]}
							name="full_name"
							className="form-lblock"
						>
							<Input placeholder="Full Name" />
						</Form.Item>
					</Col>
					
					
					<Col lg={8} md={8} sm={12} xs={24}>
						<Form.Item
							label="Phone"
							rules={[
								{ required: true, message: "Phone is required" },
							]}
							name="phone"
							className="form-lblock"
						>
							<Input placeholder="Phone" />
						</Form.Item>
					</Col>
					
					<Col lg={8} md={8} sm={12} xs={24}>
						<Form.Item
							label="Email"
							name="email"
							className="form-lblock"
						>
							<Input placeholder="Email" />
						</Form.Item>
					</Col>
					
					{/*<Col lg={8} md={8} sm={12} xs={24}>*/}
					{/*	<Form.Item*/}
					{/*		label="Website"*/}
					{/*		name="website"*/}
					{/*		className="form-lblock"*/}
					{/*	>*/}
					{/*		<Input placeholder="Email" />*/}
					{/*	</Form.Item>*/}
					{/*</Col>*/}
					
					<Col lg={8} md={8} sm={12} xs={24}>
						<Form.Item
							label="Password"
							rules={[
								{ required: true, message: "Password is required" },
							]}
							name="password"
							className="form-lblock"
						>
							<Input.Password placeholder="Password" iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} />
						</Form.Item>
					</Col>
					
					<Col lg={8} md={8} sm={12} xs={24}>
						<Form.Item
							label="Nationality"
							rules={[
								{ required: true, message: "Nationality is required" },
							]}
							name="nationality"
							className="form-lblock"
							initialValue="Bangladeshi"
						>
							<Input placeholder="Nationality" />
						</Form.Item>
					</Col>
					
					{/* <Col lg={8} md={8} sm={12} xs={24}>
						<Form.Item
							label="NID"
							rules={[
								{ required: true, message: "NID is required" },
							]}
							name="nid"
							className="form-lblock"
						>
							<Input placeholder="NID" />
						</Form.Item>
					</Col> */}
					
					<Col lg={8} md={8} sm={12} xs={24}>
						<Form.Item
							label="License No"
							rules={[
								{ required: true, message: "License No is required" },
							]}
							name="licence_no"
							className="form-lblock"
						>
							<Input placeholder="License No" />
						</Form.Item>
					</Col>
					
					<Col lg={8} md={8} sm={12} xs={24}>
						<Form.Item
							label="Degree"
							rules={[
								{ required: true, message: "Degree is required" },
							]}
							name="degree"
							className="form-lblock"
						>
							<Input placeholder="Degree" />
						</Form.Item>
					</Col>
					
					<Col lg={8} md={8} sm={12} xs={24}>
						<Form.Item
							label="Specialist"
							rules={[
								{ required: true, message: "Specialist is required" },
							]}
							name="specialist"
							className="form-lblock"
						>
							<Input placeholder="Specialist" />
						</Form.Item>
					</Col>
					
					<Col lg={8} md={8} sm={12} xs={24}>
						<Form.Item
							label="Date Of Birth"
							rules={[
								{ required: true, message: "Date Of Birth is required" },
							]}
							name="dob"
							className="form-lblock"
						>
							<DatePicker className="gx-w-100"/>
						</Form.Item>
					</Col>
					
					<Col lg={8} md={8} sm={12} xs={24}>
						<Form.Item
							label="Gender"
							rules={[
								{
									required: true,
									message: "Gender is required",
								},
							]}
							name="gender"
							className="form-lblock"
						>
							<Select
								placeholder="Select An Option"
								style={{ width: "100%" }}
								name="status"
							>
								<Option value="Male">Male</Option>
								<Option value="Female">Female</Option>
								<Option value="Others">Others</Option>
							</Select>
						</Form.Item>
					</Col>
					
					<Col lg={8} md={8} sm={12} xs={24}>
						<Form.Item
							label="City"
							name="city"
							className="form-lblock"
						>
							<Input placeholder="City" />
						</Form.Item>
					</Col>
					
					<Col lg={8} md={8} sm={12} xs={24}>
						<Form.Item
							label="District"
							name="district"
							className="form-lblock"
							rules={[
								{ required: true, message: "District is required" },
							]}
						>
							<Select
								showSearch
								// optionFilterProp="children"
								placeholder="Select Your District"
								style={{ width: "100%" }}
								filterOption={(input, option) =>
									option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
								}
								// name="country"
							>
								{districts.map((district, index) => (
									<Option key={++index} value={district.id}>
										{district.name}
									</Option>
								))}
							</Select>
						</Form.Item>
					</Col>
					
					<Col lg={8} md={8} sm={12} xs={24}>
						<Form.Item
							label="Country"
							rules={[
								{ required: true, message: "Country is required" },
							]}
							name="country"
							className="form-lblock"
							initialValue={18}
						>
							<Select
								showSearch
								// optionFilterProp="children"
								placeholder="Select Your Country"
								style={{ width: "100%" }}
								filterOption={(input, option) =>
									option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
								}
								// name="country"
							>
								{countries.map((country, index) => (
									<Option key={++index} value={country.id}>
										{country.name}
									</Option>
								))}
							</Select>
						</Form.Item>
					</Col>
					
					<Col lg={8} md={8} sm={12} xs={24}>
						<Form.Item
							label="Address"
							name="address"
							className="form-lblock"
						>
							<Input placeholder="Address" />
						</Form.Item>
					</Col>
					
					<Col lg={24} md={24} sm={24} xs={24}>
						<Form.Item
							name="intro"
							label="Bio"
							className="form-lblock"
						>
							<CKEditor
								activeClass="p10"
								content={intro}
								events={{
									"change": handleBioChange
								}}
							/>
						</Form.Item>
					</Col>
					
					<Col lg={8} md={8} sm={12} xs={24}>
						<Form.Item
							label="Zip Code"
							name="zip_code"
							className="form-lblock"
						>
							<Input placeholder="Zip Code" />
						</Form.Item>
					</Col>
					
					<Col lg={8} md={8} sm={12} xs={24}>
						<Form.Item
							label="User Status"
							rules={[
								{
									required: true,
									message: "User Status is required",
								},
							]}
							name="status"
							className="form-lblock"
						>
							<Select
								placeholder="Select An Option"
								style={{ width: "100%" }}
								name="status"
							>
								<Option value="1">Active</Option>
								<Option value="0">Inactive</Option>
							</Select>
						</Form.Item>
					</Col>
					
					<Col lg={8} md={8} sm={12} xs={24}>
						<Form.Item
							label="Image"
							className="form-lblock"
						>
							<input
								type="file"
								name="image"
								onChange={changeImage}
							/>
							
							{
								source != '' &&
								<img src={source} alt="" style={{ width: "120px", height: "100px", marginTop: "10px" }}/>
							}
							
							{/*<FileUploads data={imgData}/>*/}
							
							{/*<Upload {...content}>*/}
							{/*/!*<Upload onChange={handleImageChange}>*!/*/}
							{/*	<Button icon={<UploadOutlined />}>Click to Upload</Button>*/}
							{/*</Upload>,*/}
							
						</Form.Item>
						
						{/*{*/}
						{/*	source != '' && (*/}
						{/*		<div className="col-md-2">*/}
						{/*			<img src={source} alt="Hello" style={{ width: "150px", height: "120px"}}/>*/}
						{/*			<button className="btn btn-secondary btn-sm remove-btn" onClick={() => removeImage(source)}>x</button>*/}
						{/*		</div>*/}
						{/*	)*/}
						{/*}*/}
					</Col>
					
					<Col lg={24} xs={24} style={{ textAlign: "center" }}>
						<Button type="secondary" htmlType="back"  onClick={() => history.push(redirectto)}>
							Back
						</Button>
						<Button type="primary" htmlType="submit">
							Save
						</Button>
					</Col>
				</Row>
			</Form>
		</Card>
	);
};

export default AddGeneralInfo;