import React, {useEffect, useState} from "react";
import {Card, Col, Layout, Row} from "antd";
import { CisUI } from "../../../util/CISUI";
import axios from "axios";
import ApiUrl from "../../../APIURL/Patient/ApiUrl";
import Config from "../../../util/config";
import moment from "moment";

const MyCard = () => {
	const userID = CisUI().getLocalUserID();
	const [cardData, setCardData] = useState([]);

	const getMyCard = () => {
		axios.get(ApiUrl.my_card + "/" + userID, CisUI().HeaderRequest)
			.then(res => {
				console.log(res.data.data)
				if(res.data.status === 1) {
					setCardData(res.data.data)
				}
			})
			.catch(error => console.log(error.response))
	}

	useEffect(() => {
		getMyCard()
	}, []);
	
	// console.log(cardData)


	return (
		// <Col lg={24} md={24} sm={24} xs={24}>
			<Card title="My Card">
				<div className="user_card">
					<Row>
						<Col lg={12} md={12} sm={24} xs={24}>
							<div className="card_front_image">
								<div className="card-main-area" >
									<div className="card_details" style={{ backgroundImage: `url("/assets/images/card/front.png")` }}>
										<p className="card_title">Health Card</p>
										<div className="card-footer">
											<p className="card_number">{cardData.unqid}</p>
											<p className="card_cvv">{cardData.cvv}</p>
											<p className="validation">
												<div className="validation-from" style={{ marginRight: "10px" }}>
													<p className="text" style={{ marginRight: "5px" }}>Valid <br /> From.</p>
													<p className="date">{moment(cardData.purchased_at).format("MM/YY")}</p>
												</div>
												<div className="validation-from">
													<p className="text" style={{ marginRight: "5px" }}>Expires <br /> End.</p>
													<p className="date">{moment(cardData.validate_at).format("MM/YY")}</p>
												</div>
											</p>
											<p className="card_name">{cardData?.card_name?.toUpperCase()}</p>
										</div>
									</div>
								</div>
							</div>
						</Col>

						<Col lg={12} md={12} sm={24} xs={24}>
							<div className="card_back_image">
								<div className="card_back" style={{ backgroundImage: `url("/assets/images/card/back.png")` }} />
							</div>
						</Col>
					</Row>
				</div>
			
			
			</Card>
		// </Col>
		
	);
};

export default MyCard;
