
import { useEffect } from "react";
import { Row, Layout } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfileData } from "../../../appRedux/actions";
import HomeCard from "../../../components/HomePage/HomeCard";
import {CisUI} from "../../../util/CISUI";

const Home = () => {

	const dispatch = useDispatch();
	const userID = CisUI().getLocalUserID();

	let userData = useSelector(({ auth }) => auth.profileData);

	useEffect(() => {
		dispatch(getUserProfileData(userID))
	}, [userID]);


	return (
		<>
			<main className="ant-layout-content gx-layout-content gx-container-wrap ">
				<div className="home-main-content">
					<Layout className="ant-layout-has-sider gx-algolia-layout-has-sider">

						<div className="gx-algolia-main">
							<h1 className="home-top-header">Welcome Back, {userData.full_name}</h1>

							{/*<Row gutter={24}>*/}
							{/*	<HomeCard />*/}
							{/*</Row>*/}

						</div>
					</Layout>
				</div>
			</main>
		</>
	);
};

export default Home;
