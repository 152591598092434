import React from "react";
import {Carousel } from "antd";


const ScrollAutomatically = () => {

    return (
        <Carousel autoplay autoplaySpeed={1500}>
            <img src='/assets/sakib/covid_care.jpeg' style={{ width: "100%"}} alt={""}/>
            <img src='/assets/sakib/1.png' style={{ width: "100%" }}/>
            <img src='/assets/sakib/2.png' style={{ width: "100%" }}/>
            <img src='/assets/sakib/3.png' style={{ width: "100%" }}/>
            <img src='/assets/sakib/4.png' style={{ width: "100%" }}/>
            <img src='/assets/sakib/5.png' style={{ width: "100%" }}/>
            <img src='/assets/sakib/6.png' style={{ width: "100%" }}/>
            <img src='/assets/sakib/7.png' style={{ width: "100%" }}/>
            <img src='/assets/sakib/8.png' style={{ width: "100%" }}/>
            <img src='/assets/sakib/9.png' style={{ width: "100%" }}/>
        </Carousel>
    );
};

export default ScrollAutomatically;
