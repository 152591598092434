import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Avatar, Popover} from "antd";
import {getUserProfileData, userSignOut} from "../../appRedux/actions";
import {CisUI} from "../../util/CISUI";
import Config from "../../util/config";
import {useHistory} from "react-router-dom";

const UserProfile = () => {
	const userID = CisUI().getLocalUserID();
	// const userArea = CisUI().getLocalUserByKey('area');
	// console.log(userArea)
	const history = useHistory();
	const dispatch = useDispatch();
	let userData = useSelector((state) => state.auth.profileData);
	useEffect(() => {
		// if(userArea == )
		dispatch(getUserProfileData(userID));
	}, []);
	const userMenuOptions = (
		<ul className="gx-user-popover">
			<li onClick={() => history.push('/doctor/settings/profile_settings')}>My Account</li>
			<li onClick={() => dispatch(userSignOut())}>Logout
			</li>
		</ul>
	);

	return (
		<div className="gx-flex-row gx-align-items-center gx-avatar-row">
			<Popover placement="bottomRight" content={userMenuOptions} trigger="click">
				<Avatar src={userData.image != ''? Config.profile_img + userData.image :"https://via.placeholder.com/150"} className="gx-size-40 gx-pointer gx-mr-3" alt=""/>
				<span className="gx-avatar-name">{CisUI().getLocalUserByKey('full_name')}<i
					className="icon icon-chevron-down gx-fs-xxs gx-ml-2"/></span>
			</Popover>
		</div>
	)
};

export default UserProfile;
