import React from "react";
import { Route, Switch } from "react-router-dom";

import NewAppointment from "../../../containers/PatientPortal/Appointment/NewAppointment";
import AppointmentList from "../../../containers/PatientPortal/Appointment/AppointmentList";
import ClosedAppointment from "../../../containers/PatientPortal/Appointment/ClosedAppointment";
import AppointmentDetails from "../../../containers/PatientPortal/Appointment/AppointmentDetails";

const Appointment = ({ match }) => (

    <Switch>
        <Route
            path={`${match.url}/new`}
            component={NewAppointment}
        />
        <Route
            path={`${match.url}/list`}
            component={AppointmentList}
        />
           <Route
            path={`${match.url}/closed-list`}
            component={ClosedAppointment}
        />
        <Route
            path={`${match.url}/appointmentdetails/:id`}
            component={AppointmentDetails}
        />
    </Switch>
);

export default Appointment;
