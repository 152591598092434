import React, { useState, } from "react";
import {Card, Collapse, Form,} from "antd";
import {Link, useHistory,} from "react-router-dom";
import { CisUI } from "../../../util/CISUI";
import ApiUrl from "../../../APIURL/Doctor/ApiUrl";
import SmartDataTable from 'react-smart-data-table';
import IntlMessages from "../../../util/IntlMessages";
import ActionMenu from "../../../util/ActionMenu";
import AdvancedSearch from "./AdvancedSearch";
const {Panel} = Collapse;

const ClosedAppointmentList = () => {
	const [state, setState] = useState({
		reqURL: ApiUrl.appointment_list
	});
	const history = useHistory();
	const [form] = Form.useForm();
	
	const headers = {
		_id: {
			text: 'SL No',
			invisible: true,
			filterable: false,
			transform: (value) => `Row #${value + 1}`,
		},
		id: {
			text: <IntlMessages id="Sl NO"/>,
			sortable: true,
			filterable: true,
			transform: (value, id, row) => (<p style={{textAlign: "center"}}>{id+1}</p>)

		},
		unqid: {
			text: <IntlMessages id="Appointment Id"/>,
			sortable: true,
			filterable: true
		},
		type_name: {
			text: <IntlMessages id="Appointment Type"/>,
			sortable: true,
			filterable: true
		},
		patient_image: {
			invisible: true,
		},
		doctor_image: {
			invisible: true,
		},
		patient_name: {
			text: <IntlMessages id="Patient"/>,
			sortable: true,
			filterable: true,
			transform: (value, idx, row) =>
				<>
					<img src={row.patient_image != null ? row.patient_image : "https://via.placeholder.com/150"} alt="" style={{ width:"40px", height:"40px", borderRadius:"50%", marginRight:"8px"}}/>
					{row.patient_name}
				</>
		},
		doctor_name: {
			text: <IntlMessages id="Doctor"/>,
			sortable: true,
			filterable: true,
            invisible:true,
			transform: (value, idx, row) =>
				<>
					<img src={row.doctor_image != null ? row.doctor_image : "https://via.placeholder.com/150"} alt="" style={{ width:"40px", height:"40px", borderRadius:"50%", marginRight:"8px"}}/>
					{row.doctor_name}
				</>
		},
       
		date: {
			text: <IntlMessages id="Appointment Date"/>,
			sortable: true,
			filterable: true,
		},
		time: {
			text: <IntlMessages id="Appointment Time"/>,
			sortable: true,
			filterable: true,
		},
		status: {
			text: <IntlMessages id="Status"/>,
			sortable: true,
			filterable: true,
			transform: (value, idx, row) => (
				CisUI().getAppointmentStatusLabel(value)
			)
		},
		actions: {
			text: <IntlMessages id="Actions"/>,
			sortable: false,
			filterable: false,
			transform: (value, idx, row) => (
				<>
					{ActionMenu('/admin/patient/editpatient/',ApiUrl.delete_patient,row.id)}
				</>
			),
		},
	};

	const orderedHeaders = ["id", "unqid", "type_name", "patient_name", "doctor_name", "date", "time", "status"];
	
	let redirectto = '/admin/patient/addpatient';
	
	const onFinish = values => {
		// console.log('Received values of form: ', values);
		let data = {
			...values,
			date: values['date'].format('YYYY-MM-DD')
		}
		const qs = Object.keys(data).map(key => `${key}=${data[key]}`).join('&');
		const qsup = qs.replaceAll("undefined","");
		//reqURL = reqURL+"/?"+qsup;
		history.push("?"+qsup);
		const newURL = ApiUrl.appointment_list+"/?"+qsup;
		setState({reqURL: newURL});
	};
	
	const searchView  = (
		<Card className="advance-search-card">
			<Collapse expandIconPosition="right" defaultActiveKey={['1']}>
				<Panel header="Advanced Search" key="1">
					<Form
						form={form}
						name="advanced_search"
						className="ant-advanced-search-form"
						onFinish={onFinish}
					>
						{AdvancedSearch}
					</Form>
				</Panel>
			</Collapse>
		</Card>
	);

	return (
		<>
			{searchView}
			<Card className="tickets-card" title="Appointment List" extra={
				<Link to={redirectto} className="add_icon"><i className="icon icon-add" /></Link>
			}>
				<SmartDataTable
					name='data_table'
					data={state.reqURL}
					dataRequestOptions={CisUI().HeaderRequest}
					dataKey="data"
					headers={headers}
					orderedHeaders={orderedHeaders}
					hideUnordered={CisUI().hideUnordered}
					className={CisUI().sematicUI.table}
					// filterValue={state.filterValue}
					perPage={CisUI().perPage}
					sortable
					withLinks
					withHeader
					loader={CisUI().loader}
					// parseBool={{
					// 	yesWord: 'Yes',
					// 	noWord: 'No',
					// }}
					// parseImg={{
					// 	style: {
					// 		border: '1px solid #ddd',
					// 		borderRadius: '50px',
					// 		padding: '3px',
					// 		width: '30px',
					// 		height: '30px'
					// 	},
					// }}
					emptyTable={CisUI().emptyTable}
				/>
			</Card>
		</>
	);
};

export default ClosedAppointmentList;



