import React, {useEffect, useState} from "react";
import {Card, Tabs} from 'antd';
import 'react-smart-data-table/dist/react-smart-data-table.css'
import IntlMessages from "../../../util/IntlMessages";
import GeneralInfo from "./GeneralInfo/UpdateGeneralInfo";
import ConsultantInfo from "./ConsultantInfo/UpdateConsultantInfo";
import {Link} from "react-router-dom";
import axios from "axios";
import ApiUrl from "../../../APIURL/Doctor/ApiUrl";
import {CisUI} from "../../../util/CISUI";


const { TabPane } = Tabs;

const ProfileSettings = () => {
    const userID = CisUI().getLocalUserByKey('doctor_id');
    const [generalInfo, setGeneralInfo] = useState([]);
    const [consultInfo, setConsultantInfo] = useState([]);
    // console.log(userID)

    const getDoctorData = () => {
        axios.get(ApiUrl.get_doctor_profile + `/${userID}`, CisUI().HeaderRequest)
            .then((res) => {
                console.log(res)
                if (res.data.status == 1) {
                    setGeneralInfo(res.data.general_info);
                    setConsultantInfo(res.data.consultant_info);
                } else {
                    console.log("error");
                }
            })
            .catch((errors) => console.log(errors));
    }

    useEffect(() => {
        getDoctorData()
    }, []);


    return (
        <Card title={<IntlMessages id="Update Profile"/>}>

            <Tabs defaultActiveKey={1} style={{ marginBottom: 32 }}>
                <TabPane tab="General Info." key="1">
                    <GeneralInfo editId={userID} editData={generalInfo}/>
                </TabPane>
                <TabPane tab="Consultant Info." key="2">
                    <ConsultantInfo editId={userID} editData={consultInfo} />
                </TabPane>
            </Tabs>

        </Card>
    );
};

export default ProfileSettings
