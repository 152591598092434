/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from "react";
import { Button, Table } from "antd";
import Widget from "components/Widget/index";
import axios from "axios";
import ApiUrl from "../../../APIURL/Patient/ApiUrl";
import { CisUI } from "../../../util/CISUI";
import { ArrowDownOutlined } from "@ant-design/icons";

const HospitalList = () => {
  const [hospitals, setHospitals] = useState([])
  const [number, setNumber] = useState(10);

  const getHospitals = () => {
    axios.get(ApiUrl.hospital_list, CisUI().HeaderRequest)
      .then(res => {
        setHospitals(res.data)
        console.log(res.data);
      })
      .catch(error => console.log(error))
  }

  useEffect(() => {
    getHospitals()
  }, []);

  const loadMore = (() => {
    setNumber(number + 10)
  })

  const columns = [
    {
      title: 'ID',
      dataIndex: 'key',
    },
    {
      title: 'Hospital Name',
      dataIndex: 'name',
      render: (text, record) => {
        console.log(record)
        return <p className="gx-mb-0 gx-avatar-name" style={{fontWeight:"bold"}}>{record.name}</p>
      },
    },
    {
      title: 'Contact Number',
      dataIndex: 'phone',
      render: (text, record) => {
        return <span className="gx-text-grey"><a href={`tel:${record.phone}`}>{record.phone}</a></span>
      },
    },
    {
      title: 'Location',
      dataIndex: 'address',
      render: (text, record) => {
        return <span className="gx-text-grey">{record.address}</span>
      },
    },
    {
      title: 'Discount',
      dataIndex: 'discount',
      render: (text, record) => {
        return <span className="gx-text-grey">{record.discount}%</span>
      },
    }

  ];

  const data = [];
  hospitals?.slice(0, number).map((item, index) =>
    data.push({
      key: ++index,
      name: item.name,
      phone: item.phone,
      address: item.address,
      discount: item.discount
    })
  )

  return (
    <Widget
      title={
        <h2 className="h4 gx-text-capitalize gx-mb-0 gx-text-center">Discounted Hospital List In All Over Bangladesh</h2>
      } >
      <div className="gx-table-responsive">
        <Table className="" columns={columns} dataSource={data} pagination={false}
          size="small" />
      </div>
      {hospitals.length > number && <Button shape="round" size="small" onClick={loadMore} icon={<ArrowDownOutlined />} style={{ fontSize: "10px", display: "flex", alignItems: "center", margin: "10px auto" }}>Load More</Button>}
      <div>
      </div>
    </Widget>
  );
};

export default HospitalList;
