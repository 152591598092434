import React from 'react';
import axios from "axios";
import Config from "../../../util/config";
import {CisUI} from "../../../util/CISUI";

const CardListDownload = () => {
    axios.get(Config.apiserver + 'cardlists?excel=1',CisUI().HeaderRequest)
        .then(res => {

        }).catch(function (error) {
        console.log("Error****:", error.message);
    });
    return(<></>);
}

export default CardListDownload;
