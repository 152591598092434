import React, {useEffect, useState} from 'react';
import {Button, Col, Collapse, Form, Input, Row, Select} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {getCountry, getDistrict} from "../../../appRedux/actions/Country";
import {getDepartment} from "../../../appRedux/actions/Department";

const {Option} = Select;

const AdvancedSearch = () => {

	const dispatch = useDispatch();
	let districts = useSelector(({ district }) => district.districts);
	let departments = useSelector(({ department }) => department.departments);


	useEffect(() => {
		dispatch(getDepartment());
		dispatch(getDistrict());
	}, []);

	return (
		<Row
			className="ant-row"
			style={{ rowGap: "15px", display:"flex", alignItems: "flex-end" }}
			// justify="space-between"
		>
			<Col lg={6} md={6} sm={12} xs={24}>
				<Form.Item
					label="Department"
					name="department_id"
					className="form-lblock"
				>
					<Select
						showSearch
						// optionFilterProp="children"
						placeholder="Select Your Department"
						style={{ width: "100%" }}
						filterOption={(input, option) =>
							option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
						}
					>
						{
							departments.map((department, index) => (
								<Option key={++index} value={department.id}>
									{department.name}
								</Option>
							))
						}
					</Select>
				</Form.Item>
			</Col>

			<Col lg={6} md={6} sm={12} xs={24}>
				<Form.Item
					label="Name"
					name="name"
					className="form-lblock"
				>
					<Input placeholder="Name" />
				</Form.Item>
			</Col>

			<Col lg={6} md={6} sm={12} xs={24}>
				<Form.Item
					label="Phone"
					name="phone"
					className="form-lblock"
				>
					<Input placeholder="Phone" />
				</Form.Item>
			</Col>

			<Col lg={6} md={6} sm={12} xs={24}>
				<Form.Item
					label="Degree"
					name="degree"
					className="form-lblock"
				>
					<Input placeholder="Degree" />
				</Form.Item>
			</Col>

			<Col lg={6} md={6} sm={12} xs={24}>
				<Form.Item
					label="Specialist"
					name="specialist"
					className="form-lblock"
				>
					<Input placeholder="specialist" />
				</Form.Item>
			</Col>

			<Col lg={6} md={6} sm={12} xs={24}>
				<Form.Item
					label="District"
					name="district"
					className="form-lblock"
				>
					<Select
						showSearch
						// optionFilterProp="children"
						placeholder="Select Your District"
						style={{ width: "100%" }}
						filterOption={(input, option) =>
							option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
						}
						// name="country"
					>
						{
							districts.map((district, index) => (
								<Option key={++index} value={district.id}>
									{district.name}
								</Option>
							))
						}
					</Select>
				</Form.Item>
			</Col>

			<Col lg={6} md={6} sm={12} xs={24}>
				<Form.Item
					label="User Status"
					name="status"
					className="form-lblock"
				>
					<Select
						placeholder="Select An Option"
						style={{ width: "100%" }}
						name="status"
					>
						<Option value="1">Active</Option>
						<Option value="0">Inactive</Option>
					</Select>
				</Form.Item>
			</Col>

			<Col Col lg={6} md={6} sm={12} xs={24}>

				{/*<Form.Item*/}
				{/*	label=""*/}
				{/*	name=""*/}
				{/*	className="form-lblock"*/}
				{/*>*/}
				{/*	<Button type="primary" htmlType="submit">*/}
				{/*		Save*/}
				{/*	</Button>*/}
				{/*</Form.Item>*/}
				<Button type="primary" htmlType="submit" style={{ marginBottom: "0"}}>
					Search
				</Button>
			</Col>

		</Row>
	);
}

export default AdvancedSearch;
