import {GET_ALL_AGENT} from "../../constants/ActionTypes";

const INIT_STATE = {
	agents: [],
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
		
		case GET_ALL_AGENT: {
			return {...state, agents: action.payload};
		}
		
		default:
			return state;
	}
}
