import React, {useEffect} from "react";
import {Button, Form, Input, Col, Row, DatePicker, Select} from "antd";
import {useDispatch, useSelector} from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { UserOutlined, LockOutlined, IdcardOutlined, BankOutlined } from "@ant-design/icons";
import config from "../util/config";
import axios from "axios";
import { CisUI } from "../util/CISUI";

import moment from "moment";
import {getDistrict} from "../appRedux/actions/Country";

const {Option} = Select;
const SignUp = (props) => {

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const phone = urlParams.get('phone')
    const type = urlParams.get('type')

    const history = useHistory();
    const dispatch = useDispatch();

    let districts = useSelector(({ district }) => district.districts);

    useEffect(() => {
        dispatch(getDistrict());
    }, []);

    if (phone === null & type === null) {
        history.push("/signin")
    }

    const authUser = useSelector(({ auth }) => auth.authUser);


    const onFinish = values => {
        // console.log(values.bod);
        const data = {
            ...values,
            dob: values['dob'].format('YYYY-MM-DD'),
            phone,
            type
        }
        // console.log(data);
        let endUrl = "";
        if(type === 'doctor'){
            endUrl = 'doctorsignup';
        }
        else if(type === 'patient'){
            endUrl = 'patientsignup';
        }

        // console.log(endUrl);
        document.getElementById("loader").style.display = "block";
        axios.post(`${config.apiserver+endUrl}`, data).then(function (response) {
            // handle success
            console.log(response);
            if (response.data.status === 0) {
                CisUI().Notification("warning", response.data.msg)
            }
            if (response.data.status === 1) {
                CisUI().Notification("success", response.data.msg)
                history.push(`/signin`)

            }
            document.getElementById("loader").style.display = "none";
        }).catch(function (error) {
            // handle error
            console.log(error);
        })
    };

    useEffect(() => {
        if (authUser !== null) {
            history.push('/');
        }
    }, [authUser]);

    return (
        <>
            <div className="signup-wrap" >
                {CisUI().showLoading}
                <img alt="example" src="/assets/images/logo.png" className="login-logo" />
                <Form
                    onFinish={onFinish}
                >

                    <Row className="ant-row" >

                        <Col lg={12} xs={24}>
                            <Form.Item
                                label="Full Name"
                                rules={[{ required: true, message: 'name is required!' }]} name="full_name">
                                <Input placeholder="Full Name" size="large" prefix={<UserOutlined />} />
                            </Form.Item>
                        </Col>

                        <Col lg={12} xs={24}>
                            <Form.Item
                                label="Gender"
                                name="gender"
                                className="form-lblock"
                                rules={[
                                    { required: true, message: "Gender is required" },
                                ]}
                            >
                                <Select
                                    showSearch
                                    placeholder="Select An Option"
                                    style={{ width: "100%" }}
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }

                                >
                                    <Option value="Male">Male</Option>
                                    <Option value="Female">Female</Option>
                                    <Option value="Others">Others</Option>
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col lg={12} xs={24}>
                            <Form.Item
                                label="Password"
                                rules={[{ required: true, message: 'name is required!' }]} name="password">
                                <Input placeholder="Password" size="large" prefix={<LockOutlined />} type="password" />
                            </Form.Item>
                        </Col>

                        <Col lg={12} xs={24}>
                            <Form.Item
                                label="Confirm Password"
                                rules={[{ required: true, message: 'Confirm Password is required!' }]} name="confirm_password">
                                <Input placeholder="Confirm Password" size="large" prefix={<LockOutlined />} type="password" />
                            </Form.Item>
                        </Col>

                        <Col lg={12} xs={24}>
                            <Form.Item
                                label="Date Of Birth"
                                rules={[{ required: true, message: 'Date of birth is required!' }]} name="dob">
                                <DatePicker format="DD-MM-YYYY"/>
                            </Form.Item>
                        </Col>

                        {/* <Col lg={12} xs={24}>
                            <Form.Item
                                label="NID"
                                // rules={[{ required: true, message: 'NID is required!' }]}
                                name="nid">
                                <Input placeholder="NID" size="large" prefix={<IdcardOutlined />} />
                            </Form.Item>
                        </Col> */}

                        <Col lg={12} xs={24}>
                            <Form.Item
                                label="District"
                                name="district"
                                className="form-lblock"
                                // rules={[
                                //     { required: true, message: "District is required" },
                                // ]}
                            >
                                <Select
                                    showSearch
                                    // optionFilterProp="children"
                                    placeholder="Select Your District"
                                    style={{ width: "100%" }}
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    // name="country"
                                >
                                    {districts.map((district, index) => (
                                        <Option key={++index} value={district.id}>
                                            {district.name}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>


                        <Col lg={12} xs={24}>
                            <Form.Item
                                label="Address"
                                rules={[{ required: true, message: 'Address is required!' }]} name="address">
                                <Input placeholder="Address" size="large" prefix={<BankOutlined />} />
                            </Form.Item>
                        </Col>

                        <Col lg={24} xs={24} style={{ textAlign: "center" }}>

                            <Button type="secondary" htmlType="reset">
                                Cancel
                            </Button>
                            <Button type="primary" htmlType="submit">
                                Submit
                            </Button>
                        </Col>
                    </Row>

                </Form>

                <p style={{ textAlign: "center" }}>Already have an account? <Link to="/signin">Sign In</Link> </p>
            </div>

        </>
    );
};

export default SignUp;



