import React, {useEffect, useState,} from "react";
import {Button, Card, Col, Collapse, Form, Row,} from "antd";
import {Link, useHistory,} from "react-router-dom";
import { CisUI } from "../../../util/CISUI";
import ApiUrl from "../../../APIURL/Patient/ApiUrl";
import axios from "axios";
import moment from "moment";
const {Panel} = Collapse;

const AppointmentDetails = (props) => {
    const history = useHistory();
    const appointment_id = props.match.params.id;
    const [details, setDetails] = useState([]);
    const [presImage, setPresImage] = useState('');

    const getAppointmentDetails = (appointment_id) => {
        document.getElementById("loader").style.display = "block";
        axios.get(ApiUrl.appointment_details+"/"+appointment_id, CisUI().HeaderRequest)
            .then(res => {
                console.log(res.data)
                if(res.data.status === 1) {
                    setDetails(res.data.data);
                    setPresImage(res.data.prescription_image)
                }
                else {
                    CisUI().Notification('error', res.data.msg)
                }
                document.getElementById("loader").style.display = "none";
            })
            .catch(error => {
                console.log(error.response)
            })
    }

    useEffect(() => {
        getAppointmentDetails(appointment_id)
    }, [appointment_id]);

    const redirectToPay = (id) => {
        window.open('https://sakib75sandboxapi.sakib75.health/aptmntdetails/'+id, '_blank');
    }

    return (
        <Col lg={14} md={24} sm={24} xs={24} style={{ display:"block", margin:"0 auto"}}>
            <Card className="tickets-card">
                {CisUI().showLoading}
                <div className="appointment_details" style={{ textAlign:"center" }}>
                    <div className="message">
                        <img src="https://sakib75sandboxapi.sakib75.health/public/upload/verified.svg" alt="" style={{ width:"5%" }}/>
                        <p style={{ padding:"5px 0" }}>{CisUI().getAppointmentMsg(details.status)}</p>
                    </div>

                    <div className="doctor_details" style={{width: "50%", margin:"0 auto", textAlign:"left"}}>
                        <Card>
                            <Row>
                                <Col className={"p-auto"} lg={7} md={8} sm={8} xs={24} style={{ paddingRight: "0px"}}>
                                    <img src={details.image != null ? details.image : "https://via.placeholder.com/150"} alt=""
                                         style={{ width:"80px", height:"80px", borderRadius:"50%" }}/>
                                </Col>
                                <Col lg={16} md={16} sm={16} xs={24} style={{ paddingLeft: "10px"}}>
                                    <p>{details.doctor_name}</p>
                                    <p>{details.degree}</p>
                                    <p>{details.specialist}</p>
                                </Col>
                            </Row>
                        </Card>
                    </div>

                </div>

                <div className="info" style={{ width: "50%", margin: "0 auto"}}>
                    <p>Appointment ID: <span style={{ color:"#3a6472" }}>{details.unqid}</span></p>
                    <p>Appointment Type: <span style={{ color:"#3a6472" }}>{details.type_name}</span></p>
                    <p>Appointment Date: <span style={{ color:"#3a6472" }}>{moment(details.date).format('DD-MM-YYYY')}</span></p>
                    <p>Appointment Time: <span style={{ color:"#3a6472" }}>{details.time}</span></p>
                    <p>Patient Name: <span style={{ color:"#3a6472" }}>{details.patient_name}</span></p>
                    <p>Fee: <span style={{ color:"#3a6472" }}>{details.fees}</span></p>
                    {details?.call_type ==3 ?
                        <p>For Call: <img src={"/assets/images/phone.png"} style={{ width:"25px", height:"25px", borderRadius:"50%" }}/> <span style={{ color:"#3a6472" }}>{details.call_id}</span></p>
                        : <p>For Call: <img src={"/assets/images/whatsapp.png"} style={{ width:"25px", height:"25px", borderRadius:"50%" }}/> <span style={{ color:"#3a6472" }}>{details.call_id}</span></p>
                    }
                    {
                        presImage !== '' && (
                            <p>Prescription: <a href={presImage} target="_blank">Download</a></p>)

                    }
                    <p>Status: <span style={{ color:"#3a6472" }}>
						{CisUI().getAppointmentStatusText(details.status)}
                        {/*{details.status === 1 && 'Pending' }*/}
                        {/*{details.status === 0 && 'Closed' }*/}
                        {/*{details.status === 2 && 'Running' }*/}
                        {/*{details.status === 3 && 'Confirmed' }*/}
                        {/*{details.status === 4 && 'Cancel' }*/}
					</span></p>
                </div>
                <div style={{ textAlign: "center", margin:"30px 0 0 0" }}>
                    <Button type="primary" onClick={() => history.goBack()} style={{ marginBottom: "0"}}>
                        Back
                    </Button>
                    {details.status === 1 && (
                        <Button type="primary" onClick={() => redirectToPay(details.id)} style={{ marginBottom: "0"}}>
                            Pay
                        </Button>
                    )}
                    {/*{*/}
                    {/*    details.status !== 1 && (*/}
                    {/*        <>*/}
                    {/*            <Button type="primary" style={{ marginBottom: "0"}}>*/}
                    {/*                Call*/}
                    {/*            </Button>*/}

                    {/*            <Button type="primary" onClick={() => history.push('/admin/prescription/createprescription/'+details.id)} style={{ marginBottom: "0"}}>*/}
                    {/*                Create Prescription*/}
                    {/*            </Button>*/}
                    {/*        </>*/}
                    {/*    )*/}
                    {/*}*/}
                </div>

            </Card>
        </Col>
    );
};

export default AppointmentDetails;
