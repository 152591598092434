import React from "react";
import { Route, Switch } from "react-router-dom";
import DownloadPrescription from "../../../containers/PatientPortal/Prescription/DownloadPrescription";

const Prescription = ({ match }) => (
    <Switch>
        <Route
            path={`${match.url}/`}
            component={DownloadPrescription}
        />
    </Switch>

);

export default Prescription;