import React, { useState, } from "react";
import { Card, Collapse, Form, } from "antd";
import { Link, useHistory, } from "react-router-dom";
import { CisUI } from "../../../util/CISUI";
import ApiUrl from "../../../APIURL/Doctor/ApiUrl";
import SmartDataTable from 'react-smart-data-table';
import IntlMessages from "../../../util/IntlMessages";
import ActionMenu from "../../../util/ActionMenu";
import AdvancedSearch from "./AdvancedSearch";
import moment from "moment";
const { Panel } = Collapse;

const AppointmentList = () => {
    const [state, setState] = useState({
        reqURL: ApiUrl.appointment_list+"/?status=1"
    });
    const history = useHistory();
    const [form] = Form.useForm();

    const headers = {
        _id: {
            text: 'SL No',
            invisible: true,
            filterable: false,
            transform: (value) => `Row #${value + 1}`,
        },
        id: {
            text: <IntlMessages id="Sl NO"/>,
            sortable: true,
            filterable: true,
            transform: (value, id, row) => (<p style={{textAlign: "center"}}>{id+1}</p>)
        },
        patient_name: {
            text: <IntlMessages id="Name" />,
            sortable: true,
            filterable: true,
            transform: (value, idx, row) =>
                <>
                    <img src={row.image != null ? row.image : "https://via.placeholder.com/150"} alt="" style={{ width: "40px", height: "40px", borderRadius: "50%", marginRight: "8px" }} />
                    <Link to={`/doctor/appointment/appointmentdetails/${row.id}`}>{row.patient_name}</Link>
                </>
        },

        unqid: {
            text: <IntlMessages id="Appointment Id" />,
            sortable: true,
            filterable: true,
            transform: (value, id, row) => (<Link to={`/doctor/appointment/appointmentdetails/${row.id}`}>{row.unqid}</Link>)
        },
        type_name: {
            text: <IntlMessages id="Type" />,
            sortable: true,
            filterable: true,
            transform: (value, id, row) => (CisUI().getAppointmentTypeLabel(value)
            )
        },
        date: {
            text: <IntlMessages id="Date" />,
            sortable: true,
            filterable: true,
            transform: (value, id, row) => moment(row.date).format('DD-MM-YYYY')
        },
        time: {
            text: <IntlMessages id="Time" />,
            sortable: true,
            filterable: true,
            transform: (value, id, row) => (<p>{row.time}</p>)
        },

        status: {
            text: <IntlMessages id="Status" />,
            sortable: true,
            filterable: true,
            transform: (value, idx, row) => (
                CisUI().getAppointmentStatusLabel(value)
            )
        },
        actions: {
            text: <IntlMessages id="Actions" />,
            sortable: false,
            filterable: false,
            transform: (value, idx, row) => (
                <>
                    {ActionMenu('/admin/patient/editpatient/', ApiUrl.delete_patient, row.id)}
                </>
            ),
            invisible:true
        },
        image:{
            invisible:true
        },
        weight: {
            text: <IntlMessages id="Weight" />,
            sortable: true,
            filterable: true,
            transform: (value, id, row) => (<p >{row.weight}</p>)
        },
        patient_dob: {
            invisible:true
        },
        age: {
            text: <IntlMessages id="Age" />,
            sortable: true,
            filterable: true,
            transform: (value, id, row) => (<p>{row.age}</p>)
        },
        gender: {
            text: <IntlMessages id="Gender" />,
            sortable: true,
            filterable: true,
            transform: (value, id, row) => (<p>{row.gender}</p>)
        },
        patient_phone:{
            invisible:true
        },
    };
    const orderedHeaders = ["id", "unqid", "type_name", "date", "time","patient_name", "gender", "age", "weight", "status"];

    let redirectto = '/admin/patient/addpatient';

    const onFinish = values => {
        // console.log('Received values of form: ', values);
        // let data = {
        // 	...values,
        // 	date: values['date'].format('YYYY-MM-DD')
        // }

        let data = '';
        if(values['date'] === null || values['date'] === undefined) {
            data = {
                ...values,
            }
        }
        else {
            data = {
                ...values,
                date: values['date'].format('YYYY-MM-DD')
            }
        }

        const qs = Object.keys(data).map(key => `${key}=${data[key]}`).join('&');
        const qsup = qs.replaceAll("undefined","");
        //reqURL = reqURL+"/?"+qsup;
        history.push("?"+qsup);
        const newURL = ApiUrl.appointment_list+"/?"+qsup;
        console.log(newURL)
        setState({reqURL: newURL});
    };

    const searchView = (
        <Card className="advance-search-card">
            <Collapse expandIconPosition="right" defaultActiveKey={['1']}>
                <Panel header="Advanced Search" key="1">
                    <Form
                        form={form}
                        name="advanced_search"
                        className="ant-advanced-search-form"
                        onFinish={onFinish}
                    >
                        {AdvancedSearch}
                    </Form>
                </Panel>
            </Collapse>
        </Card>
    );

    return (
        <>
            {searchView}
            <Card className="tickets-card" title="Pending Appointment List">
                <SmartDataTable
                    name='data_table'
                    data={state.reqURL}
                    dataRequestOptions={CisUI().HeaderRequest}
                    dataKey="data"
                    headers={headers}
                    orderedHeaders={orderedHeaders}
                    hideUnordered={CisUI().hideUnordered}
                    className={CisUI().sematicUI.table}
                    // filterValue={state.filterValue}
                    perPage={CisUI().perPage}
                    sortable
                    withLinks
                    withHeader
                    loader={CisUI().loader}
                    // parseBool={{
                    // 	yesWord: 'Yes',
                    // 	noWord: 'No',
                    // }}
                    // parseImg={{
                    // 	style: {
                    // 		border: '1px solid #ddd',
                    // 		borderRadius: '50px',
                    // 		padding: '3px',
                    // 		width: '30px',
                    // 		height: '30px'
                    // 	},
                    // }}
                    emptyTable={CisUI().emptyTable}
                />
            </Card>
        </>
    );
};

export default AppointmentList;
