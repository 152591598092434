import React, {useEffect, useState} from "react";
import {Col, Input, Row, Select, Form, Button, Card, DatePicker} from "antd";
import { Link, useHistory } from "react-router-dom";
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { useDispatch, useSelector } from "react-redux";
import { getCountry, getDistrict } from "../../../appRedux/actions/Country";
import axios from "axios";
import {CisUI} from "../../../util/CISUI";
import ApiUrl from "../../../APIURL/Admin/ApiUrl";

const { Option } = Select;

const AddAgent = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const [image, setImage] = useState("");

	let countries = useSelector(({ country }) => country.countries);
	let districts = useSelector(({ district }) => district.districts);

	useEffect(() => {
		dispatch(getCountry());
		dispatch(getDistrict());
	}, []);
	
	const changeImage = (e) => {
		let file = e.target.files[0];
		let reader = new FileReader();
		// setSource(URL.createObjectURL(e.target.files[0]))
		reader.onload = (e) => {
			setImage({
				image: e.target.result,
			});
		};
		// console.log(file);
		reader.readAsDataURL(file);
	};
	
	let redirectto = '/admin/agent/agentlist'
	
	const onFinish = (values) => {
		let data = {
			...values,
			dob: values['dob'].format("YYYY-MM-DD"),
			image: image
		}
		
		// console.log(data);
		document.getElementById("loader").style.display = "block";
		axios.post(ApiUrl.add_agent, data, CisUI().HeaderRequest)
			.then(res => {
				if(res.data.status === 1) {
					CisUI().Notification('success', res.data.msg)
					history.push(redirectto)
				}
				else {
					CisUI().Notification('error', res.data.msg)
				}
				document.getElementById("loader").style.display = "none";
			})
			.catch(error => {
				console.log(error.response.data)
			})
	};
	
	return (
		<Card title="Add Agent" extra={
			<Link to={redirectto} className="list_icon"><i className="icon icon-lising-dbrd" /></Link>
		}>
			{CisUI().showLoading}
			<Form onFinish={onFinish}>
				<Row
					className="ant-row"
					style={{ rowGap: "15px" }}
					// justify="space-between"
				>
					<Col lg={8} md={8} sm={12} xs={24}>
						<Form.Item
							label="Full Name"
							rules={[
								{ required: true, message: "Full Name is required" },
							]}
							name="full_name"
							className="form-lblock"
						>
							<Input placeholder="Full Name" />
						</Form.Item>
					</Col>
					

					<Col lg={8} md={8} sm={12} xs={24}>
						<Form.Item
							label="Phone"
							rules={[
								{ required: true, message: "Phone is required" },
							]}
							name="phone"
							className="form-lblock"
						>
							<Input placeholder="Phone" />
						</Form.Item>
					</Col>

					<Col lg={8} md={8} sm={12} xs={24}>
						<Form.Item
							label="Email"
							rules={[
								{ required: true, message: "Email is required" },
							]}
							name="email"
							className="form-lblock"
						>
							<Input placeholder="Email" />
						</Form.Item>
					</Col>
					
					<Col lg={8} md={8} sm={12} xs={24}>
						<Form.Item
							label="Password"
							rules={[
								{ required: true, message: "Password is required" },
							]}
							name="password"
							className="form-lblock"
						>
							<Input.Password placeholder="Password" iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} />
						</Form.Item>
					</Col>
					
					<Col lg={8} md={8} sm={12} xs={24}>
						<Form.Item
							label="Nationality"
							rules={[
								{ required: true, message: "Nationality is required" },
							]}
							name="nationality"
							className="form-lblock"
							initialValue="Bangladeshi"
						>
							<Input placeholder="Nationality" />
						</Form.Item>
					</Col>
					
					{/* <Col lg={8} md={8} sm={12} xs={24}>
						<Form.Item
							label="NID"
							rules={[
								{ required: true, message: "NID is required" },
							]}
							name="nid"
							className="form-lblock"
						>
							<Input placeholder="NID" />
						</Form.Item>
					</Col> */}
					
					<Col lg={8} md={8} sm={12} xs={24}>
						<Form.Item
							label="Date Of Birth"
							rules={[
								{ required: true, message: "Date Of Birth is required" },
							]}
							name="dob"
							className="form-lblock"
						>
							<DatePicker className="gx-w-100"/>
						</Form.Item>
					</Col>
					
					<Col lg={8} md={8} sm={12} xs={24}>
						<Form.Item
							label="Gender"
							rules={[
								{
									required: true,
									message: "Gender is required",
								},
							]}
							name="gender"
							className="form-lblock"
						>
							<Select
								placeholder="Select An Option"
								style={{ width: "100%" }}
								name="status"
							>
								<Option value="Male">Male</Option>
								<Option value="Female">Female</Option>
								<Option value="Others">Others</Option>
							</Select>
						</Form.Item>
					</Col>

					{/*<Col lg={8} md={8} sm={12} xs={24}>*/}
					{/*	<Form.Item*/}
					{/*		label="City"*/}
					{/*		name="city"*/}
					{/*		className="form-lblock"*/}
					{/*	>*/}
					{/*		<Input placeholder="City" />*/}
					{/*	</Form.Item>*/}
					{/*</Col>*/}

					<Col lg={8} md={8} sm={12} xs={24}>
						<Form.Item
							label="District"
							name="district"
							className="form-lblock"
							rules={[
								{ required: true, message: "District is required" },
							]}
						>
							<Select
								showSearch
								// optionFilterProp="children"
								placeholder="Select Your District"
								style={{ width: "100%" }}
								filterOption={(input, option) =>
									option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
								}
								// name="country"
							>
								{districts.map((district, index) => (
									<Option key={++index} value={district.id}>
										{district.name}
									</Option>
								))}
							</Select>
						</Form.Item>
					</Col>

					<Col lg={8} md={8} sm={12} xs={24}>
						<Form.Item
							label="Country"
							rules={[
								{ required: true, message: "Country is required" },
							]}
							name="country"
							className="form-lblock"
							initialValue={18}
						>
							<Select
								showSearch
								// optionFilterProp="children"
								placeholder="Select Your Country"
								style={{ width: "100%" }}
								filterOption={(input, option) =>
									option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
								}
							// name="country"
							>
								{countries.map((country, index) => (
									<Option key={++index} value={country.id}>
										{country.name}
									</Option>
								))}
							</Select>
						</Form.Item>
					</Col>
					
					<Col lg={8} md={8} sm={12} xs={24}>
						<Form.Item
							label="Address"
							name="address"
							className="form-lblock"
						>
							<Input placeholder="Address" />
						</Form.Item>
					</Col>

					<Col lg={8} md={8} sm={12} xs={24}>
						<Form.Item
							label="Zip Code"
							name="zipcode"
							className="form-lblock"
						>
							<Input placeholder="Zip Code" />
						</Form.Item>
					</Col>

					<Col lg={8} md={8} sm={12} xs={24}>
						<Form.Item
							label="User Status"
							rules={[
								{
									required: true,
									message: "User Status is required",
								},
							]}
							name="status"
							className="form-lblock"
						>
							<Select
								placeholder="Select An Option"
								style={{ width: "100%" }}
								name="status"
							>
								<Option value="1">Active</Option>
								<Option value="0">Inactive</Option>
							</Select>
						</Form.Item>
					</Col>
					
					<Col lg={8} md={8} sm={12} xs={24}>
						<Form.Item
							label="Image"
							name="file"
							className="form-lblock"
						>
							<input
								type="file"
								name="image"
								onChange={changeImage}
							/>
						</Form.Item>
						
						{/*{*/}
						{/*	source != '' && (*/}
						{/*		<div className="col-md-2">*/}
						{/*			<img src={source} alt="Hello" style={{ width: "150px", height: "120px"}}/>*/}
						{/*			<button className="btn btn-secondary btn-sm remove-btn" onClick={() => removeImage(source)}>x</button>*/}
						{/*		</div>*/}
						{/*	)*/}
						{/*}*/}
					</Col>

					<Col lg={24} xs={24} style={{ textAlign: "center" }}>
						<Button type="secondary" htmlType="back"  onClick={() => history.push(redirectto)}>
							Back
						</Button>
						<Button type="primary" htmlType="submit">
							Save
						</Button>
					</Col>
				</Row>
			</Form>
		</Card>
	);
};

export default AddAgent;