import {FETCH_ERROR, FETCH_START, FETCH_SUCCESS, GET_ALL_COUNTRY, GET_ALL_DISTRICT} from "../../constants/ActionTypes";
// import axios from '../../util/Api'
import axios from 'axios';
import {CisUI} from "../../util/CISUI";
import Config from '../../util/config';


export const getCountry = () => {
  return (dispatch) => {
    
    dispatch({type: FETCH_START});
    // let uinputs = { token: token };
    axios.get(Config.apiserver +'allcountries',CisUI().HeaderRequest)
      .then(res => {
      console.log(res.data);
      if (res.data.status === 1) {
        console.log(res.data.data);
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: GET_ALL_COUNTRY, payload: res.data.data});
        //console.log("UserInfo :"+data.user.name);
      } else {
        dispatch({type: FETCH_ERROR, payload: res.data.msg});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_SUCCESS});
      console.log("Error****:", error.message);
    });
  }
};

export const getDistrict = () => {
  return (dispatch) => {
    
    dispatch({type: FETCH_START});
    // let uinputs = { token: token };
    axios.get(Config.apiserver +'alldistricts',CisUI().HeaderRequest)
        .then(res => {
          console.log(res.data);
          if (res.data.status === 1) {
            console.log(res.data.data);
            dispatch({type: FETCH_SUCCESS});
            dispatch({type: GET_ALL_DISTRICT, payload: res.data.data});
            //console.log("UserInfo :"+data.user.name);
          } else {
            dispatch({type: FETCH_ERROR, payload: res.data.msg});
          }
        }).catch(function (error) {
      dispatch({type: FETCH_SUCCESS});
      console.log("Error****:", error.message);
    });
  }
};

