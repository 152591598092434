import React, {useEffect} from 'react';
import {Button, Col, Collapse, DatePicker, Form, Input, Row, Select} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {getAllDoctor} from "../../../appRedux/actions/Doctor";
import {getAllPatient} from "../../../appRedux/actions/Patient";

const {Option} = Select;

const AdvancedSearch = () => {

    const dispatch = useDispatch();
    let doctors = useSelector(({ doctor }) => doctor.doctors);
    let patients = useSelector(({ patient }) => patient.patients);

    useEffect(() => {
        dispatch(getAllDoctor());
        dispatch(getAllPatient());
    }, []);

    return (
        <Row
            className="ant-row"
            style={{ rowGap: "15px", display:"flex", alignItems: "flex-end" }}
            // justify="space-between"
        >
            <Col lg={6} md={6} sm={12} xs={24}>
                <Form.Item
                    label="Doctor"
                    name="doctor_id"
                    className="form-lblock"
                >
                    <Select
                        showSearch
                        // optionFilterProp="children"
                        placeholder="Select An Option"
                        style={{ width: "100%" }}
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        // name="country"
                    >
                        {
                            doctors.map((doctor, index) => (
                                <Option key={++index} value={doctor.id}>
                                    {doctor.full_name}
                                </Option>
                            ))
                        }
                    </Select>
                </Form.Item>
            </Col>

            {/*<Col lg={6} md={6} sm={12} xs={24}>*/}
            {/*	<Form.Item*/}
            {/*		label="Patient"*/}
            {/*		name="patient_id"*/}
            {/*		className="form-lblock"*/}
            {/*	>*/}
            {/*		<Select*/}
            {/*			showSearch*/}
            {/*			// optionFilterProp="children"*/}
            {/*			placeholder="Select An Option"*/}
            {/*			style={{ width: "100%" }}*/}
            {/*			filterOption={(input, option) =>*/}
            {/*				option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0*/}
            {/*			}*/}
            {/*			// name="country"*/}
            {/*		>*/}
            {/*			{*/}
            {/*				patients.map((patient, index) => (*/}
            {/*					<Option key={++index} value={patient.id}>*/}
            {/*						{patient.full_name}*/}
            {/*					</Option>*/}
            {/*				))*/}
            {/*			}*/}
            {/*		</Select>*/}
            {/*	</Form.Item>*/}
            {/*</Col>*/}

            <Col lg={6} md={6} sm={12} xs={24}>
                <Form.Item
                    label="Date"
                    name="date"
                    className="form-lblock"
                >
                    <DatePicker className="gx-w-100"/>
                </Form.Item>
            </Col>

            <Col lg={6} md={6} sm={12} xs={24}>
                <Form.Item
                    label="Status"
                    name="status"
                    className="form-lblock"
                >
                    <Select
                        placeholder="Select An Option"
                        style={{ width: "100%" }}
                        name="status"
                    >
                        <Option value="0">Close</Option>
                        <Option value="1">Pending</Option>
                        <Option value="2">Running</Option>
                        <Option value="3">Confirmed</Option>
                        <Option value="4">Cancel</Option>
                    </Select>
                </Form.Item>
            </Col>

            <Col Col lg={6} md={6} sm={12} xs={24}>
                <Button type="primary" htmlType="submit" style={{ marginBottom: "0" }}>
                    Search
                </Button>
            </Col>

        </Row>
    );
}

export default AdvancedSearch;
