import React from "react";
import { Route, Switch } from "react-router-dom";
import DoctorList from "../../../containers/PatientPortal/Doctors/Doctors";

const Doctors = ({ match }) => (

    <Switch>
        <Route
            path={`${match.url}/`}
            component={DoctorList}
        />

    </Switch>
);

export default Doctors;