import React from "react";
import { Route, Switch } from "react-router-dom";
import AddPatient from "../../../containers/AdminPortal/Patient/AddPatient";
import PatientList from "../../../containers/AdminPortal/Patient/PatientList";
import EditPatient from "../../../containers/AdminPortal/Patient/EditPatient";
// import DeletePatient from "../../containers/Service/DeletePatient";

const Patient = ({ match }) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route path={`${match.url}/addpatient`} component={AddPatient}/>
      <Route path={`${match.url}/patientlist`} component={PatientList}/>
      <Route path={`${match.url}/editpatient/:id`} component={EditPatient}/>
      {/* <Route path={`${match.url}/customerdelete/:id`} component={DeletePatient}/> */}
    </Switch>
  </div>
);

export default Patient;
