import React from "react";
import { Route, Switch } from "react-router-dom";
import DashBoard from "../../../containers/DoctorPortal/Dashboard/DashBoard";

const Dashboard = ({ match }) => (

    <Switch>
        <Route
            path={`${match.url}/`}
            component={DashBoard}
        />

    </Switch>
);

export default Dashboard;
