/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Col, Input, Row, Select, Form, Button, Card, DatePicker, Divider, Checkbox } from "antd";
import { Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import axios from "axios";
import { CisUI } from "../../../util/CISUI";
import ApiUrl from "../../../APIURL/Patient/ApiUrl";
import { getDoctor } from "../../../appRedux/actions/Doctor";
import CKEditor from "react-ckeditor-component";
import CircularProgress from "../../../components/CircularProgress";

const { Option } = Select;

const NewAppointment = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [doctors, setDoctors] = useState([]);
    const [ShowNumber, setShowNumber] = useState(false);
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const doctorId = urlParams.get('id');
    const [form] = Form.useForm();
    const [NumberDiss, setNumberDiss] = useState(false);

    const user = JSON.parse(localStorage.getItem('userData'));
    const userPhone = user['phone'];
    const [loader, setLoader] = useState(false)

    useEffect(() => {

        dispatch(getDoctor(doctorId))
    }, [doctorId]);
    const handleTypeChange = (value) => {
        if (value === 3) {
            // console.log(userPhone)
            setNumberDiss(true)
            form.setFieldsValue({
                'call_id': userPhone
            });
        }
        else {
            setNumberDiss(false)
        }
        // console.log(NumberDiss)
        setShowNumber(true)
    }
    const getDoctors = () => {
        axios.get(ApiUrl.doctor_list, CisUI().HeaderRequest)
            .then(function (response) {
                // handle success
                // console.log("doctor",response.data);
                setDoctors(response.data.data)
            }).catch(function (error) {
                // handle error
                // console.log(error);
            });
    };
    useEffect(() => {
        getDoctors()
    }, [])

    const [generalInfo, setGeneralInfo] = useState({});
    const [consultantInfo, setConsultantInfo] = useState({});

    const handleDoctorChange = (id) => {
        setLoader(true)
        // document.getElementById("loader").style.display = "block";
        axios.get(ApiUrl.single_doctor_data + "/" + id, CisUI().HeaderRequest)
            .then(res => {
                // console.log(res.data)
                if (res.data.status === 1) {
                    setGeneralInfo(res.data.general_info)
                    setConsultantInfo(res.data.consultant_info)
                } else {
                    // console.log("No data found!")
                }
                // document.getElementById("loader").style.display = "none";
            })
            .catch(error => {
                // console.log(error);
            });
        setLoader(false)
    }

    useEffect(() => {
        handleDoctorChange(parseInt(doctorId))
    }, [doctorId]);

    let available_day = Object.keys(consultantInfo).length !== 0 ? consultantInfo.available_days.split(",") : "";


    const [remarks, setRemarks] = useState("");

    const handleChange = (evt) => {
        var newContent = evt.editor.getData();
        setRemarks(newContent)
    }

    let redirectto = '/patient/appointment/list'

    const onFinish = (values) => {
        setLoader(true)

        let data = {
            ...values,
            date: values['date'].format("YYYY-MM-DD"),
            // image: image
            details: remarks,
            call_id: "+88" + values['call_id']
        }

        // console.log(data);
        // document.getElementById("loader").style.display = "block";

        axios.post(ApiUrl.request_appointment, data, CisUI().HeaderRequest)
            .then(res => {
                // console.log(res.data)
                if (res.data.status === 1) {
                    let id = res.data.id;
                    CisUI().Notification('success', res.data.msg)
                    history.push('/patient/appointment/appointmentdetails/' + id)
                }
                else {
                    CisUI().Notification('error', res.data.msg)
                }
                document.getElementById("loader").style.display = "none";
            })
            .catch(error => {
                // console.log(error.response)
            })

        setLoader(false)
    };

    return (loader ? <CircularProgress /> : <Card title="New Appointment" extra={
        <Link to={redirectto} className="list_icon"><i className="icon icon-lising-dbrd" /></Link>}>
        {doctors !== '' && (
            <Form onFinish={onFinish} form={form} initialValues={{ doctor_id: doctorId ? parseInt(doctorId) : 'Select A Doctor' }}>
                <Row
                    className="ant-row"
                    style={{ rowGap: "15px" }}                        >
                    <Divider orientation="left" plain>Doctor</Divider>

                    <Col lg={8} md={8} sm={12} xs={24}>
                        <Form.Item
                            label="Doctor"
                            name="doctor_id"
                            className="form-lblock"
                            rules={[
                                { required: true, message: "Doctor is required" },
                            ]}
                        >
                            <Select
                                showSearch
                                placeholder="Select A Doctor"
                                style={{ width: "100%" }}
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                onChange={handleDoctorChange}
                            >
                                {
                                    doctors.map((doctor, index) => (
                                        <Option key={++index} value={doctor.id}>
                                            {doctor.full_name}
                                        </Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                    </Col>

                    {
                        Object.keys(generalInfo).length !== 0 && (
                            <Col lg={14} md={14} sm={24} xs={24} style={{ float: "right" }}>
                                <Card>
                                    <Row>
                                        <Col lg={8} md={8} sm={24} xs={24}>
                                            <img src={generalInfo.image != null ? generalInfo.image : "https://via.placeholder.com/150"} alt={generalInfo.full_name} />
                                            {/*</Col>*/}
                                            {/*<Col lg={8} md={8} sm={12} xs={12} className="doctor_details">*/}
                                            <div className="doctor_details">
                                                <p>{generalInfo.full_name}</p>
                                                <p>{generalInfo.degree}</p>
                                                <p>{generalInfo.specialist}</p>
                                            </div>
                                        </Col>
                                        <Col lg={16} md={16} sm={24} xs={24} className="consultant_details">
                                            <p>Available Days: <span style={{ wordBreak: "break-word" }}>{available_day.toString()}</span></p>
                                            <p>Time: <span>{consultantInfo.start_time} - {consultantInfo.end_time}</span></p>
                                            <p>Regular Fee: <span>{consultantInfo.regular_fee}</span></p>
                                            <p>Follow Up Fee: <span>{consultantInfo.follow_up_fee}</span></p>
                                            <p>Report Fee: <span>{consultantInfo.report_fee}</span></p>
                                            <p>Average Duration: <span>{consultantInfo.avg_duration} min</span></p>
                                        </Col>
                                    </Row>
                                </Card>

                            </Col>
                        )
                    }

                    <Divider orientation="left" plain>
                        Patient
                    </Divider>

                    <Col lg={8} md={8} sm={12} xs={24}>
                        <Form.Item
                            label="Patient Weight"
                            rules={[
                                { required: true, message: "Patient Weight is required" },
                            ]}
                            name="weight"
                            className="form-lblock"
                        >
                            <Input placeholder="Patient Weight" />
                        </Form.Item>
                    </Col>

                    <Col lg={8} md={8} sm={12} xs={24}>
                        <Form.Item
                            label="Appointment Type"
                            rules={[
                                {
                                    required: true,
                                    message: "Appointment Type is required",
                                },
                            ]}
                            name="appointment_type"
                            className="form-lblock"
                        >
                            <Select
                                placeholder="Select An Option"
                                style={{ width: "100%" }}
                                name="status"
                            >
                                <Option value="1">New</Option>
                                <Option value="2">Follow Up</Option>
                                <Option value="3">Showing Report</Option>
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col lg={8} md={8} sm={12} xs={24}>
                        <Form.Item
                            label="Date"
                            rules={[
                                { required: true, message: "Appointment Date is required" },
                            ]}
                            name="date"
                            className="form-lblock"
                        >
                            <DatePicker className="gx-w-100" format="DD-MM-YYYY" />
                        </Form.Item>
                    </Col>

                    <Col lg={8} md={8} sm={12} xs={24}>
                        <Form.Item
                            name="problems"
                            label="Have Any?"
                            valuePropName="checked"
                            className="form-lblock"
                        >
                            <Checkbox.Group>
                                <Checkbox type="checkbox" id="facilities" value="Diabetics">Diabetics</Checkbox>
                                <Checkbox type="checkbox" id="facilities" value="Hypertension">Hypertension</Checkbox>
                                <Checkbox type="checkbox" id="facilities" value="Asthma">Asthma</Checkbox>
                            </Checkbox.Group>
                        </Form.Item>
                    </Col>

                    <Col lg={8} md={8} sm={12} xs={24}>
                        <Form.Item
                            label="Call Type"
                            rules={[
                                {
                                    required: true,
                                    message: "Call Type is required",
                                },
                            ]}
                            name="call_type"
                            className="form-lblock"
                        >
                            <Select
                                placeholder="Select An Option"
                                style={{ width: "100%" }}
                                name="call_type"
                                onSelect={handleTypeChange}
                            >
                                <Option value="1">Whatsapp</Option>
                                <Option value="3">Direct call</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    {
                        ShowNumber === true ? <Col lg={8} md={8} sm={12} xs={24}>
                            <Form.Item
                                label="Number"
                                rules={[
                                    {
                                        required: true,
                                        message: "Number is required",
                                    },
                                ]}
                                name="call_id"
                                className="form-lblock"
                            >
                                <Input placeholder="Call number" disabled={NumberDiss} />
                            </Form.Item>
                        </Col> : ''
                    }


                    <Col lg={24} md={24} sm={24} xs={24}>
                        <Form.Item
                            name="remarks"
                            label="Describe Your Problem"
                            className="form-lblock"
                        >
                            <CKEditor
                                activeClass="p10"
                                content={remarks}
                                events={{
                                    "change": handleChange
                                }}
                            />
                        </Form.Item>
                    </Col>

                    <Col lg={8} md={8} sm={12} xs={24}>
                        <Form.Item
                            name="prev_prescription"
                            label="Have Any Previous Prescription?"
                            className="form-lblock"
                        >
                            <input type="file" />
                        </Form.Item>
                    </Col>

                    <Col lg={24} xs={24} style={{ textAlign: "center" }}>
                        <Button type="primary" htmlType="submit">
                            Save
                        </Button>
                    </Col>
                </Row>
            </Form>
        )}

    </Card>
    );
};

export default NewAppointment;
