module.exports = {
	// footerText: "All rights reserved by Shakib 75",
	// technology_partner: "Technology Partner Creative IT Soft",

	baseurl: 'http://localhost:3000/',
	// apiserver: 'http://localhost:8000/api/',

	// baseurl: 'https://portal.sakib75.health/',
	apiserver: 'https://sakib75sandboxapi.sakib75.health/api/',

	profile_img: 'https://sakib75sandboxapi.sakib75.health/public/upload/user/',
	ticket_file: 'https://supportapi.creativeitsoft.net/public/upload/support/',
	reply_file: 'https://supportapi.creativeitsoft.net/public/upload/reply/',
	logo: '/assets/images/logo_75.png',
	loader: '/assets/images/loader.gif',
	card_front_image: '/assets/images/health_card_front_side.svg',
	card_back_image: '/assets/images/health_card_back_side.svg',
	card_ad: '/assets/images/card_ad.svg',
	demo_card: '/assets/images/demo_card.svg',
	// cislogo: 'http://localhost:3000/public/assets/images/icon.png',
	// ticket_file: 'http://localhost:8000/upload/support/',
	// reply_file: 'http://localhost:8000/upload/reply/',



	logourl: '/assets/images/coplogo.png',
	print_logourl: '/assets/images/cop_nlogo.png',
	company_name: 'Creative IT Soft',
	address: '75(Beside BRB Hospital, Panthpath), East Raja Bazar, Dhaka, Bangladesh.',
	city: 'Dhaka',
	zipcode: '1206',
	phone: "+8801670228961",
	email: "info@creativeitsoft.net",
	website: "https://creativeitsoft.net"
}
