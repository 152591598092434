/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-target-blank */
import { Button, Card, Col, Modal, Rate, Row, } from "antd";
import React, { useEffect, useState } from "react";

import Config from "../../../util/config";
import { CisUI } from "../../../util/CISUI";
import axios from "axios";
import ApiUrl from "../../../APIURL/Patient/ApiUrl";
import AppointmentList from "./AppointmentList";
import Widget from "../../../components/Widget";
import moment from "moment";
import DiscountCarousel from "./DiscountCarousel";
import HospitalList from "./HospitalList";


const DashBoard = () => {

    const [info, setinfo] = useState([]);
    const [card, setCard] = useState([]);
    const [onlineDoctor, setOnlineDoctor] = useState({
        title: '',
        data: []
    });
    const [appointment, setAppointment] = useState({
        title: '',
        data: []
    })

    const getDashboardData = () => {
        axios.get(ApiUrl.patient_dashboard, CisUI().HeaderRequest)
            .then(res => {
                console.log(res.data.data)
                if (res.data.status === 1) {
                    setinfo(res.data.patient_info)
                    setCard(res.data.card)
                    setOnlineDoctor({
                        title: res.data.online_doctors_title,
                        data: res.data.online_doctors
                    })
                    setAppointment({
                        title: res.data.appointment_title,
                        data: res.data.last_5_appointments
                    })
                }
            })
            .catch(error => console.log(error))
    }

    useEffect(() => {
        getDashboardData()
    }, []);

    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
        if (card !== null) {
            setIsModalVisible(true);
        }
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    return (
        <>
            <Row >
                {CisUI().showLoading}
                <Col xl={12} lg={24} md={24} sm={24} xs={24} className='welcome-col'>
                    <Row style={{ marginBottom: "20px" }}>
                        <div className="gx-card" style={{ width: "100%", height: "100%" }}>
                            <div className="gx-card-body" style={{ width: "100%", height: "100%", borderRadius: "30px" }}>
                                <Row>
                                    <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                                        <div className="gx-wel-ema gx-pt-xl-2">
                                            <h1 className="gx-mb-3">Welcome {info.full_name}!</h1>
                                            <h4 className="gx-mb-3">Phone: <span style={{ color: "#3a6472" }}>{info.phone}</span></h4>
                                            <h4 className="gx-mb-3">Date Of Birth: <span style={{ color: "#3a6472" }}>{moment(info.dob).format('DD-MM-YYYY')}</span></h4>
                                            <h4 className="gx-mb-3">Gender: <span style={{ color: "#3a6472" }}>{info.gender}</span></h4>
                                            <h4 className="gx-mb-3">
                                                Address: <span style={{ color: "#3a6472", }}>{info.address}</span>
                                            </h4>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                        {card !== null ?
                            (<Col xl={24} lg={24} md={24} sm={24} xs={24} className='card-area-col'>
                                <div className="sakib_card_warp">
                                    <img src="/assets/images/card/card_blank.png" alt="Card" className="card-img" />
                                    <div className="card-main-area" >
                                        <p className="card_title">Health Card</p>
                                        <p className="card_number" >{card?.unqid}</p>
                                        <p className="card_cvc">{card?.cvv}</p>
                                        <p className="card_name">{card?.card_name}</p>

                                        <div className="validation-from">
                                            <p className="text">Valid <br /> From.</p>
                                            <p className="date">{moment(card?.purchased_at).format("MM/YY")}</p>
                                        </div>
                                        <div className="validation-end">
                                            <p className="text">Expires <br /> End.</p>
                                            <p className="date">{moment(card?.validate_at).format("MM/YY")}</p>
                                        </div>
                                    </div>
                                </div>
                            </Col>) : (
                                <Col xl={24} lg={24} md={24} sm={24} xs={24} className='card-area-col'>
                                    <a href={`https://sakib75sandboxapi.sakib75.health/cardpurchasedetails/${CisUI().getLocalUserByKey('patient_id')}`} target="_blank">
                                        <img src={Config.card_ad} style={{ width: "100%", height: "100%", borderRadius: "10px" }} />
                                    </a>
                                </Col>
                            )}


                    </Row>
                </Col>

                <Col xl={12} lg={24} md={24} sm={24} xs={24} className={"carousel_height"}>
                    <DiscountCarousel />
                </Col>
            </Row>
            <Row>
                <Col xl={12} lg={24} md={24} sm={24} xs={24} className="download-now-col">
                    <Widget styleName="gx-text-white bg-primary download-now">
                        <Row>
                            <Col xl={12} lg={12} md={12} sm={12} xs={24}>
                                <h1 className="gx-text-white gx-mb-4 ">Download Mobile Apps</h1>
                                <h3 className="gx-text-white gx-mb-3 ">Now, your account is
                                    on your fingers</h3>
                            </Col>
                            <Col xl={12} lg={12} md={12} sm={12} xs={24} className="gx-text-center gx-mt-2">
                                <img src='/assets/images/play_store.svg' alt="play_store" style={{ width: "200px" }} />
                            </Col>

                        </Row>
                    </Widget>
                    <AppointmentList appointmentData={appointment} />
                </Col>

                <Col xl={12} lg={24} md={24} sm={24} xs={24}>
                    <a onClick={showModal}>
                        <Widget styleName="gx-card-full gx-dot-arrow-hover ibne-sina gx-blue-cyan-gradient-sh">
                            <div className="gx-user-wid-row">
                                <div className="gx-user-wid-body">
                                    <div className="ant-row-flex">
                                        <h1 className="h1 gx-text-white gx-mr-1 gx-mb-1 ">IBNE Sina Discount Link</h1>
                                    </div>
                                    <div className="gx-dot-arrow">
                                        <div className="gx-bg-white gx-hover-arrow">
                                            <i className="icon icon-long-arrow-right  gx-text-primary" />
                                        </div>
                                        <div className="gx-dot">
                                            <i className="icon icon-ellipse-v gx-text-white" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Widget>
                    </a>
                    <Modal title="IBNE Sina Discount Form" visible={isModalVisible}
                        footer={[
                            <Button key="back" onClick={handleCancel}>
                                Return
                            </Button>,
                            <Button key="link" href="/assets/Ibne_Sina_Discount_Form.pdf" target={"_blank"} type="primary">
                                Download
                            </Button>]} onCancel={handleCancel}
                        width={700}
                    >
                        <img src='/assets/images/ibn-sina-form.jpg' alt="ibne-sina" style={{ width: "100%", height: "100%" }} />

                    </Modal>
                    <Card className="gx-card" title={onlineDoctor.title} style={{ width: "100%", minHeight: "252px" }}>
                        {console.log("doctors", onlineDoctor.data)}
                        {onlineDoctor.data.length > 0 ?
                            onlineDoctor.data?.map((doctor, index) =>
                                <div className="review" key={index}>
                                    <div>
                                        <img
                                            src={doctor.doctor_image != null ? doctor.doctor_image : 'https://sakib75sandboxapi.sakib75.health/public/upload/user/18206232ee02444d7.png'}
                                            alt="" />
                                    </div>
                                    <div>
                                        <p style={{ margin: "0 0 5px 0", fontWeight: "bold" }}>{doctor.full_name}</p>
                                        <p style={{ margin: '0 0 2px 0' }}>{doctor.degree}</p>
                                        <p style={{ margin: '0 0 2px 0' }}>{doctor.specialist}</p>
                                        <p> <Rate disabled defaultValue={doctor.rating} /></p>
                                    </div>
                                </div>
                            ) : <div className={"text-primary"}>No Doctor is Available Now.</div>
                        }
                    </Card>
                </Col>
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                  <HospitalList/>
                </Col>
                <Col xl={24} lg={24} md={24} sm={24} xs={24} className={"info_card"} style={{ width: "100%", minHeight: "252px" }}>
                    <img src={"/assets/images/customer_care_templete.svg"} className={"info_img"} />
                </Col>
            </Row>
        </>
    );
};

export default DashBoard;
