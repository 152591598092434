import React from "react";
import { Route, Switch } from "react-router-dom";
import ChangePassword from "../../../containers/DoctorPortal/Settings/ChangePassword";
import ProfileSettings from "../../../containers/DoctorPortal/Settings/ProfileSettings";

const Settings = ({ match }) => (

    <Switch>
        <Route
            path={`${match.url}/profile_settings`}
            component={ProfileSettings}
        />
        <Route
            path={`${match.url}/change_password`}
            component={ChangePassword}
        />
    </Switch>

);

export default Settings;
