import {
  FETCH_ERROR,
  FETCH_START, FETCH_SUCCESS, GET_ALL_AGENT
  
} from "../../constants/ActionTypes";
import axios from 'axios';
import {CisUI} from "../../util/CISUI";
import Config from '../../util/config';
import ApiUrl from "../../APIURL/Admin/ApiUrl";

export const getAllAgent = () => {
  return (dispatch) => {

    dispatch({type: FETCH_START});
    axios.get(ApiUrl.agent_list,CisUI().HeaderRequest)
      .then(res => {
      if (res.data.status === 1) {
        // console.log(res.data.data);
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: GET_ALL_AGENT, payload: res.data.data});
      } else {
        dispatch({type: FETCH_ERROR, payload: res.data.msg});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_SUCCESS});
      console.log("Error****:", error.message);
    });
  }
};



