import React, {useEffect, useState,} from "react";
import {Button, Card, Col, Collapse, Form, Input, Row, Select} from "antd";
import {Link, useHistory,} from "react-router-dom";
import { CisUI } from "../../../util/CISUI";
import ApiUrl from "../../../APIURL/Admin/ApiUrl";
import SmartDataTable from 'react-smart-data-table';
import IntlMessages from "../../../util/IntlMessages";
import ActionMenu from "../../../util/ActionMenu";
// import AdvancedSearch from "./AdvancedSearch";
const {Panel} = Collapse;

const TransactionReport = () => {
	const [state, setState] = useState({
		reqURL: ApiUrl.transaction_report
	});
	const [form] = Form.useForm();
	const history = useHistory();

	const headers = {
		_id: {
			text: 'SL No',
			invisible: true,
			filterable: false,
			transform: (value) => `Row #${value + 1}`,
		},
		id: {
			text: <IntlMessages id="Sl NO"/>,
			sortable: true,
			filterable: true,
			transform: (value, id, row) => (<p style={{textAlign: "center"}}>{id+1}</p>)

		},
		patient_image:{
			invisible: true
		},
		doctor_image: {
			invisible:true
		},
		transaction_id: {
			text: <IntlMessages id="Transaction ID"/>,
			sortable: true,
			filterable: true,
		},
		appointment_unqid: {
			text: <IntlMessages id="Appointment ID"/>,
			sortable: true,
			filterable: true,
			invisible: true
		},
		title: {
			text: <IntlMessages id="Title"/>,
			sortable: true,
			filterable: true,
		},
		doctor_name: {
			text: <IntlMessages id="Doctor"/>,
			sortable: true,
			filterable: true,
			invisible: true
			// transform: (value, idx, row) =>
			// 	<>
			// 		<img src={row.doctor_image != null ? row.doctor_image : "https://via.placeholder.com/150"} alt="" style={{ width:"40px", height:"40px", borderRadius:"50%", marginRight:"8px"}}/>
			// 		{row.doctor_name}
			// 	</>
		},
		patient_name: {
			text: <IntlMessages id="Patient"/>,
			sortable: true,
			filterable: true,
			transform: (value, idx, row) =>
				<>
					<img src={row.patient_image != null ? row.patient_image : "https://via.placeholder.com/150"} alt="" style={{ width:"40px", height:"40px", borderRadius:"50%", marginRight:"8px"}}/>
					{row.patient_name}
				</>
		},
		fees: {
			text: <IntlMessages id="Fees"/>,
			sortable: true,
			filterable: true,
			transform: (value, idx, row) => CisUI().CurrencyFormat(row.fees)
		},
		created_at: {
			text: <IntlMessages id="Date"/>,
			sortable: true,
			filterable: true,
			transform: (value, idx, row) => CisUI().DateFormat(row.created_at)
		}
	};

	const orderedHeaders = ["id", "transaction_id", "appointment_unqid", "title", "doctor_name", "patient_name", "fees"];
	
	let redirectto = '/admin/agent/addagent';
	
	const onFinish = values => {
		console.log('Received values of form: ', values);
		const qs = Object.keys(values).map(key => `${key}=${values[key]}`).join('&');
		const qsup = qs.replaceAll("undefined","");
		//reqURL = reqURL+"/?"+qsup;
		history.push("?"+qsup);
		const newURL = ApiUrl.agent_list+"/?"+qsup;
		setState({reqURL: newURL});
	};
	
	// const searchView  = (
	// 	<Card className="advance-search-card">
	// 		<Collapse expandIconPosition="right" defaultActiveKey={['1']}>
	// 			<Panel header="Advanced Search" key="1">
	// 				<Form
	// 					form={form}
	// 					name="advanced_search"
	// 					className="ant-advanced-search-form"
	// 					onFinish={onFinish}
	// 				>
	// 					{AdvancedSearch}
	// 				</Form>
	// 			</Panel>
	// 		</Collapse>
	// 	</Card>
	// );
	
	
	return (
		<>
			{/*{searchView}*/}
			
			<Card className="tickets-card" title="Transaction Report">
				<SmartDataTable
					name='data_table'
					data={state.reqURL}
					dataRequestOptions={CisUI().HeaderRequest}
					dataKey="data"
					headers={headers}
					orderedHeaders={orderedHeaders}
					hideUnordered={CisUI().hideUnordered}
					className={CisUI().sematicUI.table}
					perPage={CisUI().perPage}
					sortable
					withLinks
					withHeader
					loader={CisUI().loader}
					emptyTable={CisUI().emptyTable}
				/>
			</Card>
		</>
	);
};

export default TransactionReport;
