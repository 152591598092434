import React, {useEffect, useState} from 'react';
import {Card, Col, Row, Switch} from "antd";
import {CisUI} from "../../../util/CISUI";
import IconWithTextCard from "../../../components/Metrics/IconWithTextCard";
import Widget from "../../../components/Widget";
import {Bar, BarChart, CartesianGrid, Tooltip, Legend, ResponsiveContainer, XAxis, YAxis, AreaChart, Area, Brush, LineChart, Line} from "recharts";
import LineIndicator from "./LineIndicator";
import StarRatingComponent from "react-star-rating-component";
import axios from "axios";
import ApiUrl from '../../../APIURL/Doctor/ApiUrl';

const DashBoard = () => {
	const doctorID = CisUI().getLocalUserByKey('doctor_id');
	
	const [isOnline, setIsOnline] = useState(false);
	const [reviews, setReviews] = useState([]);
	const [todayAppointment, setTodayAppointment] = useState({
		todays_completed_appointment: '',
		todays_pending_appointment: '',
	});
	const [completedAppointment, setCompletedAppointment] = useState('');
	const [currMonIncome, setCurrMonIncome] = useState({
		text: '',
		amount: 0
	});
	
	const [monthIncome, setMonthIncome] = useState({
		title: '',
		monthly_income: []
	});
	
	const [currMnPerday, setCurrMnPerDay] = useState({
		title: '',
		tt_data: []
	});
	
	const [currMnComAppnt, setCurrMnComAppnt] = useState({
		title: '',
		tt_data: []
	});
	
	const isActive = (e) => {
		let url = "";
		if (e === true) {
			url = ApiUrl.doctor_online;
		} else {
			url = ApiUrl.doctor_offline;
		}
		// console.log(e)
		document.getElementById('loader').style.display = 'block';
		axios.post(url + `/${doctorID}`, '', CisUI().HeaderRequest)
			.then(res => {
				if (res.data.status === 1) {
					CisUI().Notification('success', res.data.msg)
					setIsOnline(e)
				} else {
					CisUI().Notification('error', res.data.msg)
				}
				document.getElementById('loader').style.display = 'none';
			})
			.catch(error => console.log(error))
	}
	
	const getDashboardData = () => {
		
		document.getElementById('loader').style.display = 'block';
		axios.get(ApiUrl.dashboard, CisUI().HeaderRequest)
			.then(res => {
				if (res.data.status === 1) {
					setIsOnline(res.data.is_online)
					setReviews(res.data.reviews)
					setTodayAppointment({
						todays_completed_appointment: res.data.todays_completed_appointment,
						todays_pending_appointment: res.data.todays_pending_appointment
					})
					setCompletedAppointment(res.data.total_completed_appointment)
					setCurrMonIncome({
						text: res.data.current_month_income_title,
						amount: res.data.current_month_income
					})
					
					setMonthIncome({
						title: res.data.monthly_income_title,
						monthly_income: res.data.monthly_income
					})
					
					setCurrMnPerDay({
						title: res.data.curr_mn_per_day_income_title,
						tt_data: res.data.curr_mn_per_day_income
					})
					
					setCurrMnComAppnt({
						title: res.data.curr_mn_completed_appnt_title,
						tt_data: res.data.curr_mn_completed_appnt
					})
					
				} else {
					CisUI().Notification('error', 'Failed to load.')
				}
				document.getElementById('loader').style.display = 'none';
			})
	}
	
	useEffect(() => {
		getDashboardData()
	}, [])
	
	
	const data = [];
	
	monthIncome?.monthly_income?.map((inc, index) =>
		data.push({name: inc.month_name, income: inc.amount})
	)
	
	const data1 = [];
	
	currMnPerday?.tt_data?.map((mndata, index) =>
		data1.push({name: mndata.date, income: mndata.amount})
	)
	
	const data2 = [];
	
	currMnComAppnt?.tt_data?.map((apntData, index) =>
		data2.push({name: apntData.date, completed: apntData.total})
	)
	
	
	return (
		<>
			<Row>
				{CisUI().showLoading}
				<Col xl={12} lg={12} md={12} sm={24} xs={24}>
					<div className="gx-card">
						<div className="gx-card-body" style={{padding: "38px 24px"}}>
							<Row>
								<Col xl={24} lg={24} md={24} sm={24} xs={24}>
									<div className="gx-wel-ema gx-pt-xl-2">
										<h1 className="gx-mb-3">Welcome {CisUI().getLocalUserByKey('full_name')}!</h1>
										<p className="gx-fs-sm gx-text-uppercase">You Are
											{
												isOnline === true ? (
													<span>
	                                            <span style={{color: "green"}}> Online </span>
	                                            <Switch size="small" checked={true} onChange={isActive}/>
		                                        </span>) : (
													<span>
		                                            <span style={{color: "red"}}> Offline </span>
		                                            <Switch size="small" onChange={isActive}/>
		                                        </span>)
											}
										</p>
									</div>
								</Col>
							</Row>
						</div>
					</div>
				</Col>
				
				<Col xl={12} lg={12} md={12} sm={24} xs={24}>
					<div className="gx-card">
						<div className="gx-card-body" style={{padding: "10px 24px"}}>
							<div className="gx-site-dash gx-mb-2 gx-pt-3 gx-pt-sm-0 gx-pt-xl-2">
								<h6 className="gx-text-uppercase gx-mb-2 gx-mb-sm-4">Today's Appointment</h6>
								<ul className="gx-line-indicator">
									<li>
										<LineIndicator
											width={parseInt(1)+todayAppointment.todays_completed_appointment + "%"}
											title="Completed" color="cyan"
											value={todayAppointment.todays_completed_appointment}/>
									</li>
									<li>
										<LineIndicator
											width={parseInt(1)+todayAppointment.todays_pending_appointment + "%"}
											title="Pending" color="geekblue"
											value={todayAppointment.todays_pending_appointment}/>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</Col>
				
				
				<Col xl={8} lg={8} md={12} sm={24} xs={24} className="gx-col-full">
					<IconWithTextCard
						icon="visits"
						iconColor="geekblue"
						title={completedAppointment}
						subTitle="Total Completed Appointment"/>
				</Col>
				
				
				<Col xl={8} lg={8} md={12} sm={24} xs={24}>
					<Widget styleName={`gx-card-full`}>
						<div className="gx-d-flex gx-px-4 gx-pt-3 gx-pb-1">
							<p className="gx-text-uppercase gx-chart-title">{currMonIncome.text}</p>
							{/*<p className="gx-ml-auto gx-text-primary">67% <i className="icon icon-menu-up gx-fs-sm"/></p>*/}
						</div>
						<Row>
							<Col lg={12} md={12} sm={24} xs={24}>
								<div className="gx-actchart gx-pb-4 gx-pl-4">
									<h2 className="gx-fs-xxxl gx-font-weight-medium gx-mb-1 gx-text-black">৳ {currMonIncome.amount}</h2>
									{/*<p className="gx-mb-0">Total income</p>*/}
								</div>
							</Col>
							{/*<Col lg={12} md={12} sm={24} xs={24}>*/}
							{/*    <ResponsiveContainer width="100%" height={80}>*/}
							{/*        <AreaChart data={trafficData}*/}
							{/*                   margin={{top: 0, right: 0, left: 0, bottom: 0}}>*/}
							{/*	        <Tooltip/>*/}
							{/*	        <defs>*/}
							{/*		        <linearGradient id="color1" x1="0" y1="0" x2="1" y2="0">*/}
							{/*			        <stop offset="5%" stopColor="#FF55AA" stopOpacity={0.9}/>*/}
							{/*			        <stop offset="95%" stopColor="#E81D27" stopOpacity={0.9}/>*/}
							{/*		        </linearGradient>*/}
							{/*	        </defs>*/}
							{/*	        <Area dataKey="income" strokeWidth={0} stackId="2" stroke='#867AE5' fill="url(#color1)" fillOpacity={1}/>*/}
							{/*        </AreaChart>*/}
							{/*    </ResponsiveContainer>*/}
							{/*</Col>*/}
						</Row>
					</Widget>
				</Col>
				
				<Col xl={8} lg={8} md={12} sm={24} xs={24} className="gx-col-full">
					<IconWithTextCard icon="revenue-new" iconColor="geekblue" title="৳ 0"
					                  subTitle="Last Withdraw Amount"/>
				</Col>
				
				{/*<Col xl={8} lg={12} md={12} sm={12} xs={24}>*/}
				{/*    <Widget styleName={`gx-card-full`}>*/}
				{/*        <div className="gx-d-flex gx-px-4 gx-pt-4 gx-pb-2">*/}
				{/*	        <p className="gx-text-uppercase gx-chart-title">Today Completed Appointment</p>*/}
				{/*	        /!*<p className="gx-ml-auto gx-text-primary">67% <i className="icon icon-menu-up gx-fs-sm"/></p>*!/*/}
				{/*        </div>*/}
				{/*        <Row>*/}
				{/*	        <Col lg={12} md={12} sm={24} xs={24}>*/}
				{/*		        <div className="gx-actchart gx-pb-5 gx-pl-4">*/}
				{/*			        <h2 className="gx-fs-xxxl gx-font-weight-medium gx-mb-1 gx-text-black">2</h2>*/}
				{/*		        </div>*/}
				{/*	        </Col>*/}
				{/*        </Row>*/}
				{/*    </Widget>*/}
				{/*</Col>*/}
				{/*<Col xl={8} lg={12} md={12} sm={12} xs={24}>*/}
				{/*    <Widget styleName={`gx-card-full`}>*/}
				{/*        <div className="gx-d-flex gx-px-4 gx-pt-4 gx-pb-2">*/}
				{/*	        <p className="gx-text-uppercase gx-chart-title">Today's Pending Appointment</p>*/}
				{/*        </div>*/}
				{/*        <Row>*/}
				{/*	        <Col lg={12} md={12} sm={24} xs={24}>*/}
				{/*		        <div className="gx-actchart gx-pb-5 gx-pl-4">*/}
				{/*			        <h2 className="gx-fs-xxxl gx-font-weight-medium gx-mb-1 gx-text-black">3</h2>*/}
				{/*		        </div>*/}
				{/*	        </Col>*/}
				{/*        </Row>*/}
				{/*    </Widget>*/}
				{/*</Col>*/}
				
				<Col lg={12} md={12} sm={24} xs={24}>
					<Card className="gx-card" title={monthIncome.title}>
						<ResponsiveContainer width="100%" height={200}>
							<BarChart data={data}
							          margin={{top: 10, right: 0, left: -15, bottom: 0}}>
								<XAxis dataKey="name"/>
								<YAxis/>
								<CartesianGrid strokeDasharray="3 3"/>
								<Tooltip/>
								<Legend/>
								<Bar dataKey="income" fill="#3a6472"/>
								{/*<Bar dataKey="uv" fill="#FE9E15"/>*/}
							</BarChart>
						</ResponsiveContainer>
					</Card>
				</Col>
				
				<Col lg={12} md={12} sm={24} xs={24}>
					<Card className="gx-card" title={currMnPerday.title}>
						<ResponsiveContainer width="100%" height={200}>
							<AreaChart data={data1} syncId="anyId"
							           margin={{top: 10, right: 0, left: -15, bottom: 0}}>
								<XAxis dataKey="name"/>
								<YAxis/>
								<CartesianGrid strokeDasharray="3 3"/>
								<Tooltip/>
								<Area type='monotone' dataKey="income" stroke='#3a6472' fill='#3a6472'/>
								{/*<Brush/>*/}
							</AreaChart>
						</ResponsiveContainer>
					</Card>
				</Col>
				
				<Col lg={12} md={12} sm={24} xs={24}>
					<Card className="gx-card" title={currMnComAppnt.title}>
						<ResponsiveContainer width="100%" height={200}>
							<LineChart data={data2}
							           margin={{top: 10, right: 0, left: -15, bottom: 0}}>
								<XAxis dataKey="name"/>
								<YAxis/>
								<CartesianGrid strokeDasharray="3 3"/>
								<Tooltip/>
								<Legend/>
								<Line type="monotone" dataKey="completed" stroke="#3a6472" activeDot={{r: 5}}/>
								{/*<Line type="monotone" dataKey="uv" stroke="#FE9E15"/>*/}
							</LineChart>
						</ResponsiveContainer>
					</Card>
				</Col>
				
				<Col lg={12} md={12} sm={24} xs={24}>
					<Card className="gx-card" title="Top Reviews">
						{
							reviews.map((review, index) =>
								<div className="review">
									<div>
										<img className='doctor-dash-rating'
											src={review.patient_image != null ? review.patient_image : 'https://sakib75sandboxapi.sakib75.health/public/upload/user/18206232ee02444d7.png'}
											alt=""/>
									</div>
									<div className='doctor-dash-rating-dec'>
										<p style={{margin: "0 0 5px 0", fontWeight: "bold"}}>{review.patient_name}</p>
										<StarRatingComponent
											name=""
											value={review.ratings}
											starCount={5}
											editing={false}/>
										{/*<strong className="gx-ml-2">4.2</strong>*/}
										<p>{review.details}</p>
									</div>
								</div>
							)
						}
					</Card>
				</Col>
			
			</Row>
		</>
	);
};

export default DashBoard;
