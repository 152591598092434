import React from "react";
import { Route, Switch } from "react-router-dom";
import TransactionReport from "../../../containers/AdminPortal/Report/TransactionReport";

const Report = ({ match }) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route path={`${match.url}/transactionreport`} component={TransactionReport}/>
    </Switch>
  </div>
);

export default Report;
