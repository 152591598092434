import React from "react";
import {Route, Switch} from "react-router-dom";
import NewAppointment from "../../../containers/AdminPortal/Appointment/NewAppointment";
import AppointmentList from "../../../containers/AdminPortal/Appointment/AppointmentList";
import AppointmentDetails from "../../../containers/AdminPortal/Appointment/AppointmentDetails";
// import EditAppointment from "../../../containers/AdminPortal/Appointment/EditAppointment";
// import DeleteAppointment from "../../containers/Service/DeleteAppointment";

const Appointment = ({match}) => (
	<div className="gx-main-content-wrapper">
		<Switch>
			<Route path={`${match.url}/newappointment`} component={NewAppointment}/>
			<Route path={`${match.url}/appointmentlists`} component={AppointmentList}/>
			<Route path={`${match.url}/appointmentdetails/:id`} component={AppointmentDetails}/>
			{/*<Route path={`${match.url}/editpatient/:id`} component={EditAppointment}/>*/}
			{/* <Route path={`${match.url}/customerdelete/:id`} component={DeleteAppointment}/> */}
		</Switch>
	</div>
);

export default Appointment;
