import React, { useState, useEffect } from "react";
import {Col, Input, Row, Select, Form, Button, Card, DatePicker} from "antd";
import { Link, useHistory } from "react-router-dom";
import { CisUI } from "../../../util/CISUI";
import Loading from "../../../components/Loading";
import {useDispatch, useSelector} from "react-redux";
import {getCountry, getDistrict} from "../../../appRedux/actions/Country";
import axios from "axios";
import ApiUrl from "../../../APIURL/Admin/ApiUrl";
import moment from "moment";

const { Option } = Select;

const EditPatient = (props) => {
	const patientID = props.match.params.id;
	const history = useHistory();
	const [patient, setPatient] = useState([]);
	const [image, setImage] = useState("");
	const dispatch = useDispatch();
	
	let countries = useSelector(({ country }) => country.countries);
	let districts = useSelector(({ district }) => district.districts);
	
	let redirectto = '/admin/patient/patientlist'
	
	const getSingleCustomer = () => {
		axios.get(ApiUrl.single_patient_data + `/${patientID}`, CisUI().HeaderRequest)
			.then((res) => {
				if (res.data.status == 1) {
					setPatient(res.data.data);
					console.log(res.data.data);
				} else {
					console.log("error");
				}
			})
			.catch((errors) => console.log(errors));
	};
	
	const changeImage = (e) => {
		let file = e.target.files[0];
		let reader = new FileReader();
		// setSource(URL.createObjectURL(e.target.files[0]))
		reader.onload = (e) => {
			setImage({
				image: e.target.result,
			});
		};
		// console.log(file);
		reader.readAsDataURL(file);
	};
	
	
	useEffect(() => {
		dispatch(getCountry());
		dispatch(getDistrict());
		getSingleCustomer();
	}, []);
	
	const onFinish = (values) => {

		let data = {
			...values,
			dob: values['dob'].format("YYYY-MM-DD"),
			image: image
		}
		console.log(data);
		document.getElementById("loader").style.display = "block";
		axios.post(ApiUrl.update_patient + `/${patientID}`, data, CisUI().HeaderRequest)
			.then((res) => {
				if (res.data.status == 1) {
					CisUI().Notification('success', res.data.msg)
					history.push(redirectto);
				} else {
					CisUI().Notification('error', res.data.msg)
				}
				document.getElementById("loader").style.display = "none";
			})
			.catch((errors) => console.log(errors));
	};
	
	return (
		<Card title="Edit Patient" extra={
			<Link to={redirectto} className="list_icon"><i className="icon icon-lising-dbrd" /></Link>
		}>
			{CisUI().showLoading}
			 {
				 patient == "" ? (
					 <Loading />
				 ) : (
					<Form
						onFinish={onFinish}
						initialValues={{
							full_name: patient.full_name,
							email: patient.email,
							// nid: patient.nid,
							nationality: patient.nationality,
							dob: moment(patient.dob),
							gender: patient.gender,
							city: patient.city,
							district: parseInt(patient.district),
							country: parseInt(patient.country),
							address: patient.address,
							zip_code: patient.zip_code,
							status: CisUI().getEditStatusLabel(patient.status),
						}}
					>
						<Row
							className="ant-row"
							style={{ rowGap: "15px" }}
						>
							<Col lg={8} md={8} sm={12} xs={24}>
								<Form.Item
									label="Full name"
									rules={[
										{ required: true, message: "Full name is required" },
									]}
									name="full_name"
									className="form-lblock"
								>
									<Input placeholder="Full Name" />
								</Form.Item>
							</Col>
							
							<Col lg={8} md={8} sm={12} xs={24}>
								<Form.Item
									label="Email"
									className="form-lblock"
									name="email"
								>
									<Input placeholder="Email" />
								</Form.Item>
							</Col>
							
							<Col lg={8} md={8} sm={12} xs={24}>
								<Form.Item
									label="Nationality"
									rules={[
										{ required: true, message: "Phone is required" },
									]}
									className="form-lblock"
									name="nationality"
								>
									<Input placeholder="Phone" />
								</Form.Item>
							</Col>
							
							{/* <Col lg={8} md={8} sm={12} xs={24}>
								<Form.Item
									label="NID"
									rules={[
										{ required: true, message: "Email is required" },
									]}
									className="form-lblock"
									name="nid"
								>
									<Input placeholder="Email" />
								</Form.Item>
							</Col> */}
							
							<Col lg={8} md={8} sm={12} xs={24}>
								<Form.Item
									label="Date Of Birth"
									rules={[
										{ required: true, message: "Date Of Birth is required" },
									]}
									name="dob"
									className="form-lblock"
								>
									<DatePicker className="gx-w-100"/>
								</Form.Item>
							</Col>
							
							<Col lg={8} md={8} sm={12} xs={24}>
								<Form.Item
									label="Gender"
									rules={[
										{
											required: true,
											message: "Gender is required",
										},
									]}
									name="gender"
									className="form-lblock"
								>
									<Select
										placeholder="Select An Option"
										style={{ width: "100%" }}
										name="status"
									>
										<Option value="Male">Male</Option>
										<Option value="Female">Female</Option>
										<Option value="Others">Others</Option>
									</Select>
								</Form.Item>
							</Col>
							
							<Col lg={8} md={8} sm={12} xs={24}>
								<Form.Item
									label="City"
									name="city"
									className="form-lblock"
								>
									<Input placeholder="City" />
								</Form.Item>
							</Col>
							
							<Col lg={8} md={8} sm={12} xs={24}>
								<Form.Item
									label="District"
									name="district"
									className="form-lblock"
									rules={[
										{ required: true, message: "District is required" },
									]}
								>
									<Select
										showSearch
										// optionFilterProp="children"
										placeholder="Select Your District"
										style={{ width: "100%" }}
										filterOption={(input, option) =>
											option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
										}
										// name="country"
									>
										{districts?.map((district, index) => (
											<Option key={++index} value={district.id}>
												{district.name}
											</Option>
										))}
									</Select>
								</Form.Item>
							</Col>
							
							<Col lg={8} md={8} sm={12} xs={24}>
								<Form.Item
									label="Country"
									className="form-lblock"
									name="country"
								>
									<Select
										placeholder="Select Your Country"
										style={{ width: "100%" }}
									>
										{
											countries?.map((country, index) => (
												<Option key={++index} value={country.id}>
													{country.name}
												</Option>
											))
										}
									</Select>
								</Form.Item>
							</Col>
							
							<Col lg={8} md={8} sm={12} xs={24}>
								<Form.Item
									label="Address"
									name="address"
									className="form-lblock"
								>
									<Input placeholder="Address" />
								</Form.Item>
							</Col>
							
							<Col lg={8} md={8} sm={12} xs={24}>
								<Form.Item
									label="Zip Code"
									className="form-lblock"
									name="zip_code"
								>
									<Input placeholder="Zip Code" />
								</Form.Item>
							</Col>
							
							<Col lg={8} md={8} sm={12} xs={24}>
								<Form.Item
									label="User Status"
									rules={[
										{
											required: true,
											message: "User Status is required",
										},
									]}
									className="form-lblock"
									name="status"
								>
									<Select
										placeholder="Select An Option"
										style={{ width: "100%" }}
										name="status"
									>
										<Option value="1">Active</Option>
										<Option value="0">Inactive</Option>
									</Select>
								</Form.Item>
							</Col>
							
							<Col lg={8} md={8} sm={12} xs={24}>
								<Form.Item
									label="Image"
									name="file"
									className="form-lblock"
								>
									<input
										type="file"
										name="image"
										onChange={changeImage}
									/>
								</Form.Item>
								
								{
									patient.image != null && (
										<div className="col-md-2">
											<img src={patient.image} alt="" style={{ width: "150px", height: "120px"}}/>
											{/*<button className="btn btn-secondary btn-sm remove-btn" onClick={() => removeImage(source)}>x</button>*/}
										</div>
									)
								}
							</Col>
							
							<Col lg={24} xs={24} style={{ textAlign: "center" }}>
								<Button type="secondary" htmlType="back"  >
									Back
								</Button>
								<Button type="primary" htmlType="submit">
									Save
								</Button>
							</Col>
						</Row>
					</Form>
				)
			 }
		</Card>
	);
};

export default EditPatient;
