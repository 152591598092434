import React, { useEffect } from "react";
import { Button, Form, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import { userSignIn } from "../appRedux/actions";
import IntlMessages from "util/IntlMessages";
import InfoView from "components/InfoView";
import { UserOutlined, LockOutlined } from "@ant-design/icons";


const SignIn = (props) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const authUser = useSelector(({ auth }) => auth.authUser);

	const onFinishFailed = errorInfo => {
		console.log('Failed:', errorInfo);
	};


	const onFinish = values => {
		dispatch(userSignIn(values));
	};

	useEffect(() => {
		if (authUser !== null) {
			history.push('/');
		}
	}, [authUser]);

	return (
		<div className="gx-app-login-wrap login-wrap">
			<div className="gx-app-login-container">
				<div className="gx-app-login-main-content">

					<div className="gx-app-login-content">
						<img alt="example" src="/assets/images/logo.png" className="login-logo" />
						<Form
							initialValues={{ remember: true }}
							name="basic"
							onFinish={onFinish}
							onFinishFailed={onFinishFailed}
							className="gx-signin-form gx-form-row0"
							layout="vertical">

							<Form.Item
								label="Your phone number"
								rules={[{ required: true, message: 'Phone is required!' }, { min: 11, message: 'Phone number must be 11 digits.' }, { max: 11, message: 'Phone number must be 11 digits.' }]} name="email">
								<Input placeholder="Your phone number" prefix={<UserOutlined />} size="large" />
							</Form.Item>
							<Form.Item
								label="Password"
								rules={[{ required: true, message: 'Please input your Password!' }]} name="password">
								<Input type="password" placeholder="Password" prefix={<LockOutlined />} size="large" />
							</Form.Item>

							<Form.Item className="signin-btn">

								<Button type="primary" className="gx-mb-0" htmlType="submit">
									<IntlMessages id="app.userAuth.signIn" />
								</Button>
								<Link to="/forgotpassword"><IntlMessages id="app.userAuth.forgotPassword" /></Link>
							</Form.Item>

						</Form>
						<p style={{ textAlign: "center" }}>Don't have an account? <Link to="/signupotp">Sign Up</Link> </p>
					</div>
					<InfoView />

				</div>
			</div>
		</div>
	);
};

export default SignIn;